import {Box, Text, Button, useModal, Flex, Icon} from '@glimpse/glass';
import {Scorecard}                               from '@glimpse/glimpse';
import {RequirePermission}                       from 'features/permission/require-permission';
import {useTranslation}                          from 'foundations/i18n/use-translation';
import {CreateScorecardAssignmentModal}          from 'features/scorecard-assignment/create-scorecard-assignment-modal';
import {ScorecardAssignmentTable}                from 'features/scorecard-assignment/scorecard-assignment-table';
import {faPlus}                                  from '@fortawesome/pro-light-svg-icons';
import React                                     from 'react';

export function ScorecardAssignmentSection({
    scorecardId
}: {
    scorecardId: Scorecard['id']
}) {
    const {t}         = useTranslation();
    const createModal = useModal();

    return (
        <>
            <CreateScorecardAssignmentModal scorecardId={scorecardId} open={createModal.open} onClose={createModal.toggle}/>
            <Box mb={1}>
                <Flex justify='between' align={'center'}>
                    <Text fontWeight='bold' fontSize={3}>{t('Assign to Components')}</Text>
                    <RequirePermission permission={'scorecard.write'}>
                        <Button spacing={'compact'} icon={<Icon fa={faPlus}/>} onClick={createModal.toggle}/>
                    </RequirePermission>
                </Flex>
            </Box>
            <ScorecardAssignmentTable scorecardId={scorecardId}/>
        </>
    );
}