import {ScaffoldGrid}                      from 'features/scaffold/scaffold-grid';
import {Box, Button, Grid, Text, useModal} from '@glimpse/glass';
import {CreateScaffoldModal}               from 'features/scaffold/create-scaffold-modal';
import {RequirePermission}                 from 'features/permission/require-permission';
import {AppLayout}                         from 'foundations/layouts/app-layout/app-layout';
import React                               from 'react';
import {Searchbox}                         from 'foundations/ui/searchbox/searchbox';
import {useForm}                           from 'foundations/ui/form/use-form';
import {Scaffold}                          from '@glimpse/glimpse';
import {useTranslation}                    from 'foundations/i18n/use-translation';

export function ScaffoldsScreen() {
    const form        = useForm<Scaffold.List.Params>();
    const createModal = useModal();
    const {t}         = useTranslation();

    return (
        <AppLayout title={t('Scaffolds')}>
            {
                createModal.open &&
                <CreateScaffoldModal open={createModal.open} onClose={createModal.toggle}/>
            }
            <AppLayout.Header>
                <Box pt={3} pb={3}>
                    <Grid justify='between' align='center'>
                        <div>
                            <Text fontSize={4} fontWeight='bolder'>{t('Scaffolds')}</Text>
                            <Text fontSize={1} color={'text.subtle'}>{t('Build faster')}</Text>
                        </div>
                        <div>
                            <RequirePermission permission={'scaffold.write'}>
                                <Button variant='primary' icon='fa-regular fa-plus' onClick={createModal.toggle}>{t('Add Scaffold')}</Button>
                            </RequirePermission>
                        </div>
                    </Grid>
                </Box>
            </AppLayout.Header>
            <AppLayout.Body>
                <Box mb={2}>
                    <Searchbox {...form.register('query')}/>
                </Box>
                <ScaffoldGrid query={form.data}/>
            </AppLayout.Body>
        </AppLayout>
    );
}