import {Table, Text, Box, Icon, Flex, Avatar, Tooltip, Button} from '@glimpse/glass';
import {Component}                                     from '@glimpse/glimpse';
import {Link, useNavigate} from 'react-router-dom';
import React                                           from 'react';
import {glimpse}                                       from 'providers/glimpse/glimpse-provider';
import {Empty}                                         from 'foundations/ui/empty/empty';
import {useTranslation}                                from 'foundations/i18n/use-translation';
import {CenteredSpinner}                               from 'foundations/ui/interstitial/centered-spinner';
import {useQuery}                                      from 'foundations/net/use-query';
import {RequirePermission}                             from 'features/permission/require-permission';
import {ComponentIcon}                                 from 'features/component/component-icon';
import {isQueryEmpty}                                  from 'foundations/net/is-query-empty';

export function ComponentTable({
    query = {}
}: {
    query?: Component.List.Params,
}) {
    const {t}      = useTranslation();
    const navigate = useNavigate();
    const q        = {
        ...query,
        expand: ['owners.team', 'owners.user']
    };

    const {loading, data} = useQuery<Component>({
        key: ['component', q],
        fn:  async () => glimpse.component.list(q)
    });

    if (loading) {
        return <CenteredSpinner/>;
    }

    if (data?.length === 0) {
        return isQueryEmpty(query)
            ? <Empty
                prompt={
                    <RequirePermission permission={'integration.write'}>
                        <Link to={'/settings/integrations'}>
                            <Button variant={'primary'}>{t('Connect Integration')}</Button>
                        </Link>
                    </RequirePermission>
                }
                icon={<Icon fa={['far', 'fa-server']}/>}
                title={t('Add your first component')}
                desc={t('Use components to track and manage your stack. Components can represent entire systems, applications, libraries or parts of a system.')}/>
            : <Empty
                icon={<Icon fa={['far', 'fa-server']}/>}
                title={t('No components matching filters')}
                desc={t('There are no components matching your filters. Change your filters to find more components')}/>;
    }

    return (
        <RequirePermission permission={'component.read'} fallback>
            <Table>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>{t('Name')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('Description')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('Owners')}</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {
                        data?.map(component =>
                            <Table.Row effects={['clickable', 'hoverable']} onClick={() => navigate(`/components/${component.id}`)} key={component.id}>
                                <Table.BodyCell>
                                    <Box>
                                        <Flex align={'center'} gap={1}>
                                            <ComponentIcon componentType={component.type}/>
                                            <Text fontWeight={'bold'}>
                                                {component.name}
                                            </Text>
                                        </Flex>
                                    </Box>
                                </Table.BodyCell>
                                <Table.BodyCell>
                                    <Text color={'text.subtle'}>
                                        {component.description ?? '-'}
                                    </Text>
                                </Table.BodyCell>
                                <Table.BodyCell>
                                    <Flex align={'center'}>
                                        {
                                            component.owners?.map((owner) =>
                                                <Box ml={-1}>
                                                    <Tooltip content={owner.team?.name ?? owner.user?.name ?? ''}>
                                                        <Avatar name={owner.team?.name ?? owner.user?.name}/>
                                                    </Tooltip>
                                                </Box>
                                            )
                                        }
                                        {
                                            (component.owners?.length ?? []) === 0 &&
                                            <Text color={'text.subtle'}>-</Text>
                                        }
                                    </Flex>
                                </Table.BodyCell>
                            </Table.Row>
                        )
                    }
                </Table.Body>
            </Table>
        </RequirePermission>
    );
}