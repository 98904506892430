import {Box, Flex, Icon, Text, Tooltip} from '@glimpse/glass';
import React, {useMemo}                 from 'react';
import {LoadingPanel}                   from 'foundations/ui/loading-panel/loading-panel';
import {useTranslation}                 from 'foundations/i18n/use-translation';
import {useQuery}                       from 'foundations/net/use-query';
import {Insight, InsightBenchmark}      from '@glimpse/glimpse';
import {glimpse}                        from 'providers/glimpse/glimpse-provider';
import {ageFromTimestamp}               from 'foundations/ui/date/age-from-timestamp';

export function InsightStat({
    name,
    mode,
    query
}: {
    name: string,
    mode?: 'percent' | 'duration',
    query?: Insight.List.Params
}) {
    const {t} = useTranslation();

    const insightApi = useQuery<Insight>({
        key: ['insight', query],
        fn:  () => glimpse.insight.list(query)
    });

    const benchmarkApi = useQuery<InsightBenchmark>({
        key: ['insight-benchmark'],
        fn:  glimpse.insightBenchmark.list
    });

    const insight = useMemo(() => {
        if (insightApi.data) {
            return insightApi.data.find((insight) => insight.name === name);
        } else {
            return null;
        }

    }, [name, JSON.stringify(insightApi.data)]);

    const unit = useMemo(() => {
        if (typeof insight?.value !== 'number') {
            return null;
        }

        switch (insight?.value_format) {
            case 'duration':
                return ageFromTimestamp(insight?.value).unit;
            default:
                return null;
        }
    }, [mode, insight]);

    const value = useMemo(() => {
        if (typeof insight?.value !== 'number') {
            return null;
        }

        switch (insight?.value_format) {
            case 'duration':
                return ageFromTimestamp(insight.value).value.toFixed(1);
            case `percentage`:
                return `${(insight?.value * 100).toFixed(0)}%`;
            default:
                return insight?.value.toString();
        }
    }, [mode, insight]);

    const benchmark = useMemo(() => {
        if (benchmarkApi.data) {
            return benchmarkApi.data.find((benchmark) => benchmark.insight_name === name);
        } else {
            return null;
        }
    }, [name, JSON.stringify(benchmarkApi.data)]);

    if (insightApi.loading || benchmarkApi.loading || !insight) {
        return <LoadingPanel/>;
    }

    return (
        <Box p={2} border={'default'} radius={1}>
            <Box mb={2}>
                <Flex align={'center'} justify={'between'}>
                    <Flex align={'center'} gap={1}>
                        <Text fontWeight={'bold'}>{insight.label}</Text>
                        {
                            insight.description &&
                            <Tooltip content={insight.description}>
                                <Icon fa={['far', 'circle-info']} color={'n60'}/>
                            </Tooltip>
                        }
                    </Flex>
                </Flex>
            </Box>
            {
                (typeof value === 'undefined' || value === null) &&
                <Box pt={2} pb={2}>
                    <Flex align={'center'} justify={'center'}>
                        <Text color={'text.subtle'}>{t('Not enough data')}</Text>
                    </Flex>
                </Box>
            }
            {
                value &&
                <Box pb={2} pt={2}>
                    <Flex align={'end'} justify={'center'} gap={0.5}>
                        <Text fontSize={6} fontWeight={'bold'}>{value}</Text>
                        {
                            unit &&
                            <Box mb={0.5}>
                                <Text fontWeight={'bold'}>{unit}</Text>
                            </Box>
                        }
                    </Flex>
                    {
                        benchmark &&
                        <Box mt={2}>
                            <Flex align={'center'} justify={'center'} gap={1}>
                                {
                                    insight.value_direction === 'high-is-best' &&
                                    insight.value > benchmark.value &&
                                    <>
                                        <Icon fa={['fas', 'caret-up']} color={'success'}/>
                                        <Text color={'text.subtle'}>{t('Higher than industry average')}</Text>
                                    </>
                                }
                                {
                                    insight.value_direction === 'high-is-best' &&
                                    insight.value < benchmark.value &&
                                    <>
                                        <Icon fa={['fas', 'caret-down']} color={'danger'}/>
                                        <Text color={'text.subtle'}>{t('Lower than industry average')}</Text>
                                    </>
                                }
                                {
                                    insight.value_direction === 'low-is-best' &&
                                    insight.value > benchmark.value &&
                                    <>
                                        <Icon fa={['fas', 'caret-up']} color={'danger'}/>
                                        <Text color={'text.subtle'}>{t('Higher than industry average')}</Text>
                                    </>
                                }
                                {
                                    insight.value_direction === 'low-is-best' &&
                                    insight.value < benchmark.value &&
                                    <>
                                        <Icon fa={['fas', 'caret-down']} color={'success'}/>
                                        <Text color={'text.subtle'}>{t('Lower than industry average')}</Text>
                                    </>
                                }
                            </Flex>
                        </Box>
                    }
                </Box>
            }
        </Box>
    );
}