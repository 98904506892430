import {Modal, Button, Form, Textbox, Flex} from '@glimpse/glass';
import {useForm}                            from 'foundations/ui/form/use-form';
import {glimpse}                            from 'providers/glimpse/glimpse-provider';
import {useTranslation}                     from 'foundations/i18n/use-translation';
import toast                                from 'react-hot-toast';
import {useMutate}                          from 'foundations/net/use-mutate';
import React                                from 'react';
import {GqlTextbox}                         from 'features/gql/gql-textbox';
import {ScorecardRule}                      from '@glimpse/glimpse';

export function CreateScorecardRuleModal({
    open,
    onClose,
    scorecardId
}: {
    open: boolean,
    onClose: () => void,
    scorecardId: ScorecardRule['scorecard_id']
}) {
    const {t}                      = useTranslation();
    const form                     = useForm<ScorecardRule.Create.Params>({
        scorecard_id: scorecardId
    });
    const {error, loading, submit} = useMutate<ScorecardRule>({
        key:       ['scorecard-rule', scorecardId],
        fn:        () => glimpse.scorecardRule.create(form.data),
        onSuccess: () => {
            toast.success(t('Rule added'));
            onClose();
        }
    });

    return (
        <Modal onClose={onClose} open={open}>
            <Modal.Header onClose={onClose} title={t('Add a rule')}/>
            <Modal.Body>
                <Form>
                    <Form.Field label={t('Name')} errors={error?.params.name}>
                        <Textbox {...form.register('name', {required: true})} placeholder={t('Has Readme')}/>
                    </Form.Field>
                    <Form.Field label={t('GQL Query')} errors={error?.params.gql} description={t('Queries are the foundation of your check. Write queries to evaluate data, make comparisons and access integration functions.')}>
                        <GqlTextbox {...form.register('gql', {required: true})} placeholder={'github.hasFile("readme.md")'}/>
                    </Form.Field>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Flex justify={'end'}>
                    <Button onClick={onClose}>{t('Cancel')}</Button>
                    <Button variant='primary' loading={loading} onClick={submit} disabled={!form.valid}>{t('Add Rule')}</Button>
                </Flex>
            </Modal.Footer>
        </Modal>
    );
}