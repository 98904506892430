import {Icon, Reveal, Button, useModal, Box, Text, Table} from '@glimpse/glass';
import {Component, Property}                              from '@glimpse/glimpse';
import React, {useState}                                  from 'react';
import {glimpse}                                          from 'providers/glimpse/glimpse-provider';
import {Empty}                                            from 'foundations/ui/empty/empty';
import {useTranslation}                                   from 'foundations/i18n/use-translation';
import {UpdatePropertyModal}                              from 'features/property/update-property-modal';
import {DeletePropertyModal}                              from 'features/property/delete-property-modal';
import {useQuery}                                         from 'foundations/net/use-query';
import {RequirePermission}                                from 'features/permission/require-permission';

export function PropertyTable({
    query = {}
}: {
    query?: Component.List.Params,
}) {
    const {t}                     = useTranslation();
    const [selected, setSelected] = useState<Property['id']>();
    const deleteModal             = useModal();
    const updateModal             = useModal();
    const q                       = {
        ...query,
        expand: ['values']
    };
    const {data, loading}         = useQuery<Property>({
        key: ['property', q],
        fn:  () => glimpse.property.list(q)
    });

    if (loading) {
        return null;
    }

    if (data?.length === 0) {
        return <Empty
            icon={<Icon fa={['far', 'fa-pen-field']}/>}
            title={t('Add your first property')}
            desc={t('Custom properties allow you to add structured information to your components')}/>;
    }

    const onDelete = (id: Property['id']) => {
        setSelected(id);
        deleteModal.toggle();
    };

    const onUpdate = (id: Property['id']) => {
        setSelected(id);
        updateModal.toggle();
    };

    return (
        <RequirePermission permission={'property.read'} fallback>
            <UpdatePropertyModal open={updateModal.open} onClose={updateModal.toggle} propertyId={selected!}/>
            <DeletePropertyModal open={deleteModal.open} onClose={deleteModal.toggle} propertyId={selected!}/>
            <Table>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>{t('Name')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('Description')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('Usage')}</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                {
                    data?.map(property =>
                        <Reveal>
                            <Table.Row>
                                <Table.BodyCell>
                                    <Box pt={0.5} pb={0.5}>
                                        <Text fontWeight={'bold'}>{property.label}</Text>
                                        <Text color={'text.subtle'}>{property.name}</Text>
                                    </Box>
                                </Table.BodyCell>
                                <Table.BodyCell>
                                    <Text color={'text.subtle'}>{property.description ?? '-'}</Text>
                                </Table.BodyCell>
                                <Table.BodyCell>
                                    <Text color={'text.subtle'}>{`${property.values?.length ?? 0} components`}</Text>
                                </Table.BodyCell>
                                <Table.BodyCell shrink>
                                    <Reveal.Hidden>
                                        <>
                                            <Button spacing='compact' icon={<Icon fa={['far', 'fa-pen']}/>} onClick={() => onUpdate(property.id)}/>
                                            <Button spacing='compact' icon={<Icon fa={['far', 'fa-trash']}/>} onClick={() => onDelete(property.id)}/>
                                        </>
                                    </Reveal.Hidden>
                                </Table.BodyCell>
                            </Table.Row>
                        </Reveal>
                    )
                }
            </Table>
        </RequirePermission>
    );
}