import {GlassChangeEvent} from '@glimpse/glass';
import {useTranslation}   from 'foundations/i18n/use-translation';
import {StaticFilter}     from 'foundations/ui/filter/static-filter';

export const pullRequestsStateOptions = [
    {label: ('Open'), value: 'open'},
    {label: ('Draft'), value: 'draft'},
    {label: ('Merged'), value: 'merged'},
    {label: ('Closed'), value: 'closed'}
];

export function PullRequestStateFilter({
    value,
    onChange,
    name
}: {
    value: any,
    onChange: (event: GlassChangeEvent) => void,
    name: string,
}) {
    const {t}     = useTranslation();
    const options = [
        {
            label: t('Open'),
            value: 'open'
        },
        {
            label: t('Draft'),
            value: 'draft'
        },
        {
            label: t('Closed'),
            value: 'closed'
        },
        {
            label: t('Merged'),
            value: 'merged'
        }
    ];

    return <StaticFilter options={options} value={value} onChange={onChange} name={name} label={t('State')}/>;
}