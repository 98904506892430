import {Box, Button, Grid, Text, useModal} from '@glimpse/glass';
import {useForm}                           from 'foundations/ui/form/use-form';
import {Action}                            from '@glimpse/glimpse';
import {RequirePermission}                 from 'features/permission/require-permission';
import React                               from 'react';
import {AppLayout}                         from 'foundations/layouts/app-layout/app-layout';
import {Searchbox}                         from 'foundations/ui/searchbox/searchbox';
import {useTranslation}                    from 'foundations/i18n/use-translation';
import {ActionsGrid}                       from 'features/action/actions-grid';
import {CreateActionModal}                 from 'features/action/create-action-modal';

export function ActionsScreen() {
    const {t}         = useTranslation();
    const createModal = useModal();
    const form        = useForm<Action.List.Params>();

    return (
        <AppLayout title={t('Actions')}>
            <RequirePermission permission={'action.read'} fallback>
                <CreateActionModal open={createModal.open} onClose={createModal.toggle}/>
                <AppLayout.Header>
                    <Box pt={3} pb={3}>
                        <Grid justify='between' align='center'>
                            <div>
                                <Text fontSize={4} fontWeight='bolder'>{t('Actions')}</Text>
                                <Text fontSize={1} color={'text.subtle'}>{t('Self-serve functions')}</Text>
                            </div>
                            <div>
                                <RequirePermission permission={'action.write'}>
                                    <Button variant='primary' icon='fa-regular fa-plus' onClick={createModal.toggle}>{t('Add Action')}</Button>
                                </RequirePermission>
                            </div>
                        </Grid>
                    </Box>
                </AppLayout.Header>
                <AppLayout.Body>
                    <Box mb={2}>
                        <Searchbox {...form.register('query')}/>
                    </Box>
                    <ActionsGrid query={form.data}/>
                </AppLayout.Body>
            </RequirePermission>
        </AppLayout>
    );
}