import {Text, Box, Button, Icon} from '@glimpse/glass';
import {AuthLayout}              from 'foundations/layouts/auth-layout/auth-layout';
import {useTranslation}          from 'foundations/i18n/use-translation';
import {useNavigate}             from 'react-router-dom';

export function PasswordResetCompleteScreen() {
    const {t}      = useTranslation();
    const navigate = useNavigate();

    return (
        <AuthLayout>
            <Box xAlign={'center'}>
                <Box mb={4}>
                    <Icon fa={['fas', 'fa-check']} fontSize={6} color={'secondary'}/>
                </Box>
                <Box mb={4}>
                    <Text fontSize={3} fontWeight={'bold'}>{t('Password successfully updated!')}</Text>
                </Box>
                <Button onClick={() => navigate('/login')} spacing={'large'} fullwidth variant={'primary'}>{t('Return to login')}</Button>
            </Box>
        </AuthLayout>
    );
}