import {Invite, User}                               from '@glimpse/glimpse';
import {Table, Box, Text, Reveal, Button, useModal} from '@glimpse/glass';
import {glimpse}                                    from 'providers/glimpse/glimpse-provider';
import {useTranslation}                             from 'foundations/i18n/use-translation';
import {useQuery}                                   from 'foundations/net/use-query';
import {RequirePermission}                          from 'features/permission/require-permission';
import {CenteredSpinner}                            from 'foundations/ui/interstitial/centered-spinner';
import React, {useState}                            from 'react';
import {DeleteInviteModal}                          from 'features/invite/delete-invite-modal';

export function InviteTable({
    query = {}
}: {
    query?: User.List.Params
}) {
    const {t}                     = useTranslation();
    const deleteModal             = useModal();
    const [selected, setSelected] = useState<Invite['id']>();
    const {data, loading}         = useQuery<User>({
        key: ['invite', query],
        fn:  () => glimpse.invite.list(query)
    });

    if (loading) {
        return <CenteredSpinner/>;
    }

    const deleteInvite = (id: Invite['id']) => {
        setSelected(id);
        deleteModal.toggle();
    };

    return (
        <RequirePermission permission={'user.read'}>
            <DeleteInviteModal open={deleteModal.open} onClose={deleteModal.toggle} inviteId={selected!}/>
            <Table>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>{t('Email')}</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {
                        data?.length === 0 &&
                        <Box p={2}>
                            <Text color='text.subtle'>{t('No invites')}</Text>
                        </Box>
                    }
                    {
                        data?.map(invite =>
                            <Reveal>
                                <Table.Row effects={['hoverable']}>
                                    <Table.BodyCell>
                                        <Text fontWeight={'bold'}>{invite.email}</Text>
                                    </Table.BodyCell>
                                    <Table.BodyCell shrink>
                                        <RequirePermission permission={'user.write'}>
                                            <Reveal.Hidden>
                                                <Button spacing='compact' icon='far fa-trash' onClick={() => deleteInvite(invite.id)}/>
                                            </Reveal.Hidden>
                                        </RequirePermission>
                                    </Table.BodyCell>
                                </Table.Row>
                            </Reveal>
                        )
                    }
                </Table.Body>
            </Table>
        </RequirePermission>
    );
}