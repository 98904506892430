import {GlassChangeEvent} from '@glimpse/glass';
import {StaticFilter}     from 'foundations/ui/filter/static-filter';
import {useTranslation}   from 'foundations/i18n/use-translation';
import {PipelineRun}      from '@glimpse/glimpse';

export function PipelineStatusFilter({
    value,
    onChange,
    name,
    label
}: {
    value: any,
    onChange: (event: GlassChangeEvent) => void,
    name: string,
    label: string,
}) {
    const {t} = useTranslation();
    const options: {
        label: string,
        value: Exclude<PipelineRun['status'], null>
    }[]       = [
        {
            value: 'success',
            label: t('Success')
        },
        {
            value: 'error',
            label: t('Error')
        },
        {
            value: 'cancelled',
            label: t('Cancelled')
        },
        {
            value: 'in_progress',
            label: t('In progress')
        },
        {
            value: 'pending',
            label: t('Pending')
        }
    ];

    return <StaticFilter options={options} value={value} onChange={onChange} name={name} label={label}/>;
}