import {Entitlement} from '@glimpse/glimpse';
import {useQuery}    from 'foundations/net/use-query';
import {glimpse}     from 'providers/glimpse/glimpse-provider';

export function useEntitlement(name: string) {
    const entitlements = useQuery<Entitlement>({
        key: ['entitlement'],
        fn:  glimpse.entitlement.list
    });

    return entitlements.data.find((ent) => ent.name === name);
}