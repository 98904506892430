import {Box, Text} from '@glimpse/glass';
import React       from 'react';

export function EmptyStateSmall({
    text
}: {
    text: string
}) {
    return (
        <Box border={'dashed'} pt={4} pb={4} xAlign={'center'} radius={1}>
            <Text color='text.subtle'>{text}</Text>
        </Box>
    );
}