import {Modal, Button, Form, Textbox, Textarea, Flex} from '@glimpse/glass';
import {Action, Component}                            from '@glimpse/glimpse';
import {useForm}                                      from 'foundations/ui/form/use-form';
import {glimpse}                                      from 'providers/glimpse/glimpse-provider';
import {useTranslation}                               from 'foundations/i18n/use-translation';
import toast                                          from 'react-hot-toast';
import {SelectComponent}                              from 'features/component/select-component';
import {useMutate}                                    from 'foundations/net/use-mutate';

export function CreateActionModal({
    open,
    onClose,
    componentId
}: {
    open: boolean,
    onClose: () => void,
    componentId?: Component['id'],
}) {
    const {t}                      = useTranslation();
    const form                     = useForm<Action.Create.Params>({
        component_id: componentId
    });
    const {error, loading, submit} = useMutate<Action>({
        key:       ['action', componentId],
        fn:        () => glimpse.action.create(form.data),
        onSuccess: () => {
            toast.success(t('Action added'));
            onClose();
        }
    });

    return (
        <Modal onClose={onClose} open={open}>
            <Modal.Header onClose={onClose} title={t('Add an action')}/>
            <Modal.Body>
                <Form>
                    {
                        componentId &&
                        <Form.Field label={t('Component')} errors={error?.params.component_id}>
                            <SelectComponent value={componentId} name={'component_id'} onChange={() => {
                            }} disabled/>
                        </Form.Field>
                    }
                    <Form.Field label={t('Name (Required)')} errors={error?.params.name} description={t('Name of the action, visible to the team')}>
                        <Textbox {...form.register('name', {required: true})}/>
                    </Form.Field>
                    <Form.Field label={t('Action Url')} errors={error?.params.url} description={t('URL that will be called to handle the action')}>
                        <Textbox {...form.register('url', {required: true})} placeholder={'https://rpc.example.com/force-deploy-backend'}/>
                    </Form.Field>
                    <Form.Field label={t('Action Payload')} errors={error?.params.payload} description={t('Additional data to send to the URL')}>
                        <Textarea {...form.register('payload', {required: true})}/>
                    </Form.Field>
                    <Form.Field label={t('Description')} errors={error?.params.description} description={t('Help your team understand what this action does')}>
                        <Textarea {...form.register('description')}/>
                    </Form.Field>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Flex justify={'end'}>
                    <Button onClick={onClose}>{t('Cancel')}</Button>
                    <Button variant='primary' loading={loading} onClick={submit} disabled={!form.valid}>{t('Add Action')}</Button>
                </Flex>
            </Modal.Footer>
        </Modal>
    );
}