import {Scaffold}                                                    from '@glimpse/glimpse';
import {Modal, Button, Form, Textbox, BoxedSelector, Textarea, Flex} from '@glimpse/glass';
import {useForm}                                                     from 'foundations/ui/form/use-form';
import {SelectRepository}                                            from 'features/repository/select-repository';
import {glimpse}                                                     from 'providers/glimpse/glimpse-provider';
import {useTranslation}                                              from 'foundations/i18n/use-translation';
import toast                                                         from 'react-hot-toast';
import {useMutate}                                                   from 'foundations/net/use-mutate';
import {useMemo}                                                     from 'react';

export function CreateScaffoldModal({
    open,
    onClose
}: {
    open: boolean,
    onClose: () => void
}) {
    const {t}  = useTranslation();
    const form = useForm<Scaffold.Create.Params>({
        mechanism: 'git'
    });

    const {error, submit, loading} = useMutate({
        key:       ['scaffold'],
        fn:        () => glimpse.scaffold.create(form.data),
        onSuccess: () => {
            toast.success(t('Scaffold added'));
            onClose();
        }
    });

    const options = [
        {label: t('Git'), value: 'git'},
        {label: t('Cookiecutter'), value: 'cookiecutter'}
    ];

    const desc = useMemo(() => {
        switch (form.data.mechanism) {
            case 'git':
                return t('Git is the simplest and quickest way to use scaffolds. It requires very little setup and works with any project.');
            case 'cookiecutter':
                return t('Cookiecutter offers added flexibility and variables, but requires more setup.');

        }
    }, [form.data.mechanism]);

    return (
        <Modal onClose={onClose} open={open}>
            <Modal.Header onClose={onClose} title={t('Add a scaffold')}/>
            <Modal.Body>
                <Form.Field label={'Mechanism'} description={desc}>
                    <BoxedSelector {...form.register('mechanism', {required: true})} options={options}/>
                </Form.Field>
                <Form onSubmit={submit}>
                    <Form.Field label={t('Name')} errors={error?.params.name}>
                        <Textbox {...form.register('name', {required: true})} placeholder='Ruby Api'/>
                    </Form.Field>
                    <Form.Field label={t('Repository')} errors={error?.params.template_url}>
                        <SelectRepository {...form.register('template_url', {required: true})}/>
                    </Form.Field>
                    {
                        form.data.mechanism === 'git' &&
                        <Form.Field label={t('Sub directory')} errors={error?.params.template_dir}>
                            <Textbox {...form.register('template_dir')} placeholder={'/templates/my-template'}/>
                        </Form.Field>
                    }
                    <Form.Field label={t('Description')} errors={error?.params.description} description={t('Add a description to help other develops understand this scaffold and what it\'s used for')}>
                        <Textarea {...form.register('description')}/>
                    </Form.Field>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Flex justify={'end'} align={'center'}>
                    <Button onClick={onClose}>{t('Cancel')}</Button>
                    <Button variant='primary' loading={loading} onClick={submit} disabled={!form.valid}>{t('Add Scaffold')}</Button>
                </Flex>
            </Modal.Footer>
        </Modal>
    );
}