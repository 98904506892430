import {Modal, Button, Form, Textbox, Textarea, Flex} from '@glimpse/glass';
import {ErrorModal}                                   from 'foundations/ui/error-modal/error-modal';
import {LoadingModal}                                 from 'foundations/ui/loading-modal/loading-modal';
import {useForm}                                      from 'foundations/ui/form/use-form';
import React                                          from 'react';
import {Action, Component}                            from '@glimpse/glimpse';
import {glimpse}                                      from 'providers/glimpse/glimpse-provider';
import {useTranslation}                               from 'foundations/i18n/use-translation';
import toast                                          from 'react-hot-toast';
import {useQuery}                                     from 'foundations/net/use-query';
import {useMutate}                                    from 'foundations/net/use-mutate';

export function UpdateActionModal({
    open,
    onClose,
    actionId
}: {
    open: boolean,
    onClose: () => void,
    actionId: Component['id']
}) {
    const {t}  = useTranslation();
    const form = useForm<Action.Update.Params>();
    const load = useQuery<Action>({
        key:       ['action', {id: actionId}],
        fn:        () => glimpse.action.list({id: actionId}),
        onSuccess: (res) => form.setData(res?.data[0] as Action.Update.Params)
    });

    const mutate = useMutate<Action>({
        key:       ['action', actionId],
        fn:        () => glimpse.action.update(actionId, form.data),
        onSuccess: () => {
            toast.success(t('Action updated'));
            onClose();
        }
    });

    if (load.loading)
        return <LoadingModal onClose={onClose} open={open}/>;

    if (load.error)
        return <ErrorModal onClose={onClose} open={open}/>;

    return (
        <Modal onClose={onClose} open={open}>
            <Modal.Header onClose={onClose} title={t('Update this action')}/>
            <Modal.Body>
                <Form>
                    <Form.Field label={t('Name (Required)')} errors={mutate.error?.params.name} description={t('Name of the action, visible to the team')}>
                        <Textbox {...form.register('name')}/>
                    </Form.Field>
                    <Form.Field label={t('Description')} errors={mutate.error?.params.description} description={t('Help your team understand what this action does')}>
                        <Textarea {...form.register('description')}/>
                    </Form.Field>
                    <Form.Field label={t('Action Url (Required)')} errors={mutate.error?.params.url} description={t('URL that will be called to handle the action')}>
                        <Textbox {...form.register('url')} placeholder={'https://rpc.example.com/force-deploy-backend'}/>
                    </Form.Field>
                    <Form.Field label={t('Action Payload')} errors={mutate.error?.params.payload} description={t('Additional data to send to the URL')}>
                        <Textarea {...form.register('payload')}/>
                    </Form.Field>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Flex justify={'end'}>
                    <Button onClick={onClose}>{t('Cancel')}</Button>
                    <Button variant='primary' loading={mutate.loading} onClick={mutate.submit}>{t('Save Changes')}</Button>
                </Flex>
            </Modal.Footer>
        </Modal>
    );
}