import {Modal, Button, Form, Textbox, Textarea, Text, Flex} from '@glimpse/glass';
import {Scorecard}                                          from '@glimpse/glimpse';
import {useForm}                                            from 'foundations/ui/form/use-form';
import {glimpse}                                            from 'providers/glimpse/glimpse-provider';
import {useTranslation}                                     from 'foundations/i18n/use-translation';
import toast                                                from 'react-hot-toast';
import {useNavigate}                                        from 'react-router-dom';
import {useMutate}                                          from 'foundations/net/use-mutate';

export function CreateScorecardModal({
    open,
    onClose
}: {
    open: boolean,
    onClose: () => void
}) {
    const navigate                 = useNavigate();
    const {t}                      = useTranslation();
    const form                     = useForm<Scorecard.Create.Params>();
    const {error, loading, submit} = useMutate<Scorecard>({
        key:       ['scorecard'],
        fn:        async () => await glimpse.scorecard.create(form.data),
        onSuccess: (res) => {
            toast.success(t('Scorecard added'));
            navigate(`/scorecards/${res.data[0].id}`);
            onClose();
        }
    });

    return (
        <Modal onClose={onClose} open={open}>
            <Modal.Header onClose={onClose} title={t('Add a scorecard')}>
                <Text color='text.subtle'>
                    {t('Add a scorecard to begin creating rules against your components and integrated data.')}
                </Text>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Field label={t('Name')} errors={error?.params.name}>
                        <Textbox {...form.register('name', {required: true})} placeholder={t('Gold Service Level')}/>
                    </Form.Field>
                    <Form.Field label={t('Description')} errors={error?.params.description}>
                        <Textarea {...form.register('description')}/>
                    </Form.Field>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Flex justify={'end'}>
                    <Button onClick={onClose}>{t('Cancel')}</Button>
                    <Button variant='primary' loading={loading} onClick={submit} disabled={!form.valid}>{t('Add Scorecard')}</Button>
                </Flex>
            </Modal.Footer>
        </Modal>
    );
}