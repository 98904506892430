import {AppLayout}                                                             from 'foundations/layouts/app-layout/app-layout';
import {Box, Button, Flex, Grid, Icon, Image, Spaced, Text, Tooltip, useModal} from '@glimpse/glass';
import React                                                                   from 'react';
import {useParams}                                                             from 'react-router-dom';
import {RequirePermission}                                                     from 'features/permission/require-permission';
import {useTranslation}                                                        from 'foundations/i18n/use-translation';
import {IntegrationHelper}                                                     from 'features/integration/integration-helper';
import {useQuery}                                                              from 'foundations/net/use-query';
import {glimpse}                                                               from 'providers/glimpse/glimpse-provider';
import {BillingProductUpsell}                                                  from 'features/billing-product/billing-product-upsell';
import {useEntitlement}                                                        from 'features/entitlement/use-entitlement';
import {IntegrationInstallSection}                                             from 'features/integration-install/integration-install-section';
import {IntegrationConnectorCapabilitiesSection}                               from 'features/integration-connector/integration-connector-capabilities-section';
import {SyncIntegrationModal}                                                  from 'features/integration/sync-integration-modal';

export function SettingsIntegrationScreen() {
    const {connector} = useParams();
    const {t}         = useTranslation();
    const meta        = IntegrationHelper.get(connector!);
    const entitlement = useEntitlement('multi-install');

    const {data} = useQuery({
        key: ['integration', {connector}],
        fn:  () => glimpse.integration.list({connector})
    });

    return (
        <AppLayout>
            <RequirePermission permission={'workspace.write'} fallback>
                <AppLayout.Header>
                    <Box pt={3} pb={3}>
                        <Flex justify={'between'}>
                            <Flex gap={2} align={'center'}>
                                <Image height={'40px'} source={meta?.icon}/>
                                <Box>
                                    <Text fontSize={4} fontWeight='bolder'>{meta?.label}</Text>
                                    <Text color={'text.subtle'}>{t('Manage how this integration interacts with your workspace')}</Text>
                                </Box>
                            </Flex>
                            <div>
                                <RequirePermission permission={'integration.write'}>
                                    <MultiInstallButton connector={connector!}/>
                                </RequirePermission>
                            </div>
                        </Flex>
                    </Box>
                </AppLayout.Header>
                <AppLayout.Body>
                    <Grid gap={4}>
                        <Grid.Item xs={8}>
                            <Spaced gap={3} direction={'vertical'}>
                                <IntegrationInstallSection connector={connector!}/>
                                {
                                    typeof data[0] !== 'undefined' &&
                                    meta.capabilities?.multiInstall &&
                                    !entitlement?.entitled &&
                                    <BillingProductUpsell
                                        icon={<Icon fa={['fas', 'arrow-down-big-small']} fontSize={4} color={'secondary'}/>}
                                        desc={t('With Multi-install, you can install multiple instances of the same integration. Combine different accounts, organizations or permissions scopes to get exactly the data you need.')}
                                        title={t(`Need to connect multiple ${meta.label} accounts?`)}
                                        preTitle={t('Multi-install')}/>
                                }
                            </Spaced>
                        </Grid.Item>
                        <Grid.Item xs={4}>
                            <Spaced gap={3} direction={'vertical'}>
                                <IntegrationConnectorCapabilitiesSection connectorId={connector!}/>
                            </Spaced>
                        </Grid.Item>
                    </Grid>
                </AppLayout.Body>
            </RequirePermission>
        </AppLayout>
    );
}

const MultiInstallButton = ({
    connector
}: {
    connector: string,
}) => {
    const {data, loading} = useQuery({
        key: ['integration', {connector}],
        fn:  () => glimpse.integration.list({connector})
    });

    const {t}         = useTranslation();
    const entitlement = useEntitlement('multi-install');
    const meta        = IntegrationHelper.get(connector!);
    const hasInstall  = typeof data[0] !== 'undefined';

    if (loading) {
        return <Button
            disabled
            icon={<Icon fa={['far', 'plus']}/>}
            variant={'primary'}>
            {t('Install')}
        </Button>;
    }

    if (!hasInstall || (meta.capabilities?.multiInstall && entitlement?.entitled)) {
        return <Button
            onClick={meta.install}
            icon={<Icon fa={['far', 'plus']}/>}
            variant={'primary'}>
            {t('Install')}
        </Button>;
    }

    // Integration does not support multi install
    if (!meta.capabilities?.multiInstall) {
        return <Tooltip content={t('This integration does not support multiple installs')}>
            <Button
                disabled={true}
                icon={<Icon fa={['far', 'lock']}/>}
                variant={'primary'}>
                {t('Install')}
            </Button>
        </Tooltip>;
    }

    // Subscription does not support multi install
    if (!entitlement?.entitled) {
        return <Tooltip content={t(`Upgrade to install additional ${meta.label} accounts`)}>
            <Button
                disabled={true}
                icon={<Icon fa={['far', 'lock']}/>}
                variant={'primary'}>
                {t('Install')}
            </Button>
        </Tooltip>;
    }

    return null;
};