import {IssueTable}     from 'features/issue/issue-table';
import {useForm}        from 'foundations/ui/form/use-form';
import {Box, Text}      from '@glimpse/glass';
import {AppLayout}      from 'foundations/layouts/app-layout/app-layout';
import React            from 'react';
import {useTranslation} from 'foundations/i18n/use-translation';
import {Searchbox}      from 'foundations/ui/searchbox/searchbox';
import {AutoFilter}     from 'foundations/ui/filter/auto-filter';

export function IssuesScreen() {
    const {t}  = useTranslation();
    const form = useForm();

    const options = [
        {label: t('Open'), value: 'open'},
        {label: t('Closed'), value: 'closed'},
        {label: t('All'), value: 'all'}
    ];

    return (
        <AppLayout>
            <AppLayout.Header>
                <Box pt={3} pb={3}>
                    <Text fontSize={4} fontWeight='bolder'>{t('Issues')}</Text>
                </Box>
            </AppLayout.Header>
            <AppLayout.Body>
                <Box mb={2}>
                    <Searchbox {...form.register('query')}/>
                </Box>
                <Box mb={2}>
                    <AutoFilter label={t('State')} resource={'issue'} property={'state'} {...form.register('state')}/>
                </Box>
                <IssueTable query={form.data}/>
            </AppLayout.Body>
        </AppLayout>
    );
}