import {Grid, Text, Box, Flex, Code, Skeleton} from '@glimpse/glass';
import {useParams}                             from 'react-router-dom';
import {AppLayout}                             from 'foundations/layouts/app-layout/app-layout';
import {useTranslation}                        from 'foundations/i18n/use-translation';
import {ScorecardResult}                       from '@glimpse/glimpse';
import {glimpse}                               from 'providers/glimpse/glimpse-provider';
import {useQuery}                              from 'foundations/net/use-query';
import {RequirePermission}                     from 'features/permission/require-permission';
import {ScorecardRunStatus}                    from 'features/scorecard-run/scorecard-run-status';
import {ScorecardRunHistoryChart}              from 'features/scorecard-run/scorecard-run-history-chart';

export function ScorecardResultScreen() {
    const {t}                                = useTranslation();
    const {component_id, scorecard_id}       = useParams();
    const query: ScorecardResult.List.Params = {
        scorecard_id,
        component_id
    };

    const {data, loading} = useQuery<ScorecardResult>({
        key: ['scorecard-result', query],
        fn:  () => glimpse.scorecardResult.list(query)
    });

    return (
        <AppLayout>
            <RequirePermission permission={'scorecard.read'} fallback>
                <AppLayout.Header>
                    <Box pt={3} pb={3}>
                        <Grid justify='between'>
                            <div>
                                <Text fontSize={4} fontWeight='bolder'>{t('Scorecard Results')}</Text>
                            </div>
                        </Grid>
                    </Box>
                </AppLayout.Header>
                <AppLayout.Body>
                    <>
                        <Box mb={1} ml={0.5}>
                            <Grid justify='between'>
                                <Text fontWeight='bold' fontSize={3}>{t('Status')}</Text>
                            </Grid>
                        </Box>
                        <Box mb={3}>
                            {
                                loading &&
                                <Grid xs={6} gap={2}>
                                    <Skeleton height={'60px'}/>
                                    <Skeleton height={'60px'}/>
                                    <Skeleton height={'60px'}/>
                                </Grid>
                            }
                            <Grid xs={6} gap={2}>
                                {
                                    data?.[0]?.results.map((result) =>
                                        <Box border={'default'} radius={1} p={2}>
                                            <Flex align={'center'} justify={'between'}>
                                                <Flex align={'center'} gap={1}>
                                                    <ScorecardRunStatus passed={result.latest_run?.passed}/>
                                                    <Text fontWeight={'bold'}>{result.rule?.name}</Text>
                                                </Flex>
                                                <Code>{result.rule.gql}</Code>
                                            </Flex>
                                            {
                                                result.latest_run?.passed === false &&
                                                <Text color={'text.subtle'}>{result.latest_run?.message}</Text>
                                            }
                                        </Box>
                                    )
                                }
                            </Grid>
                        </Box>
                        <Box mb={1} ml={0.5}>
                            <Grid justify='between'>
                                <Text fontWeight='bold' fontSize={3}>{t('History')}</Text>
                            </Grid>
                        </Box>
                        <Box border={'default'} radius={1} bg={'n0'}>
                            <div style={{height: 400}}>
                                <ScorecardRunHistoryChart query={{
                                    ...query,
                                    limit: 14
                                }}/>
                            </div>
                        </Box>
                    </>
                </AppLayout.Body>
            </RequirePermission>
        </AppLayout>
    );
}