export const GlimpseChartTheme = {
    axis: {
        domain: {
            line: {
                strokeWidth: 0
            }
        },
        ticks: {
            line: {
                stroke: '#f5f5f7'
            }
        }
    },
    grid: {
        line: {
            stroke: '#f5f5f7'
        }
    }
};