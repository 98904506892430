import {Scaffold}                                                    from '@glimpse/glimpse';
import {Grid, Box, Text, useModal, Icon, Flex, Button, Reveal, Menu} from '@glimpse/glass';
import {ScaffoldModal}                                               from 'features/scaffold/scaffold-modal';
import React                                                         from 'react';
import {glimpse}                                                     from 'providers/glimpse/glimpse-provider';
import {Empty}                                                       from 'foundations/ui/empty/empty';
import {UpdateScaffoldModal}                                         from 'features/scaffold/update-scaffold-modal';
import {DeleteScaffoldModal}                                         from 'features/scaffold/delete-scaffold-modal';
import {useTranslation}                                              from 'foundations/i18n/use-translation';
import {ObjectUtil}                                                  from 'foundations/ui/object-util/object-util';
import {CenteredSpinner}                                             from 'foundations/ui/interstitial/centered-spinner';
import {useQuery}                                                    from 'foundations/net/use-query';
import {RequirePermission}                                           from 'features/permission/require-permission';

export function ScaffoldGrid({
    query = {}
}: {
    query?: Scaffold.List.Params,
}) {
    const {t}             = useTranslation();
    const {data, loading} = useQuery<Scaffold>({
        key: ['scaffold', query],
        fn:  () => glimpse.scaffold.list(query)
    });

    if (loading) {
        return <CenteredSpinner/>;
    }

    if (data?.length === 0) {
        return ObjectUtil.isEmpty(query)
            ? <Empty
                title={t('Add your first scaffold')}
                icon={<Icon fa={['far', 'fa-box-open']}/>}
                desc={t('Use scaffolds to quickly start new projects or components. Create your first scaffold and it will appear here.')}/>
            : <Empty
                title={t('No scaffolds matching filters')}
                icon={<Icon fa={['far', 'fa-box-open']}/>}
                desc={t('Change your filters or search query to find other scaffolds.')}/>;
    }

    return (
        <RequirePermission permission={'scaffold.read'} fallback>
            <Grid gap={2} xs={12} md={4} lg={4}>
                {
                    data?.map(scaffold => <Item scaffold={scaffold} key={scaffold.id}/>)
                }
            </Grid>
        </RequirePermission>
    );
}

const Item = ({
    scaffold
}: {
    scaffold: Scaffold
}) => {
    const {t}         = useTranslation();
    const viewModal   = useModal();
    const updateModal = useModal();
    const deleteModal = useModal();

    const menu = (
        <>
            <Menu.Group>
                <Menu.Item onClick={updateModal.toggle}>
                    {t('Edit details')}
                </Menu.Item>
                <Menu.Item onClick={deleteModal.toggle}>
                    {t('Delete scaffold')}
                </Menu.Item>
            </Menu.Group>
        </>
    );

    return (
        <>
            {
                updateModal.open &&
                <UpdateScaffoldModal open={updateModal.open} onClose={updateModal.toggle} scaffoldId={scaffold.id}/>
            }
            {
                deleteModal.open &&
                <DeleteScaffoldModal open={deleteModal.open} onClose={deleteModal.toggle} scaffoldId={scaffold.id}/>
            }
            {
                viewModal.open &&
                <ScaffoldModal onClose={viewModal.toggle} open={viewModal.open} scaffoldId={scaffold.id}/>
            }
            <Reveal>
                <Box p={3} radius={1} border={'default'} effects={['hoverable']}>
                    <Box pb={0.5}>
                        <Flex justify={'between'}>
                            <Text fontWeight='bold' fontSize={2}>{scaffold.name}</Text>
                            <RequirePermission permission={'scaffold.write'}>
                                <Menu menu={menu}>
                                    <Reveal.Hidden>
                                        <Button spacing={'compact'} variant={'subtle'} icon={<Icon fa={['fas', 'ellipsis']}/>}/>
                                    </Reveal.Hidden>
                                </Menu>
                            </RequirePermission>
                        </Flex>
                    </Box>
                    {
                        scaffold.description &&
                        <Box mt={1}>
                            <Text color='text.subtle'>{scaffold.description}</Text>
                        </Box>
                    }
                    <Box mt={2}>
                        <Button onClick={viewModal.toggle} fullwidth>{t('Use scaffold')}</Button>
                    </Box>
                </Box>
            </Reveal>
        </>
    );
};