import {PullRequest}                          from '@glimpse/glimpse';
import {Modal, Button, Text, Flex, List, Box} from '@glimpse/glass';
import {glimpse}                              from 'providers/glimpse/glimpse-provider';
import {useTranslation}                       from 'foundations/i18n/use-translation';
import toast                                  from 'react-hot-toast';
import {useMutate}                            from 'foundations/net/use-mutate';
import {FormFallbackError}                    from 'foundations/ui/form/form-fallback-error';

export function DeletePullRequestModal({
    open,
    onClose,
    pullRequestId
}: {
    open: boolean,
    onClose: () => void,
    pullRequestId: PullRequest['id'],
}) {
    const {t}                      = useTranslation();
    const {loading, submit, error} = useMutate({
        key:       ['pull-request', pullRequestId],
        fn:        async () => await glimpse.pullRequest.delete(pullRequestId),
        onSuccess: () => {
            toast.success(t('PR removed'));
            onClose();
        }
    });

    return (
        <Modal onClose={onClose} open={open}>
            <Modal.Header onClose={onClose} title={t('Remove pull request?')}/>
            <Modal.Body>
                <FormFallbackError error={error}/>
                <Text>
                    {t('Are you sure you want to remove this pull request from Glimpse?')}
                </Text>
            </Modal.Body>
            <Modal.Footer>
                <Flex justify={'end'}>
                    <Button onClick={onClose}>{t('Cancel')}</Button>
                    <Button variant='destructive' loading={loading} onClick={submit}>{t('Remove Pull Request')}</Button>
                </Flex>
            </Modal.Footer>
        </Modal>
    );
}