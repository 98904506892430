import {Modal, Button, Form, Alert, Box, Flex, Textarea} from '@glimpse/glass';
import {ErrorModal}                                      from 'foundations/ui/error-modal/error-modal';
import {LoadingModal}                                    from 'foundations/ui/loading-modal/loading-modal';
import {useForm}                                         from 'foundations/ui/form/use-form';
import React                                             from 'react';
import {Pipeline}                                        from '@glimpse/glimpse';
import {glimpse}                                         from 'providers/glimpse/glimpse-provider';
import {useTranslation}                                  from 'foundations/i18n/use-translation';
import toast                                             from 'react-hot-toast';
import {useMutate}                                       from 'foundations/net/use-mutate';
import {useQuery}                                        from 'foundations/net/use-query';
import {FormFallbackError}                               from 'foundations/ui/form/form-fallback-error';
import {SelectPipelineEnvironment}                       from 'features/pipeline/select-pipeline-environment';

export function UpdatePipelineModal({
    open,
    onClose,
    pipelineId
}: {
    open: boolean,
    onClose: () => void,
    pipelineId: Pipeline['id']
}) {
    const {t}  = useTranslation();
    const form = useForm();

    const load = useQuery<Pipeline>({
        key:       ['pipeline', pipelineId],
        fn:        async () => await glimpse.pipeline.list({id: pipelineId}),
        onSuccess: (res) => form.setData(res.data[0])
    });

    const mutate = useMutate<Pipeline>({
        key:       ['pipeline'],
        fn:        async () => await glimpse.pipeline.update(pipelineId, form.data),
        onSuccess: () => {
            toast.success(t('Pipeline updated'));
            onClose();
        }
    });

    if (load.loading)
        return <LoadingModal onClose={onClose} open={open}/>;

    if (load.error)
        return <ErrorModal onClose={onClose} open={open}/>;

    return (
        <Modal onClose={onClose} open={open}>
            <Modal.Header onClose={onClose} title={t('Update this pipeline')}/>
            <Modal.Body>
                {
                    load.data?.[0]?.source_connector &&
                    <Box mb={2}>
                        <Alert bg={'info.subtle'}>
                            {t('This pipeline is synced by an integration. Some details may be overridden by the integration.')}
                        </Alert>
                    </Box>
                }
                <Form>
                    <FormFallbackError error={mutate.error}/>
                    <Form.Field label={t('Environment')} errors={mutate.error?.params.environment}>
                        <SelectPipelineEnvironment {...form.register('environment')}/>
                    </Form.Field>
                    <Form.Field label={t('Description')} errors={mutate.error?.params.description}>
                        <Textarea {...form.register('description')}/>
                    </Form.Field>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Flex justify={'end'}>
                    <Button onClick={onClose}>{t('Cancel')}</Button>
                    <Button variant='primary' loading={mutate.loading} onClick={mutate.submit}>{t('Save Changes')}</Button>
                </Flex>
            </Modal.Footer>
        </Modal>
    );
}