import React, {useMemo} from 'react';
import styles           from 'foundations/ui/chart/chart-legend.module.css';
import {Flex}           from '@glimpse/glass';

export function ChartLegend({
    children
}: {
    children: React.ReactNode
}) {
    return (
        <Flex align={'center'} gap={1.5}>
            {children}
        </Flex>
    );
}

function Item({
    label,
    color
}: {
    label: string,
    color: string
}) {
    const formatted = useMemo(() => {
        const words = label.replaceAll('_', ' ');
        return words.charAt(0).toUpperCase() + words.slice(1);
    }, [label]);

    return (
        <Flex align={'center'} gap={0.5}>
            <div className={styles.dot} style={{backgroundColor: color}}/>
            <span className={styles.label}>{formatted}</span>
        </Flex>
    );
}

ChartLegend.Item = Item;