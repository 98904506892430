import {Modal, Button, Form, Textbox, Box, Alert, Text, Flex, Code} from '@glimpse/glass';
import {ApiKey}                                                     from '@glimpse/glimpse';
import {useForm}                                                    from 'foundations/ui/form/use-form';
import {glimpse}                                                    from 'providers/glimpse/glimpse-provider';
import {useTranslation}                                             from 'foundations/i18n/use-translation';
import toast                                                        from 'react-hot-toast';
import {useState}                                                   from 'react';
import {useMutate}                                                  from 'foundations/net/use-mutate';

export function CreateApiKeyModal({
    onClose
}: {
    onClose: () => void
}) {
    const {t}                            = useTranslation();
    const form                           = useForm<ApiKey.Create.Params>();
    const [complete, setComplete]        = useState(false);
    const {data, error, loading, submit} = useMutate<ApiKey>({
        key:       ['api-key'],
        fn:        () => glimpse.apiKey.create(form.data),
        onSuccess: () => {
            toast.success(t('API key added'));
            setComplete(true);
        }
    });

    return (
        <Modal onClose={onClose} open={true}>
            <Modal.Header onClose={onClose} title={t('Add an API key')}/>
            <Modal.Body>
                {
                    !complete &&
                    <Form>
                        <Form.Field label={t('Name')} errors={error?.params.name}>
                            <Textbox {...form.register('name', {required: true})} placeholder={t('My API')}/>
                        </Form.Field>
                    </Form>
                }
                {
                    complete &&
                    <>
                        <Box mb={2}>
                            <Alert bg={'success.subtle'}>
                                {t('API Key Created! Make sure to copy your API Key now, it will be hidden after closing this window')}
                            </Alert>
                        </Box>
                        <Box mb={1}>
                            <Text fontWeight={'bold'}>{t('Your API Key')}</Text>
                        </Box>
                        <Code>{data?.[0].key}</Code>
                    </>
                }
            </Modal.Body>
            <Modal.Footer>
                <Flex justify={'end'}>
                    {
                        complete ?
                            <Button onClick={onClose}>{t('Done')}</Button>
                            : <>
                                <Button onClick={onClose}>{t('Cancel')}</Button>
                                <Button variant='primary' loading={loading} onClick={submit} disabled={!form.valid}>{t('Add API Key')}</Button>
                            </>
                    }
                </Flex>
            </Modal.Footer>
        </Modal>
    );
}