import {Grid, Text, Button, Skeleton, useModal, Box, Spaced, Menu, Flex, Tabs} from '@glimpse/glass';
import {UpdateComponentModal}       from 'features/component/update-component-modal';
import {ComponentDependencySection} from 'features/component-dependency/component-dependency-section';
import {useParams}                  from 'react-router-dom';
import {AppLayout}                                                             from 'foundations/layouts/app-layout/app-layout';
import {DeleteComponentModal}                                                  from 'features/component/delete-component-modal';
import {glimpse}                                                               from 'providers/glimpse/glimpse-provider';
import React                                                                   from 'react';
import {RequirePermission}                                                     from 'features/permission/require-permission';
import {useTranslation}                                                        from 'foundations/i18n/use-translation';
import {useQuery}                                                              from 'foundations/net/use-query';
import {Component}             from '@glimpse/glimpse';
import {ComponentOwnerSection}  from 'features/component-owner/component-owner-section';
import {ComponentMetaSection}   from 'features/component/component-meta-section';
import {ScorecardResultSection} from 'features/scorecard-result/scorecard-result-section';
import {LinkSection}            from 'features/link/link-section';
import {ManageIntegrationAppModal} from 'features/integration-app/manage-integration-app-modal';
import {ComponentPipelineSection} from 'features/component-pipeline/component-pipeline-section';
import {PropertyValueSection}     from 'features/property-value/property-value-section';

export function ComponentScreen() {
    const {id}             = useParams();
    const {t}              = useTranslation();
    const integrationModal = useModal();
    const updateModal      = useModal();
    const deleteModal      = useModal();

    const {data, loading} = useQuery<Component>({
        key: ['component', id],
        fn:  async () => await glimpse.component.list({id})
    });

    const component = data?.[0];

    const menu = (
        <>
            <Menu.Group>
                <Menu.Item onClick={updateModal.toggle}>
                    {t('Edit details')}
                </Menu.Item>
                {
                    false &&
                    <Menu.Item onClick={integrationModal.toggle}>
                        {t('Integrations')}
                    </Menu.Item>
                }
                <Menu.Item onClick={deleteModal.toggle}>
                    {t('Delete component')}
                </Menu.Item>
            </Menu.Group>
        </>
    );

    return (
        <AppLayout>
            <RequirePermission permission={'component.read'} fallback>
                {
                    integrationModal.open &&
                    <ManageIntegrationAppModal open={integrationModal.open} onClose={integrationModal.toggle} componentId={id!}/>
                }
                {
                    deleteModal.open &&
                    <DeleteComponentModal open={deleteModal.open} onClose={deleteModal.toggle} componentId={id!}/>
                }
                {
                    updateModal.open &&
                    <UpdateComponentModal open={updateModal.open} onClose={updateModal.toggle} componentId={id!}/>
                }
                <AppLayout.Header>
                    <Box pt={3} pb={3}>
                        <Grid justify='between'>
                            <div>
                                <Box pb={1}>
                                    {
                                        loading
                                            ? <Skeleton width='200px' height='22px'/>
                                            : <Flex align={'center'}>
                                                <Text fontSize={4} fontWeight='bolder'>{component?.name}</Text>
                                            </Flex>
                                    }
                                </Box>
                                {
                                    loading
                                        ? <Skeleton width='150px' height='22px'/>
                                        : <Text color='text.subtle'>{component?.description ?? t('No Description')}</Text>
                                }
                            </div>
                            <div>
                                <RequirePermission permission={'component.write'}>
                                    <Menu menu={menu}>
                                        <Button icon={'fas fa-ellipsis'}/>
                                    </Menu>
                                </RequirePermission>
                            </div>
                        </Grid>
                    </Box>
                    <Tabs onChange={() => {
                    }}>
                        <Tabs.Tab value={'overview'} current={'overview'} label={t('Overview')}/>
                    </Tabs>
                </AppLayout.Header>
                <AppLayout.Body>
                    <Grid gap={6}>
                        <Grid.Item xs={8}>
                            <Spaced gap={3} direction={'vertical'}>
                                <ScorecardResultSection componentId={id!}/>
                                <ComponentDependencySection componentId={id!}/>
                                <ComponentPipelineSection componentId={id!}/>
                            </Spaced>
                        </Grid.Item>
                        <Grid.Item xs={4}>
                            <Spaced gap={3} direction={'vertical'}>
                                <ComponentOwnerSection componentId={id!}/>
                                <PropertyValueSection componentId={id!}/>
                                <LinkSection componentId={id!}/>
                                <ComponentMetaSection componentId={id!}/>
                            </Spaced>
                        </Grid.Item>
                    </Grid>
                </AppLayout.Body>
            </RequirePermission>
        </AppLayout>
    );
}