import {UsersTable}                from 'features/user/users-table';
import {CreateInviteModal}         from 'features/invite/create-invite-modal';
import {useModal, Grid, Text, Box} from '@glimpse/glass';
import {RequirePermission}         from 'features/permission/require-permission';
import React                       from 'react';
import {AppLayout}                 from 'foundations/layouts/app-layout/app-layout';
import {Searchbox}                 from 'foundations/ui/searchbox/searchbox';
import {useForm}                   from 'foundations/ui/form/use-form';
import {User}                      from '@glimpse/glimpse';
import {useTranslation}            from 'foundations/i18n/use-translation';

export function UsersScreen() {
    const {t}          = useTranslation();
    const createInvite = useModal();
    const form         = useForm<User.List.Params>();

    return (
        <AppLayout title={t('People')}>
            <RequirePermission permission={'user.read'}>
                <CreateInviteModal open={createInvite.open} onClose={createInvite.toggle}/>
                <AppLayout.Header>
                    <Box pt={3} pb={3}>
                        <Grid justify='between' align='center'>
                            <Text fontSize={4} fontWeight='bolder'>{t('People')}</Text>
                        </Grid>
                    </Box>
                </AppLayout.Header>
                <AppLayout.Body>
                    <Box mb={2}>
                        <Searchbox {...form.register('query')}/>
                    </Box>
                    <UsersTable query={form.data}/>
                </AppLayout.Body>
            </RequirePermission>
        </AppLayout>
    );
}