import {Box, Text, Button, useModal, Icon, Flex} from '@glimpse/glass';
import {ComponentDependency}                     from '@glimpse/glimpse';
import {RequirePermission}                       from 'features/permission/require-permission';
import {useTranslation}                          from 'foundations/i18n/use-translation';
import {CreateComponentOwnerModal}               from 'features/component-owner/create-component-owner-modal';
import React                                     from 'react';
import {faPlus}                                  from '@fortawesome/pro-light-svg-icons';
import {ComponentOwnerList}                      from 'features/component-owner/component-owner-list';

export function ComponentOwnerSection({
    componentId
}: {
    componentId: ComponentDependency['dependent_id']
}) {
    const createModal = useModal();
    const {t}         = useTranslation();

    return (
        <RequirePermission permission={'component.read'}>
            {
                createModal.open &&
                <CreateComponentOwnerModal open={createModal.open} onClose={createModal.toggle} componentId={componentId}/>
            }
            <Box mb={1} ml={0.5}>
                <Flex justify='between' align={'center'}>
                    <Text fontWeight='bold' fontSize={3}>{t('Owners')}</Text>
                    <RequirePermission permission={'component.write'}>
                        <Button spacing={'compact'} icon={<Icon fa={faPlus}/>} onClick={createModal.toggle}/>
                    </RequirePermission>
                </Flex>
            </Box>
            <ComponentOwnerList query={{component_id: componentId}}/>
        </RequirePermission>
    );
}