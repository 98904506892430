import {Auth}                                        from '@glimpse/glimpse';
import {Text, Form, Textbox, Box, Button, Separator} from '@glimpse/glass';
import {AuthLayout}                                  from 'foundations/layouts/auth-layout/auth-layout';
import {useNavigate}                                 from 'react-router-dom';
import {useForm}                                     from 'foundations/ui/form/use-form';
import {glimpse}                                     from 'providers/glimpse/glimpse-provider';
import {useTranslation}                              from 'foundations/i18n/use-translation';
import {useMutate}                                   from 'foundations/net/use-mutate';
import {useAuth}                                     from 'features/auth/use-auth';
import {useEffect, useState}                         from 'react';
import {AuthButton}                                  from 'features/auth/auth-button/auth-button';
import {useGoogleAuth}                               from 'features/auth/google-auth/use-google-auth';
import toast                                         from 'react-hot-toast';

export function SignupScreen() {
    const {t}                 = useTranslation();
    const {signIn}            = useAuth();
    const [method, setMethod] = useState<string>('password');
    const navigate            = useNavigate();
    const form                = useForm<Auth.Signup.Params>();

    const createAccount = useMutate({
        key:       ['account'],
        fn:        glimpse.auth.signup,
        onSuccess: (res) => signIn(res.data[0].token)
    });

    const googleAuth = useGoogleAuth({
        onSuccess: (auth, profile) => {
            setMethod('google');
            createAccount.submit({
                method:       'google',
                google_token: auth.access_token,
                email:        profile.email,
                name:         profile.name
            });
        }
    });

    const passwordAuth = () => createAccount.submit({
        ...form.data,
        method: 'password'
    });

    useEffect(() => {
        if (method !== 'password' && createAccount.error?.code) {
            const error = createAccount.error?.params?.['email']?.[0]
                ?? createAccount.error?.params?.['name']?.[0]
                ?? createAccount.error?.message
                ?? t('Unable to sign up');

            toast.error(error);
        }
    }, [createAccount.error, method]);

    const login = () => navigate('/login');

    return (
        <AuthLayout>
            <Box xAlign={'center'} mb={6}>
                <Text fontSize={5} fontWeight='bold'>{t('Get started for free')}</Text>
            </Box>
            <Form onSubmit={passwordAuth}>
                <Form.Field label={t('Full Name')} errors={method === 'password' ? createAccount.error?.params.name : []}>
                    <Textbox {...form.register('name')} placeholder='James Smith'/>
                </Form.Field>
                <Form.Field label={t('Email')} errors={method === 'password' ? createAccount.error?.params.email : []}>
                    <Textbox {...form.register('email')} placeholder='james@workplace.com'/>
                </Form.Field>
                <Form.Field label={t('Password')} errors={method === 'password' ? createAccount.error?.params.password : []}>
                    <Textbox {...form.register('password')} placeholder={t('Password')} type={'password'}/>
                </Form.Field>
            </Form>
            <Box pt={4}>
                <Button fullwidth onClick={passwordAuth} variant='primary' spacing='large' loading={createAccount.loading}>{t('Signup')}</Button>
            </Box>
            <Box pt={2} pb={2}>
                <Separator/>
            </Box>
            <Box mb={4}>
                <AuthButton method={'google'} text={t('Signup with Google')} onClick={googleAuth.submit}/>
            </Box>
            <Box xAlign='right'>
                <Button variant='link' onClick={login}>{t('Have an account already?')}</Button>
            </Box>
        </AuthLayout>
    );
}