import {Box, Text, Button, useModal, Flex, Icon} from '@glimpse/glass';
import {ComponentPipeline}                       from '@glimpse/glimpse';
import {RequirePermission}                       from 'features/permission/require-permission';
import {useTranslation}                          from 'foundations/i18n/use-translation';
import {faPlus}                                  from '@fortawesome/pro-light-svg-icons';
import React                                     from 'react';
import {ComponentPipelineTable}                  from 'features/component-pipeline/component-pipeline-table';
import {CreateComponentPipelineModal}            from 'features/component-pipeline/create-component-pipeline-modal';

export function ComponentPipelineSection({
    componentId
}: {
    componentId: ComponentPipeline['component_id']
}) {
    const createModal = useModal();
    const {t}         = useTranslation();

    return (
        <>
            {
                createModal.open &&
                <CreateComponentPipelineModal open={createModal.open} onClose={createModal.toggle} componentId={componentId}/>
            }
            <Box mb={1} ml={0.5}>
                <Flex justify='between' align={'center'}>
                    <Text fontWeight='bold' fontSize={3}>{t('Pipelines')}</Text>
                    <RequirePermission permission={'component.write'}>
                        <Button spacing={'compact'} icon={<Icon fa={faPlus}/>} onClick={createModal.toggle}/>
                    </RequirePermission>
                </Flex>
            </Box>
            <ComponentPipelineTable componentId={componentId}/>
        </>
    );
}