import {Component, PropertyValue}                                          from '@glimpse/glimpse';
import {Box, Button, Flex, Icon, Reveal, Skeleton, Spaced, Text, useModal} from '@glimpse/glass';
import React, {useState}                                                   from 'react';
import {glimpse}                                                           from 'providers/glimpse/glimpse-provider';
import {useTranslation}                                                    from 'foundations/i18n/use-translation';
import {useQuery}                                                          from 'foundations/net/use-query';
import {CenteredSpinner}                                                   from 'foundations/ui/interstitial/centered-spinner';
import {RequirePermission}                                                 from 'features/permission/require-permission';
import {UpdatePropertyValueModal}                                          from 'features/property-value/update-property-value-modal';
import {EmptyStateSmall}                                                   from 'foundations/ui/empty-state-small/empty-state-small';

export function PropertyValueGrid({
    componentId
}: {
    componentId: Component['id']
}) {
    const {t}                               = useTranslation();
    const updateModal                       = useModal();
    const [selectedValue, setSelectedValue] = useState<PropertyValue['id']>();
    const query                             = {
        component_id: componentId,
        expand:       ['property']
    };
    const {data, loading}                   = useQuery<PropertyValue>({
        key: ['property-value', query],
        fn:  () => glimpse.propertyValue.list(query)
    });

    const openUpdate = (id: PropertyValue['id']) => {
        setSelectedValue(id);
        updateModal.toggle();
    };

    if (loading) {
        return (
            <Box border={'default'} xAlign={'center'} radius={1}>
                <CenteredSpinner/>
            </Box>
        );
    }

    if (data?.length === 0) {
        return <EmptyStateSmall text={t('No properties')}/>
    }

    return (
        <>
            {
                selectedValue &&
                <UpdatePropertyValueModal open={updateModal.open} onClose={updateModal.toggle} propertyValueId={selectedValue}/>
            }
            <Box border={'default'} radius={1}>
                {
                    loading &&
                    <Spaced gap={2} direction={'vertical'}>
                        <Skeleton height={'22px'}/>
                        <Skeleton height={'22px'}/>
                        <Skeleton height={'22px'}/>
                    </Spaced>
                }
                {
                    data?.map((value) => {
                        return (
                            <Reveal>
                                <Box pl={2} pr={2} pt={1.5} pb={1.5} effects={value?.url ? ['clickable', 'hoverable'] : ['hoverable']} key={value.id}>
                                    <Flex justify={'between'}>
                                        <div>
                                            <Text fontWeight={'bold'}>{value.property?.label}</Text>
                                            <Text>{value.value}</Text>
                                        </div>
                                        <Reveal.Hidden>
                                            <div>
                                                <RequirePermission permission={'component.write'}>
                                                    <Button onClick={() => openUpdate(value?.id)} spacing='compact' icon={<Icon fa='far fa-pen'/>}/>
                                                </RequirePermission>
                                            </div>
                                        </Reveal.Hidden>
                                    </Flex>
                                </Box>
                            </Reveal>
                        );
                    })
                }
            </Box>
        </>
    );
}