import {TeamMember}                                      from '@glimpse/glimpse';
import {Button, Text, useModal, Reveal, Grid, Icon, Box} from '@glimpse/glass';
import {DeleteTeamMemberModal}                           from 'features/team-member/delete-team-member-modal';
import React            from 'react';
import {glimpse}        from 'providers/glimpse/glimpse-provider';
import {useTranslation} from 'foundations/i18n/use-translation';
import {useQuery}                                        from 'foundations/net/use-query';
import {RequirePermission}                               from 'features/permission/require-permission';
import {CenteredSpinner}                                 from 'foundations/ui/interstitial/centered-spinner';
import {EmptyStateSmall}                                 from 'foundations/ui/empty-state-small/empty-state-small';

export function TeamMemberList({
    query
}: {
    query: TeamMember.List.Params
}) {
    const {t}                     = useTranslation();
    const deleteModal             = useModal();
    const [selected, setSelected] = React.useState<TeamMember['id']>();
    const q                       = {
        ...query,
        expand: ['user']
    };
    const {data, loading}         = useQuery<TeamMember>({
        key: ['team-member', q],
        fn:  () => glimpse.teamMember.list(q)
    });

    const deleteMember = (id: TeamMember['id']) => {
        setSelected(id);
        deleteModal.toggle();
    };

    if (loading) {
        return (
            <Box border={'default'} xAlign={'center'} radius={1}>
                <CenteredSpinner/>
            </Box>
        );
    }

    if (data?.length === 0) {
        return <EmptyStateSmall text={t('No members yet')}/>
    }

    return (
        <RequirePermission permission={'team.read'}>
            {
                selected &&
                <DeleteTeamMemberModal open={deleteModal.open} onClose={deleteModal.toggle} teamMemberId={selected as string}/>
            }
            <Box radius={1} p={2} border={'default'}>
                {
                    data?.map(teamMember =>
                        <Box radius={1} ml={-1} key={teamMember.id} effects={['hoverable', 'clickable']}>
                            <Reveal>
                                <Box pl={1} p={0.5}>
                                    <Grid justify='between' align='center'>
                                        <Text>{teamMember.user?.name}</Text>
                                        <RequirePermission permission={'team.write'}>
                                            <Reveal.Hidden>
                                                <div>
                                                    <Button onClick={() => deleteMember(teamMember.id)} spacing='compact' icon={<Icon fa='far fa-xmark'/>}/>
                                                </div>
                                            </Reveal.Hidden>
                                        </RequirePermission>
                                    </Grid>
                                </Box>
                            </Reveal>
                        </Box>
                    )
                }
            </Box>
        </RequirePermission>
    );
}