import {GlassChangeEvent} from '@glimpse/glass';
import {StaticFilter}     from 'foundations/ui/filter/static-filter';
import {useTranslation}   from 'foundations/i18n/use-translation';
import {ScorecardRun}     from '@glimpse/glimpse';

export function ScorecardRunStatusFilter({
    value,
    onChange,
    name,
    label
}: {
    value: any,
    onChange: (event: GlassChangeEvent) => void,
    name: string,
    label: string,
}) {
    const {t} = useTranslation();
    const options: {
        label: string,
        value: ScorecardRun['passed']
    }[]       = [
        {
            value: true,
            label: t('Passed')
        },
        {
            value: false,
            label: t('Failed')
        }
    ];

    return <StaticFilter options={options} value={value} onChange={onChange} name={name} label={label}/>;
}