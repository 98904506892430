import {Box, Text, Button, useModal, Reveal, Icon, Flex, Avatar} from '@glimpse/glass';
import {ComponentOwner, User}                                    from '@glimpse/glimpse';
import {RequirePermission}                                       from 'features/permission/require-permission';
import {useTranslation}                                          from 'foundations/i18n/use-translation';
import React, {useState}                                         from 'react';
import {useQuery}                                                from 'foundations/net/use-query';
import {glimpse}                                                 from 'providers/glimpse/glimpse-provider';
import {DeleteComponentOwner}                                    from 'features/component-owner/delete-component-owner';
import {useNavigate}                                             from 'react-router-dom';
import {UserSlideout}                                            from 'features/user/user-slideout';
import {CenteredSpinner}                                         from 'foundations/ui/interstitial/centered-spinner';
import {EmptyStateSmall}                                         from 'foundations/ui/empty-state-small/empty-state-small';

export function ComponentOwnerList({
    query
}: {
    query: ComponentOwner.List.Params
}) {
    const {t}                     = useTranslation();
    const navigate                = useNavigate();
    const deleteModal             = useModal();
    const userModal               = useModal();
    const [selected, setSelected] = useState<ComponentOwner['id']>();
    const q                       = {
        ...query,
        expand: ['user', 'team']
    };
    const {data, loading}         = useQuery<ComponentOwner>({
        key: ['component-owner', q],
        fn:  async () => glimpse.componentOwner.list(q)
    });

    const deleteMember = (id: ComponentOwner['id']) => {
        setSelected(id);
        deleteModal.toggle();
    };

    const viewUser = (id: User['id']) => {
        setSelected(id);
        userModal.toggle();
    };

    if (loading) {
        return (
            <Box border={'default'} xAlign={'center'} radius={1}>
                <CenteredSpinner/>
            </Box>
        );
    }

    if (data?.length === 0) {
        return <EmptyStateSmall text={t('No owners')}/>;
    }

    return (
        <RequirePermission permission={'component.read'}>
            {
                selected &&
                <>
                    <UserSlideout open={userModal.open} onClose={userModal.toggle} userId={selected}/>
                    <DeleteComponentOwner open={deleteModal.open} onClose={deleteModal.toggle} componentOwnerId={selected!}/>
                </>

            }
            <Box p={2} radius={1} border={'default'}>
                {
                    data?.map(owner =>
                        <Box radius={1} ml={-1} key={owner.id} effects={['hoverable', 'clickable']}>
                            <Reveal>
                                <div onClick={
                                    () => owner.team_id
                                        ? navigate(`/teams/${owner.team_id}`)
                                        : viewUser(owner.user_id)}>
                                    <Box pl={1} p={0.5}>
                                        <Flex justify='between' align='center'>
                                            <Flex align={'center'}>
                                                <Avatar size={28} name={owner.user?.name ?? owner.team?.name ?? ''}/>
                                                <Box ml={1}>
                                                    <Text>{owner.user?.name ?? owner.team?.name}</Text>
                                                </Box>
                                            </Flex>
                                            <Reveal.Hidden>
                                                <div>
                                                    <RequirePermission permission={'component.write'}>
                                                        <Button onClick={() => deleteMember(owner.id)} spacing='compact' icon={<Icon fa='far fa-xmark'/>}/>
                                                    </RequirePermission>
                                                </div>
                                            </Reveal.Hidden>
                                        </Flex>
                                    </Box>
                                </div>
                            </Reveal>
                        </Box>
                    )
                }
            </Box>
        </RequirePermission>
    );
}