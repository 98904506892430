import {PullRequest}      from '@glimpse/glimpse';
import {Flex, Icon, Text} from '@glimpse/glass';
import {useTranslation}   from 'foundations/i18n/use-translation';
import {ageFromTimestamp} from 'foundations/ui/date/age-from-timestamp';

export function PullRequestTimeOpenBadge({
    timeOpen
}: {
    timeOpen: PullRequest['time_open']
}) {
    const {t}           = useTranslation();
    const {value, unit} = ageFromTimestamp(timeOpen);

    if (unit === 'years') {
        return (
            <Flex gap={1} align={'center'}>
                <Icon color={'danger'} fa={['fas', 'triangle-exclamation']}/>
                <Text color={'danger'}>{`${value.toFixed(0)} ${t('years')}`}</Text>
            </Flex>
        );
    }

    if (unit === 'months') {
        if (value > 4) {
            return (
                <Flex gap={1} align={'center'}>
                    <Icon color={'danger'} fa={['fas', 'triangle-exclamation']}/>
                    <Text color={'danger'}>{`${value.toFixed(0)} ${t('months')}`}</Text>
                </Flex>
            );
        } else {
            return (
                <Flex gap={1} align={'center'}>
                    <Icon color={'danger'} fa={['fas', 'triangle-exclamation']}/>
                    <Text color={'danger'}>{`${value.toFixed(0)} ${t('months')}`}</Text>
                </Flex>
            );
        }
    }

    if (unit === 'weeks') {
        return <Text>{`${value.toFixed(0)} ${t('weeks')}`}</Text>;
    }

    if (unit === 'days') {
        return <Text>{`${value.toFixed(0)} ${t('days')}`}</Text>;
    }

    if (unit === 'hours') {
        return <Text>{`${value.toFixed(0)}h`}</Text>;
    }

    if (unit === 'minutes') {
        return <Text>{`${value.toFixed(0)}m`}</Text>;
    }

    return <Text>{`${value.toFixed(0)}s`}</Text>;
};