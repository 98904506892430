import {AppLayout}         from 'foundations/layouts/app-layout/app-layout';
import {Box, Grid, Text}   from '@glimpse/glass';
import React               from 'react';
import {IntegrationPanel}  from 'features/integration/integration-panel';
import {RequirePermission} from 'features/permission/require-permission';
import {useTranslation}    from 'foundations/i18n/use-translation';
import {IntegrationHelper} from 'features/integration/integration-helper';

export function SettingsIntegrationsScreen() {
    const {t} = useTranslation();

    return (
        <AppLayout title={t('Integrations')}>
            <RequirePermission permission={'integration.write'} fallback>
                <AppLayout.Header>
                    <Box pt={3} pb={3}>
                        <Box mb={1}>
                            <Text fontSize={4} fontWeight='bolder'>{t('Integrations')}</Text>
                        </Box>
                        <Text color={'text.subtle'}>{t('Connect powerful apps to supercharge Glimpse')}</Text>
                    </Box>
                </AppLayout.Header>
                <AppLayout.Body>
                    <Grid gap={2} xs={3} align={'stretch'}>
                        {
                            IntegrationHelper.listAll()
                                .filter((int) => int.install)
                                .map(integration =>
                                <IntegrationPanel
                                    connector={integration.connector}
                                    logo={integration.icon}
                                    label={integration.label}/>
                            )
                        }
                    </Grid>
                </AppLayout.Body>
            </RequirePermission>
        </AppLayout>
    );
}