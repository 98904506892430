import {Action}                    from '@glimpse/glimpse';
import {Modal, Button, Text, Flex} from '@glimpse/glass';
import {ErrorModal}                from 'foundations/ui/error-modal/error-modal';
import {LoadingModal}              from 'foundations/ui/loading-modal/loading-modal';
import {glimpse}                   from 'providers/glimpse/glimpse-provider';
import {useTranslation}            from 'foundations/i18n/use-translation';
import {useQuery}                  from 'foundations/net/use-query';
import {useMutate}                 from 'foundations/net/use-mutate';

export function CreateActionRunModal({
    onClose,
    open,
    actionId
}: {
    onClose: () => void,
    open: boolean,
    actionId: Action['id']
}) {
    const {t}  = useTranslation();
    const load = useQuery<Action>({
        key: ['action', actionId],
        fn:  () => glimpse.action.list({id: actionId})
    });
    const run  = useMutate({
        key: ['action', actionId],
        fn:  () => glimpse.actionRun.create({action_id: actionId})
    });

    if (load.loading)
        return <LoadingModal onClose={onClose} open={open}/>;

    if (load.error)
        return <ErrorModal onClose={onClose} open={open}/>;

    return (
        <Modal onClose={onClose} open={open}>
            <Modal.Header onClose={onClose} title={load.data?.[0].name ?? ''}/>
            <Modal.Body>
                <Text>{load.data?.[0].description ?? t('This action has no description. Consider adding one.')}</Text>
            </Modal.Body>
            <Modal.Footer>
                <Flex justify={'end'}>
                    <Button onClick={onClose}>{t('Cancel')}</Button>
                    <Button onClick={run.submit} variant={'primary'}>{t('Run Action')}</Button>
                </Flex>
            </Modal.Footer>
        </Modal>
    );
}