import {Box, Flex, Grid, Text, useModal} from '@glimpse/glass';
import React                             from 'react';
import {AppLayout}                       from 'foundations/layouts/app-layout/app-layout';
import {useTranslation}                  from 'foundations/i18n/use-translation';
import {CreateActionModal}               from 'features/action/create-action-modal';
import {Searchbox}                       from 'foundations/ui/searchbox/searchbox';
import {useForm}                         from 'foundations/ui/form/use-form';
import {PipelineStatusFilter}            from 'features/pipeline/pipeline-status-filter';
import {PipelineEnvironmentFilter}       from 'features/pipeline/pipeline-environment-filter';
import {AutoFilter}                      from 'foundations/ui/filter/auto-filter';
import {PipelineTable}                   from 'features/pipeline/pipeline-table';

export function PipelinesScreen() {
    const {t}         = useTranslation();
    const form        = useForm();
    const createModal = useModal();

    return (
        <AppLayout title={t('Pipelines')}>
            <CreateActionModal open={createModal.open} onClose={createModal.toggle}/>
            <AppLayout.Header>
                <Box pt={3} pb={3}>
                    <Grid justify='between' align='center'>
                        <Text fontSize={4} fontWeight='bolder'>{t('Pipelines')}</Text>
                    </Grid>
                </Box>
            </AppLayout.Header>
            <AppLayout.Body>
                <Box mb={1.5}>
                    <Searchbox {...form.register('query')}/>
                </Box>
                <Box mb={2}>
                    <Flex gap={1}>
                        <AutoFilter property={'source_connector'} resource={'pipeline'} {...form.register('source_connector')} label={t('Source')}/>
                        <PipelineStatusFilter {...form.register('status')} label={t('Status')}/>
                        <PipelineEnvironmentFilter {...form.register('environment')} label={t('Environment')}/>
                    </Flex>
                </Box>
                <PipelineTable query={form.data}/>
            </AppLayout.Body>
        </AppLayout>
    );
}