import {ComponentPipeline}   from '@glimpse/glimpse';
import {Modal, Button, Text, Flex} from '@glimpse/glass';
import {glimpse}             from 'providers/glimpse/glimpse-provider';
import {useTranslation}      from 'foundations/i18n/use-translation';
import toast                 from 'react-hot-toast';
import {useMutate}           from 'foundations/net/use-mutate';
import {FormFallbackError}   from 'foundations/ui/form/form-fallback-error';

export function DeleteComponentPipelineModal({
    open,
    onClose,
    componentPipelineId
}: {
    open: boolean,
    onClose: () => void,
    componentPipelineId: ComponentPipeline['id'],
}) {
    const {t}                      = useTranslation();
    const {submit, loading, error} = useMutate({
        key:       ['component-pipeline', componentPipelineId],
        fn:        () => glimpse.componentPipeline.delete(componentPipelineId),
        onSuccess: () => {
            toast.success(t('Pipeline removed'));
            onClose();
        }
    });

    return (
        <Modal onClose={onClose} open={open}>
            <Modal.Header onClose={onClose} title={t('Remove this pipeline?')}/>
            <Modal.Body>
                <FormFallbackError error={error}/>
                <Text>
                    {t('Are you sure you want to remove this pipeline from the component? Other components will not be affected.')}
                </Text>
            </Modal.Body>
            <Modal.Footer>
                <Flex justify={'end'}>
                    <Button onClick={onClose}>{t('Cancel')}</Button>
                    <Button variant='destructive' loading={loading} onClick={submit}>{t('Remove Pipeline')}</Button>
                </Flex>
            </Modal.Footer>
        </Modal>
    );
}