import {Modal, Button, Text, Flex} from '@glimpse/glass';
import {useTranslation}            from 'foundations/i18n/use-translation';
import {useAuth}        from 'features/auth/use-auth';

export function SignoutModal({
    open,
    onClose
}: {
    open: boolean,
    onClose: () => void,
}) {
    const {signOut} = useAuth();
    const {t}       = useTranslation();
    return (
        <Modal onClose={onClose} open={open}>
            <Modal.Header onClose={onClose} title={t('Sign out?')}/>
            <Modal.Body>
                <Text>
                    {t('Are you sure you want to sign out?')}
                </Text>
            </Modal.Body>
            <Modal.Footer>
                <Flex justify={'end'}>
                    <Button onClick={onClose}>{t('Cancel')}</Button>
                    <Button variant='destructive' onClick={signOut}>{t('Sign Out')}</Button>
                </Flex>
            </Modal.Footer>
        </Modal>
    );
}