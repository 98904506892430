import {Modal, Button, Form, Textbox, Textarea, GlassChangeEvent, Flex} from '@glimpse/glass';
import {ErrorModal}                                                     from 'foundations/ui/error-modal/error-modal';
import {LoadingModal}                                                   from 'foundations/ui/loading-modal/loading-modal';
import {useForm}                                                        from 'foundations/ui/form/use-form';
import {glimpse}                                                        from 'providers/glimpse/glimpse-provider';
import {useTranslation}                                                 from 'foundations/i18n/use-translation';
import toast                                                            from 'react-hot-toast';
import {Property}                                                       from '@glimpse/glimpse';
import {SelectPropertyFormat}                                           from 'features/property/select-property-format';
import {useMutate}                                                      from 'foundations/net/use-mutate';
import {useQuery}                                                       from 'foundations/net/use-query';

export function UpdatePropertyModal({
    open,
    onClose,
    propertyId
}: {
    open: boolean,
    onClose: () => void,
    propertyId: Property['id']
}) {
    const {t}    = useTranslation();
    const form   = useForm<Property.Update.Params>();
    const update = useMutate<Property>({
        key:       ['property', propertyId],
        fn:        () => glimpse.property.update(propertyId, form.data),
        onSuccess: () => {
            toast.success(t('Property updated'));
            onClose();
        }
    });

    const load = useQuery<Property>({
        key:       ['property', propertyId],
        fn:        () => glimpse.property.list({id: propertyId}),
        onSuccess: (res) => form.setData(res.data[0] as Property.Update.Params)
    });

    if (load.loading) {
        return <LoadingModal onClose={onClose} open={open}/>;
    }

    if (load.error) {
        return <ErrorModal onClose={onClose} open={open}/>;
    }

    const onChangeName = (event: GlassChangeEvent) => {
        form.setData({
            ...form.data,
            [event.currentTarget.name]: event.currentTarget.value
                                            .toLowerCase()
                                            .replaceAll(/[^a-z]/g, '_')
        });
    };

    return (
        <Modal onClose={onClose} open={open}>
            <Modal.Header onClose={onClose} title={t('Update this property')}/>
            <Modal.Body>
                <Form>
                    <Form.Field label={t('Name')} errors={update.error?.params.name} description={t('Give your property a unique name, machine-friendly that can be used with GlimpseQL')}>
                        <Textbox {...form.register('name')} onChange={onChangeName} placeholder={t('tier')}/>
                    </Form.Field>
                    <Form.Field label={t('Display Name')} errors={update.error?.params.name}>
                        <Textbox {...form.register('label')} placeholder={t('Tier')}/>
                    </Form.Field>
                    <Form.Field label={t('Format')} errors={update.error?.params.format}>
                        <SelectPropertyFormat {...form.register('format')}/>
                    </Form.Field>
                    {
                        form.data.format === 'select' &&
                        <Form.Field label={t('Select Options')} errors={update.error?.params.options} description={'Please provide a comma seperated list like (Option A, Option B, ...)'}>
                            <Textbox {...form.register('options')}/>
                        </Form.Field>
                    }
                    <Form.Field label={t('Description')} errors={update.error?.params.description}>
                        <Textarea {...form.register('description')}/>
                    </Form.Field>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Flex justify={'end'}>
                    <Button onClick={onClose}>{t('Cancel')}</Button>
                    <Button variant='primary' loading={update.loading} onClick={update.submit}>{t('Save Changes')}</Button>
                </Flex>
            </Modal.Footer>
        </Modal>
    );
}