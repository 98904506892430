import React, {useEffect, useRef, useState} from 'react';
import {GlassChangeEvent, ObjectUtility}    from '@glimpse/glass';

export function useForm<S extends Record<string, any>>(data: Partial<S> = {}): {
    data: S,
    setData: (data: S) => void,
    set: (prop: keyof S, value: any) => void,
    valid: boolean,
    register: (name: keyof S, options?: {
        required?: boolean
    }) => {
        name: string,
        value: any,
        onChange: (event: GlassChangeEvent) => void
    }
} {
    const [valid, setValid]       = useState<boolean>(false);
    const required                = useRef<Partial<Record<keyof S, boolean>>>({});
    const [formData, setFormData] = React.useState<Partial<S>>(data);

    const register = (name: keyof S, options?: {
        required?: boolean,
    }) => {
        if (typeof required.current[name] === 'undefined') {
            required.current[name] = options?.required;
        }
        return {
            name:     name as any,
            value:    formData[name] as any ?? undefined,
            onChange: (event: GlassChangeEvent) => {
                const newData = {...formData};
                ObjectUtility.setNestedKey(newData, name as string, event.currentTarget.value);
                setFormData(newData);
            }
        };
    };

    useEffect(() => {
        for (const key of Object.keys(required.current)) {
            if (required.current[key] && (formData[key] === null || typeof formData[key] === 'undefined' || formData[key] === '')) {
                return;
            }
        }

        setValid(true);
    }, [JSON.stringify(required.current), JSON.stringify(formData)]);


    const set = (prop: keyof S, value: any) => {
        const data = {...formData};
        ObjectUtility.setNestedKey(data, prop as string, value);
        setFormData(data);
    };

    return {
        data:    formData as S,
        setData: (data: S) => setFormData(data ?? {}),
        register,
        valid,
        set
    };
}