import {Grid, Text, Button, Skeleton, useModal, Box, Menu, Tabs, useTabs, Flex} from '@glimpse/glass';
import {useParams}                                                              from 'react-router-dom';
import {AppLayout}                                                              from 'foundations/layouts/app-layout/app-layout';
import {glimpse}                                                                from 'providers/glimpse/glimpse-provider';
import {Scorecard, ScorecardRun}                                                from '@glimpse/glimpse';
import {DeleteScorecardModal}                                                   from 'features/scorecard/delete-scorecard-modal';
import {UpdateScorecardModal}                                                   from 'features/scorecard/update-scorecard-modal';
import {RequirePermission}                                                      from 'features/permission/require-permission';
import {useTranslation}                                                         from 'foundations/i18n/use-translation';
import {useQuery}                                                               from 'foundations/net/use-query';
import React                                                                    from 'react';
import {ScorecardAssignmentSection}                                             from 'features/scorecard-assignment/scorecard-assignment-section';
import {useForm}                                                                from 'foundations/ui/form/use-form';
import {ScorecardRuleSection}                                                   from 'features/scorecard-rule/scorecard-rule-section';
import {ScorecardRunStatusFilter}                                               from 'features/scorecard-run/scorecard-run-status-filter';
import {ScorecardRunTable}                                                      from 'features/scorecard-run/scorecard-run-table';

export function ScorecardScreen() {
    const {t}         = useTranslation();
    const {id}        = useParams();
    const updateModal = useModal();
    const deleteModal = useModal();
    const tabs        = useTabs('overview');
    const historyForm = useForm<ScorecardRun.List.Params>({
        scorecard_id: id!,
        limit:        50
    });
    const {data}      = useQuery<Scorecard>({
        key: ['scorecard'],
        fn:  glimpse.scorecard.list
    });

    const scorecard = data?.[0];

    const menu = (
        <>
            <Menu.Group>
                <Menu.Item onClick={updateModal.toggle}>
                    {t('Edit details')}
                </Menu.Item>
                <Menu.Item onClick={deleteModal.toggle}>
                    {t('Delete scorecard')}
                </Menu.Item>
            </Menu.Group>
        </>
    );

    return (
        <AppLayout>
            <RequirePermission permission={'scorecard.read'} fallback>
                {
                    updateModal.open &&
                    <UpdateScorecardModal open={updateModal.open} onClose={updateModal.toggle} scorecardId={id!}/>
                }
                {
                    deleteModal.open &&
                    <DeleteScorecardModal open={deleteModal.open} onClose={deleteModal.toggle} scorecardId={id!}/>
                }
                <AppLayout.Header>
                    <Box pt={3} pb={3}>
                        <Grid justify='between'>
                            <div>
                                <Box pb={1}>
                                    {
                                        scorecard?.name === undefined
                                            ? <Skeleton width='200px' height='22px'/>
                                            : <Text fontSize={4} fontWeight='bolder'>{scorecard.name}</Text>
                                    }
                                </Box>
                                {
                                    scorecard?.description === undefined
                                        ? <Skeleton width='150px' height='22px'/>
                                        : <Text color='text.subtle'>{scorecard.description ?? 'No Description'}</Text>
                                }
                            </div>
                            <div>
                                <RequirePermission permission={'scaffold.write'}>
                                    <Menu menu={menu}>
                                        <Button icon={'fas fa-ellipsis'}/>
                                    </Menu>
                                </RequirePermission>
                            </div>
                        </Grid>
                    </Box>
                    <Tabs onChange={tabs.change}>
                        <Tabs.Tab value={'overview'} current={tabs.current} label={t('Overview')}/>
                        <Tabs.Tab value={'history'} current={tabs.current} label={t('History')}/>
                    </Tabs>
                </AppLayout.Header>
                <AppLayout.Body>
                    {
                        tabs.current === 'overview' &&
                        <Grid gap={6} xs={12}>
                            <Grid.Item xs={5}>
                                <Box pb={2}>
                                    <ScorecardRuleSection scorecardId={id!}/>
                                </Box>
                            </Grid.Item>
                            <Grid.Item xs={7}>
                                <Box pb={2}>
                                    <ScorecardAssignmentSection scorecardId={id!}/>
                                </Box>
                            </Grid.Item>
                        </Grid>
                    }
                    {
                        tabs.current === 'history' &&
                        <div>
                            <Box mb={2}>
                                <Flex gap={1}>
                                    <ScorecardRunStatusFilter {...historyForm.register('passed')} label={t('Status')}/>
                                </Flex>
                            </Box>
                            <ScorecardRunTable query={historyForm.data}/>
                        </div>
                    }
                </AppLayout.Body>
            </RequirePermission>
        </AppLayout>
    );
}