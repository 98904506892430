/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export var ContentType;
(function (ContentType) {
  ContentType["Json"] = "application/json";
  ContentType["FormData"] = "multipart/form-data";
  ContentType["UrlEncoded"] = "application/x-www-form-urlencoded";
  ContentType["Text"] = "text/plain";
})(ContentType || (ContentType = {}));
export class HttpClient {
  baseUrl = "https://api.withglimpse.com";
  key = null;
  securityData = null;
  securityWorker;
  abortControllers = new Map();
  customFetch = (...fetchParams) => fetch(...fetchParams);
  baseApiParams = {
    credentials: "same-origin",
    headers: {},
    redirect: "follow",
    referrerPolicy: "no-referrer",
  };
  constructor(key, apiConfig = {}) {
    this.key = key;
    Object.assign(this, apiConfig);
  }
  setSecurityData = (data) => {
    this.securityData = data;
  };
  encodeQueryParam(key, value) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === "number" ? value : `${value}`)}`;
  }
  addQueryParam(query, key) {
    return this.encodeQueryParam(key, query[key]);
  }
  addArrayQueryParam(query, key) {
    const value = query[key];
    return value.map((v) => this.encodeQueryParam(key, v)).join("&");
  }
  toQueryString(rawQuery) {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => "undefined" !== typeof query[key]);
    return keys
      .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join("&");
  }
  addQueryParams(rawQuery) {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : "";
  }
  contentFormatters = {
    [ContentType.Json]: (input) =>
      input !== null && (typeof input === "object" || typeof input === "string") ? JSON.stringify(input) : input,
    [ContentType.Text]: (input) => (input !== null && typeof input !== "string" ? JSON.stringify(input) : input),
    [ContentType.FormData]: (input) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === "object" && property !== null
            ? JSON.stringify(property)
            : `${property}`,
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input) => this.toQueryString(input),
  };
  mergeRequestParams(params1, params2) {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }
  createAbortSignal = (cancelToken) => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }
    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };
  abortRequest = (cancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);
    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };
  request = async ({ body, secure, path, type, query, format, baseUrl, cancelToken, ...params }) => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;
    return this.customFetch(`${baseUrl || this.baseUrl || ""}${path}${queryString ? `?${queryString}` : ""}`, {
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
        "X-Api-key": this.key,
      },
      signal: (cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal) || null,
      body: typeof body === "undefined" || body === null ? null : payloadFormatter(body),
    }).then(async (response) => {
      const r = response;
      r.data = null;
      r.error = null;
      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.data = data;
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });
      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }
      return data.data;
    });
  };
}
/**
 * @title Glimpse API
 * @version next
 * @termsOfService https://withglimpse.com/terms
 * @baseUrl https://api.withglimpse.com
 */
export class Glimpse extends HttpClient {
  action = {
    /**
     * @description Create an new action that can be used to facilitate developer self-service.
     *
     * @tags action
     * @name Create
     * @summary Create an action
     * @request POST:/action
     * @secure
     * @response `200` `(Action)[]` Action
     */
    create: (data, params = {}) =>
      this.request({
        path: `/action`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags action
     * @name List
     * @summary List actions
     * @request GET:/action
     * @secure
     * @response `200` `(Action)[]` Array of actions
     */
    list: (query, params = {}) =>
      this.request({
        path: `/action`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
    /**
     * @description Updates the specified action with the supplied parameters. Any parameters not provided will be left unchanged.
     *
     * @tags action
     * @name Update
     * @summary Update an action
     * @request POST:/action/{id}
     * @secure
     * @response `200` `(Action)[]` Returns the updated action object if the update succeeded. Throws an error if the parameters were invalid, such as an invalid url.
     */
    update: (id, data, params = {}) =>
      this.request({
        path: `/action/${id}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags action
     * @name Delete
     * @summary Delete an action
     * @request DELETE:/action/{id}
     * @secure
     * @response `200` `(any)[]` Empty
     */
    delete: (id, params = {}) =>
      this.request({
        path: `/action/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  actionRun = {
    /**
     * @description Run an action by creating a new action run
     *
     * @tags action-run
     * @name Create
     * @summary Run an action
     * @request POST:/action-run
     * @secure
     * @response `200` `(ActionRun)[]` Action Run
     */
    create: (data, params = {}) =>
      this.request({
        path: `/action-run`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags action-run
     * @name List
     * @summary List action runs
     * @request GET:/action-run
     * @secure
     * @response `200` `(ActionRun)[]` Array of action runs
     */
    list: (query, params = {}) =>
      this.request({
        path: `/action-run`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
    /**
     * @description Updates the specified action run with the supplied parameters.
     *
     * @tags action-run
     * @name Update
     * @summary Update an action run
     * @request POST:/action-run/{id}
     * @secure
     * @response `200` `(ActionRun)[]` Returns the updated action run object if the update succeeded. Throws an error if the parameters were invalid, such as an invalid url.
     */
    update: (id, data, params = {}) =>
      this.request({
        path: `/action-run/${id}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  apiKey = {
    /**
     * No description
     *
     * @tags api-key
     * @name Create
     * @summary Create an API Key
     * @request POST:/api-key
     * @secure
     * @response `200` `(ApiKey)[]` Api Key
     */
    create: (data, params = {}) =>
      this.request({
        path: `/api-key`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags api-key
     * @name List
     * @summary List API Keys
     * @request GET:/api-key
     * @secure
     * @response `200` `(ApiKey)[]` Array of API Keys
     */
    list: (query, params = {}) =>
      this.request({
        path: `/api-key`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags api-key
     * @name Delete
     * @summary Delete an API Key
     * @request DELETE:/api-key/{id}
     * @secure
     * @response `200` `(any)[]` Empty
     */
    delete: (id, params = {}) =>
      this.request({
        path: `/api-key/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  appListing = {
    /**
     * No description
     *
     * @tags app-listing
     * @name Create
     * @summary Create an App Listing
     * @request POST:/app-listing
     * @secure
     * @response `200` `(AppListing)[]` App Listing
     */
    create: (data, params = {}) =>
      this.request({
        path: `/app-listing`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags app-listing
     * @name List
     * @summary List App Listings
     * @request GET:/app-listing
     * @secure
     * @response `200` `(AppListing)[]` Array of App Listings
     */
    list: (query, params = {}) =>
      this.request({
        path: `/app-listing`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags app-listing
     * @name Update
     * @summary Update an app listing
     * @request POST:/app-listing/{id}
     * @secure
     * @response `200` `(AppListing)[]` Returns the updated app listing object if the update succeeded. Throws an error if the parameters were invalid, such as an invalid url.
     */
    update: (id, data, params = {}) =>
      this.request({
        path: `/app-listing/${id}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags app-listing
     * @name Delete
     * @summary Delete an App Listing
     * @request DELETE:/app-listing/{id}
     * @secure
     * @response `200` `(any)[]` Empty
     */
    delete: (id, params = {}) =>
      this.request({
        path: `/app-listing/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  auth = {
    /**
     * No description
     *
     * @tags auth
     * @name Signup
     * @summary Signup for a new account
     * @request POST:/auth/signup
     * @secure
     * @response `200` `(Session)[]` Returns the created session if successful
     */
    signup: (data, params = {}) =>
      this.request({
        path: `/auth/signup`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags auth
     * @name Signin
     * @summary Signin an existing user
     * @request POST:/auth/signin
     * @secure
     * @response `200` `(Session)[]` Returns the created session if successful
     */
    signin: (data, params = {}) =>
      this.request({
        path: `/auth/signin`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags auth
     * @name Join
     * @summary Join an existing workspace
     * @request POST:/auth/join
     * @secure
     * @response `200` `(any)[]` Empty
     */
    join: (data, params = {}) =>
      this.request({
        path: `/auth/join`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  billingProduct = {
    /**
     * No description
     *
     * @tags billing-product
     * @name List
     * @summary List available billing products
     * @request GET:/billing-product
     * @secure
     * @response `200` `(BillingProduct)[]` A list of billing products that can be purchased
     */
    list: (query, params = {}) =>
      this.request({
        path: `/billing-product`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };
  billingPortal = {
    /**
     * No description
     *
     * @tags billing-portal
     * @name Create
     * @summary Create a billing portal
     * @request POST:/billing-portal
     * @secure
     * @response `200` `(BillingPortal)[]` Billing Portal
     */
    create: (params = {}) =>
      this.request({
        path: `/billing-portal`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  billingSubscription = {
    /**
     * No description
     *
     * @tags billing-subscription
     * @name List
     * @summary List user subscriptions
     * @request GET:/billing-subscription
     * @secure
     * @response `200` `(BillingSubscription)[]` Subscription
     */
    list: (params = {}) =>
      this.request({
        path: `/billing-subscription`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags billing-subscription
     * @name Update
     * @summary Update your subscription
     * @request POST:/billing-subscription/{id}
     * @secure
     * @response `200` `(BillingSubscription)[]` Product
     */
    update: (id, data, params = {}) =>
      this.request({
        path: `/billing-subscription/${id}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags billing-subscription
     * @name Renew
     * @summary Renew your subscription
     * @request POST:/billing-subscription/renew
     * @secure
     * @response `200` `(any)[]` Empty
     */
    renew: (params = {}) =>
      this.request({
        path: `/billing-subscription/renew`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags billing-subscription
     * @name Cancel
     * @summary Cancel your subscription
     * @request POST:/billing-subscription/{id}/cancel
     * @secure
     * @response `200` `(BillingSubscription)[]` Product
     */
    cancel: (id, params = {}) =>
      this.request({
        path: `/billing-subscription/${id}/cancel`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  pipeline = {
    /**
     * No description
     *
     * @tags pipeline
     * @name Create
     * @summary Create a pipeline
     * @request POST:/pipeline
     * @secure
     * @response `200` `(Pipeline)[]` Pipeline
     */
    create: (data, params = {}) =>
      this.request({
        path: `/pipeline`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags pipeline
     * @name List
     * @summary List pipelines
     * @request GET:/pipeline
     * @secure
     * @response `200` `(Pipeline)[]` Array of pipelines
     */
    list: (query, params = {}) =>
      this.request({
        path: `/pipeline`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags pipeline
     * @name Update
     * @summary Update a pipeline
     * @request POST:/pipeline/{id}
     * @secure
     * @response `200` `(Pipeline)[]` Pipeline
     */
    update: (id, data, params = {}) =>
      this.request({
        path: `/pipeline/${id}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags pipeline
     * @name Delete
     * @summary Delete a pipeline
     * @request DELETE:/pipeline/{id}
     * @secure
     * @response `200` `(any)[]` Empty
     */
    delete: (id, params = {}) =>
      this.request({
        path: `/pipeline/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  pipelineRun = {
    /**
     * No description
     *
     * @tags pipeline
     * @name Create
     * @summary Create a pipeline run
     * @request POST:/pipeline-run
     * @secure
     * @response `200` `(PipelineRun)[]` Pipeline run
     */
    create: (data, params = {}) =>
      this.request({
        path: `/pipeline-run`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags pipeline-run
     * @name List
     * @summary List pipeline runs
     * @request GET:/pipeline-run
     * @secure
     * @response `200` `(PipelineRun)[]` Array of pipeline runs
     */
    list: (query, params = {}) =>
      this.request({
        path: `/pipeline-run`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags pipeline-run
     * @name Update
     * @summary Update a pipeline run
     * @request POST:/pipeline-run/{id}
     * @secure
     * @response `200` `(PipelineRun)[]` Pipeline
     */
    update: (id, data, params = {}) =>
      this.request({
        path: `/pipeline-run/${id}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags pipeline-run
     * @name Delete
     * @summary Delete a pipeline run
     * @request DELETE:/pipeline-run/{id}
     * @secure
     * @response `200` `(any)[]` Empty
     */
    delete: (id, params = {}) =>
      this.request({
        path: `/pipeline-run/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  component = {
    /**
     * No description
     *
     * @tags component
     * @name Create
     * @summary Create a component
     * @request POST:/component
     * @secure
     * @response `200` `(Component)[]` Component
     */
    create: (data, params = {}) =>
      this.request({
        path: `/component`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags component
     * @name List
     * @summary List components
     * @request GET:/component
     * @secure
     * @response `200` `(Component)[]` Array of components
     */
    list: (query, params = {}) =>
      this.request({
        path: `/component`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags component
     * @name Update
     * @summary Update a component
     * @request POST:/component/{id}
     * @secure
     * @response `200` `(Component)[]` Component
     */
    update: (id, data, params = {}) =>
      this.request({
        path: `/component/${id}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags component
     * @name Delete
     * @summary Delete a component
     * @request DELETE:/component/{id}
     * @secure
     * @response `200` `(any)[]` Empty
     */
    delete: (id, params = {}) =>
      this.request({
        path: `/component/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  componentDependency = {
    /**
     * @description Create a component dependency relationship, which creates a dependency between two components.
     *
     * @tags component-dependency
     * @name Create
     * @summary Create a component dependency
     * @request POST:/component-dependency
     * @secure
     * @response `200` `(ComponentDependency)[]` Component Dependency
     */
    create: (data, params = {}) =>
      this.request({
        path: `/component-dependency`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags component-dependency
     * @name List
     * @summary List component dependencies
     * @request GET:/component-dependency
     * @secure
     * @response `200` `(ComponentDependency)[]` Array of component dependencies
     */
    list: (query, params = {}) =>
      this.request({
        path: `/component-dependency`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags component-dependency
     * @name Delete
     * @summary Delete a component dependency
     * @request DELETE:/component-dependency/{id}
     * @secure
     * @response `200` `(any)[]` Empty
     */
    delete: (id, params = {}) =>
      this.request({
        path: `/component-dependency/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  componentOwner = {
    /**
     * @description Create a component owner relationship, which assigns a user or team as the owner of a component.
     *
     * @tags component-owner
     * @name Create
     * @summary Create a component owner
     * @request POST:/component-owner
     * @secure
     * @response `200` `(ComponentOwner)[]` Returns the created component owner object if successful.
     */
    create: (data, params = {}) =>
      this.request({
        path: `/component-owner`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags component-owner
     * @name List
     * @summary List component owners
     * @request GET:/component-owner
     * @secure
     * @response `200` `(ComponentOwner)[]` Returns a collection of matching component owners
     */
    list: (query, params = {}) =>
      this.request({
        path: `/component-owner`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags component-owner
     * @name Delete
     * @summary Delete a component owner
     * @request DELETE:/component-owner/{id}
     * @secure
     * @response `200` `(any)[]` Empty
     */
    delete: (id, params = {}) =>
      this.request({
        path: `/component-owner/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  componentPipeline = {
    /**
     * @description Create a component pipeline relationship
     *
     * @tags component-pipeline
     * @name Create
     * @summary Create a component pipeline
     * @request POST:/component-pipeline
     * @secure
     * @response `200` `(ComponentPipeline)[]` Returns the created component pipeline object if successful.
     */
    create: (data, params = {}) =>
      this.request({
        path: `/component-pipeline`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags component-owner
     * @name List
     * @summary List component pipelines
     * @request GET:/component-pipeline
     * @secure
     * @response `200` `(ComponentPipeline)[]` Returns a collection of matching component pipelines
     */
    list: (query, params = {}) =>
      this.request({
        path: `/component-pipeline`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags component-pipeline
     * @name Delete
     * @summary Delete a component pipeline
     * @request DELETE:/component-pipeline/{id}
     * @secure
     * @response `200` `(any)[]` Empty
     */
    delete: (id, params = {}) =>
      this.request({
        path: `/component-pipeline/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  entitlement = {
    /**
     * No description
     *
     * @tags entitlement
     * @name List
     * @summary List entitlements
     * @request GET:/entitlement
     * @secure
     * @response `200` `(Entitlement)[]` Array of entitlements
     */
    list: (query, params = {}) =>
      this.request({
        path: `/entitlement`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };
  file = {
    /**
     * No description
     *
     * @tags file
     * @name Create
     * @summary Create a file
     * @request POST:/file
     * @secure
     * @response `200` `(File)[]` File
     */
    create: (data, params = {}) =>
      this.request({
        path: `/file`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  filterValue = {
    /**
     * @description This endpoint finds all the potential values of an object's property
     *
     * @tags filter-values
     * @name List
     * @summary List filter values
     * @request GET:/filter-value
     * @secure
     * @response `200` `(FilterValue)[]` Array of possible filter values
     */
    list: (query, params = {}) =>
      this.request({
        path: `/filter-value`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };
  group = {
    /**
     * No description
     *
     * @tags group
     * @name Create
     * @summary Create a group
     * @request POST:/group
     * @secure
     * @response `200` `(any)[]` Group
     */
    create: (data, params = {}) =>
      this.request({
        path: `/group`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
    /**
       * No description
       *
       * @tags group
       * @name List
       * @summary List groups
       * @request GET:/group
       * @secure
       * @response `200` `({
          id: string,
          name: string,
          workspace_id: string,
          created_at: string,
          updated_at: string,
          deleted_at: string | null,
      
      })[]` Array of groups
       */
    list: (query, params = {}) =>
      this.request({
        path: `/group`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags group
     * @name Update
     * @summary Update a group
     * @request POST:/group/{id}
     * @secure
     * @response `200` `(any)[]` Group
     */
    update: (id, data, params = {}) =>
      this.request({
        path: `/group/${id}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags group
     * @name Delete
     * @summary Delete a group
     * @request DELETE:/group/{id}
     * @secure
     * @response `200` `(any)[]` Empty
     */
    delete: (id, params = {}) =>
      this.request({
        path: `/group/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  groupAssignment = {
    /**
     * No description
     *
     * @tags group-assignment
     * @name Create
     * @summary Create a group assignment
     * @request POST:/group-assignment
     * @secure
     * @response `200` `(any)[]` Group Assignment
     */
    create: (data, params = {}) =>
      this.request({
        path: `/group-assignment`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
    /**
       * No description
       *
       * @tags group-assignment
       * @name List
       * @summary List group assignments
       * @request GET:/group-assignment
       * @secure
       * @response `200` `({
          id: string,
          match_type: "component_id" | "component_type" | "all",
          match_value: string | null,
          scorecard_id: string,
          workspace_id: string,
          run_at: string | null,
          created_at: string,
          updated_at: string,
          deleted_at: string | null,
          component?: Component,
          scorecard?: Scorecard,
          workspace?: Workspace,
      
      })[]` Array of group Assignments
       */
    list: (query, params = {}) =>
      this.request({
        path: `/group-assignment`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags group-assignment
     * @name Delete
     * @summary Delete a group assignment
     * @request DELETE:/group-assignment/{id}
     * @secure
     * @response `200` `(any)[]` Empty
     */
    delete: (id, params = {}) =>
      this.request({
        path: `/group-assignment/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  gqlQuery = {
    /**
     * No description
     *
     * @tags gql-query
     * @name Create
     * @summary Create a GQL query
     * @request POST:/gql-query
     * @secure
     * @response `200` `(GqlQuery)[]` Query result
     */
    create: (data, params = {}) =>
      this.request({
        path: `/gql-query`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  insight = {
    /**
     * No description
     *
     * @tags insight
     * @name List
     * @summary List insights
     * @request GET:/insight
     * @secure
     * @response `200` `(Insight)[]` Array of insights
     */
    list: (query, params = {}) =>
      this.request({
        path: `/insight`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };
  insightBenchmark = {
    /**
     * No description
     *
     * @tags insight
     * @name List
     * @summary List insight benchmarks
     * @request GET:/insight-benchmark
     * @secure
     * @response `200` `(InsightBenchmark)[]` Array of insight benchmarks
     */
    list: (query, params = {}) =>
      this.request({
        path: `/insight-benchmark`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };
  integration = {
    /**
     * No description
     *
     * @tags integration
     * @name Create
     * @summary Create an integration
     * @request POST:/integration
     * @secure
     * @response `200` `(Integration)[]` Integration
     */
    create: (data, params = {}) =>
      this.request({
        path: `/integration`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags integration
     * @name List
     * @summary List integrations
     * @request GET:/integration
     * @secure
     * @response `200` `(Integration)[]` Array of integrations
     */
    list: (query, params = {}) =>
      this.request({
        path: `/integration`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags integration
     * @name Update
     * @summary Update an integration
     * @request POST:/integration/{id}
     * @secure
     * @response `200` `(Integration)[]` Integration
     */
    update: (id, data, params = {}) =>
      this.request({
        path: `/integration/${id}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags integration
     * @name Delete
     * @summary Delete an integration
     * @request DELETE:/integration/{id}
     * @secure
     * @response `200` `(any)[]` Empty
     */
    delete: (id, params = {}) =>
      this.request({
        path: `/integration/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags action
     * @name Sync
     * @summary Sync an integration
     * @request POST:/integration/{id}/sync
     * @secure
     * @response `200` `(any)[]` Action
     */
    sync: (id, params = {}) =>
      this.request({
        path: `/integration/${id}/sync`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  integrationApp = {
    /**
     * No description
     *
     * @tags integration-app
     * @name Create
     * @summary Create an integration app
     * @request POST:/integration-app
     * @secure
     * @response `200` `(IntegrationApp)[]` Integration app
     */
    create: (data, params = {}) =>
      this.request({
        path: `/integration-app`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags integration-apps
     * @name List
     * @summary List integration apps
     * @request GET:/integration-app
     * @secure
     * @response `200` `(IntegrationApp)[]` Array of Integration apps
     */
    list: (query, params = {}) =>
      this.request({
        path: `/integration-app`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags integration-app
     * @name Update
     * @summary Update an integration app
     * @request POST:/integration-app/{id}
     * @secure
     * @response `200` `(IntegrationApp)[]` Integration app
     */
    update: (id, data, params = {}) =>
      this.request({
        path: `/integration-app/${id}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags integration-app
     * @name Delete
     * @summary Delete an integration app
     * @request DELETE:/integration-app/{id}
     * @secure
     * @response `200` `(any)[]` Empty
     */
    delete: (id, params = {}) =>
      this.request({
        path: `/integration-app/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  invite = {
    /**
     * No description
     *
     * @tags invite
     * @name Create
     * @summary Invite a user
     * @request POST:/invite
     * @secure
     * @response `200` `(Invite)[]` Invite
     */
    create: (data, params = {}) =>
      this.request({
        path: `/invite`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags user
     * @name List
     * @summary List invites
     * @request GET:/invite
     * @secure
     * @response `200` `(Invite)[]` Array of invites
     */
    list: (query, params = {}) =>
      this.request({
        path: `/invite`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags invite
     * @name Delete
     * @summary Delete an invite
     * @request DELETE:/invite/{id}
     * @secure
     * @response `200` `(any)[]` Empty
     */
    delete: (id, params = {}) =>
      this.request({
        path: `/invite/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  issue = {
    /**
     * No description
     *
     * @tags issue
     * @name List
     * @summary List issues
     * @request GET:/issue
     * @secure
     * @response `200` `(Issue)[]` Array of issues
     */
    list: (query, params = {}) =>
      this.request({
        path: `/issue`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags issue
     * @name Delete
     * @summary Delete an issue
     * @request DELETE:/issue/{id}
     * @secure
     * @response `200` `(any)[]` Empty
     */
    delete: (id, params = {}) =>
      this.request({
        path: `/issue/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  link = {
    /**
     * No description
     *
     * @tags link
     * @name Create
     * @summary Create a link
     * @request POST:/link
     * @secure
     * @response `200` `(Link)[]` Link
     */
    create: (data, params = {}) =>
      this.request({
        path: `/link`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags link
     * @name List
     * @summary List links
     * @request GET:/link
     * @secure
     * @response `200` `(Link)[]` Array of links
     */
    list: (query, params = {}) =>
      this.request({
        path: `/link`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags link
     * @name Update
     * @summary Update a link
     * @request POST:/link/{id}
     * @secure
     * @response `200` `(Link)[]` Insight
     */
    update: (id, data, params = {}) =>
      this.request({
        path: `/link/${id}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags link
     * @name Delete
     * @summary Delete a link
     * @request DELETE:/link/{id}
     * @secure
     * @response `200` `(any)[]` Empty
     */
    delete: (id, params = {}) =>
      this.request({
        path: `/link/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  passwordReset = {
    /**
     * No description
     *
     * @tags password-reset
     * @name Create
     * @summary Request a password reset token
     * @request POST:/password-reset
     * @secure
     * @response `200` `(any)[]` Password Reset
     */
    create: (data, params = {}) =>
      this.request({
        path: `/password-reset`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags password-reset
     * @name Consume
     * @summary Consume a password reset token
     * @request POST:/password-reset/consume
     * @secure
     * @response `200` `(any)[]` Password Reset
     */
    consume: (data, params = {}) =>
      this.request({
        path: `/password-reset/consume`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  property = {
    /**
     * No description
     *
     * @tags property
     * @name Create
     * @summary Create a property
     * @request POST:/property
     * @secure
     * @response `200` `(Property)[]` Property
     */
    create: (data, params = {}) =>
      this.request({
        path: `/property`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags property
     * @name List
     * @summary List properties
     * @request GET:/property
     * @secure
     * @response `200` `(Property)[]` Array of Properties
     */
    list: (query, params = {}) =>
      this.request({
        path: `/property`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags property
     * @name Update
     * @summary Update a property
     * @request POST:/property/{id}
     * @secure
     * @response `200` `(Property)[]` Property
     */
    update: (id, data, params = {}) =>
      this.request({
        path: `/property/${id}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags property
     * @name Delete
     * @summary Delete a property
     * @request DELETE:/property/{id}
     * @secure
     * @response `200` `(any)[]` Empty
     */
    delete: (id, params = {}) =>
      this.request({
        path: `/property/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  propertyValue = {
    /**
     * No description
     *
     * @tags property-value
     * @name Create
     * @summary Create a property value
     * @request POST:/property-value
     * @secure
     * @response `200` `(PropertyValue)[]` Returns the created property value if the request succeeded.
     */
    create: (data, params = {}) =>
      this.request({
        path: `/property-value`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags property-value
     * @name List
     * @summary List property values
     * @request GET:/property-value
     * @secure
     * @response `200` `(PropertyValue)[]` Array of Property Values
     */
    list: (query, params = {}) =>
      this.request({
        path: `/property-value`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags property-value
     * @name Update
     * @summary Update a property value
     * @request POST:/property-value/{id}
     * @secure
     * @response `200` `(PropertyValue)[]` Property Value
     */
    update: (id, data, params = {}) =>
      this.request({
        path: `/property-value/${id}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags property-value
     * @name Delete
     * @summary Delete a property value
     * @request DELETE:/property-value/{id}
     * @secure
     * @response `200` `(any)[]` Empty
     */
    delete: (id, params = {}) =>
      this.request({
        path: `/property-value/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  pullRequest = {
    /**
     * No description
     *
     * @tags pull-request
     * @name List
     * @summary List pull requests
     * @request GET:/pull-request
     * @secure
     * @response `200` `(PullRequest)[]` Array of pull requests
     */
    list: (query, params = {}) =>
      this.request({
        path: `/pull-request`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags pull-request
     * @name Delete
     * @summary Delete a pull request
     * @request DELETE:/pull-request/{id}
     * @secure
     * @response `200` `(any)[]` Empty
     */
    delete: (id, params = {}) =>
      this.request({
        path: `/pull-request/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  repository = {
    /**
     * No description
     *
     * @tags repository
     * @name List
     * @summary List repositories
     * @request GET:/repository
     * @secure
     * @response `200` `(Repository)[]` Array of repositories
     */
    list: (query, params = {}) =>
      this.request({
        path: `/repository`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags repository
     * @name Delete
     * @summary Delete a repository
     * @request DELETE:/repository/{id}
     * @secure
     * @response `200` `(any)[]` Empty
     */
    delete: (id, params = {}) =>
      this.request({
        path: `/repository/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  role = {
    /**
     * No description
     *
     * @tags role
     * @name Create
     * @summary Create a role
     * @request POST:/role
     * @secure
     * @response `200` `(Role)[]` Role
     */
    create: (data, params = {}) =>
      this.request({
        path: `/role`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags role
     * @name List
     * @summary List roles
     * @request GET:/role
     * @secure
     * @response `200` `(Role)[]` Array of Roles
     */
    list: (query, params = {}) =>
      this.request({
        path: `/role`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags role
     * @name Update
     * @summary Update a role
     * @request POST:/role/{id}
     * @secure
     * @response `200` `(Role)[]` Role
     */
    update: (id, data, params = {}) =>
      this.request({
        path: `/role/${id}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags Role
     * @name Delete
     * @summary Delete a role
     * @request DELETE:/role/{id}
     * @secure
     * @response `200` `(any)[]` Empty
     */
    delete: (id, params = {}) =>
      this.request({
        path: `/role/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  scaffold = {
    /**
     * No description
     *
     * @tags scaffold
     * @name Create
     * @summary Create a scaffold
     * @request POST:/scaffold
     * @secure
     * @response `200` `(Scaffold)[]` Scaffold
     */
    create: (data, params = {}) =>
      this.request({
        path: `/scaffold`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags scaffold
     * @name List
     * @summary List scaffolds
     * @request GET:/scaffold
     * @secure
     * @response `200` `(Scaffold)[]` Returns a collection of scaffolds.
     */
    list: (query, params = {}) =>
      this.request({
        path: `/scaffold`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags scaffold
     * @name Update
     * @summary Update a scaffold
     * @request POST:/scaffold/{id}
     * @secure
     * @response `200` `(Scaffold)[]` Scaffold
     */
    update: (id, data, params = {}) =>
      this.request({
        path: `/scaffold/${id}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags scaffold
     * @name Delete
     * @summary Delete a scaffold
     * @request DELETE:/scaffold/{id}
     * @secure
     * @response `200` `(any)[]` Empty
     */
    delete: (id, params = {}) =>
      this.request({
        path: `/scaffold/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  actionField = {
    /**
     * @description Create an action field for an existing action. Creating action fields allows you to collect information before the action is run.
     *
     * @tags action-field
     * @name Create
     * @summary Create an action field
     * @request POST:/action-field
     * @secure
     * @response `200` `(ActionField)[]` Action Field
     */
    create: (data, params = {}) =>
      this.request({
        path: `/action-field`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags action-field
     * @name List
     * @summary List action fields
     * @request GET:/action-field
     * @secure
     * @response `200` `(ActionField)[]` Array of Action Fields
     */
    list: (query, params = {}) =>
      this.request({
        path: `/action-field`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags action-field
     * @name Update
     * @summary Update an action field
     * @request POST:/action-field/{id}
     * @secure
     * @response `200` `(ActionField)[]` Action Field
     */
    update: (id, data, params = {}) =>
      this.request({
        path: `/action-field/${id}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags action-field
     * @name Delete
     * @summary Delete an action field
     * @request DELETE:/action-field/{id}
     * @secure
     * @response `200` `(any)[]` Empty
     */
    delete: (id, params = {}) =>
      this.request({
        path: `/action-field/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  scorecard = {
    /**
     * No description
     *
     * @tags scorecard
     * @name Create
     * @summary Create a scorecard
     * @request POST:/scorecard
     * @secure
     * @response `200` `(Scorecard)[]` Scorecard
     */
    create: (data, params = {}) =>
      this.request({
        path: `/scorecard`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags scorecard
     * @name List
     * @summary List scorecards
     * @request GET:/scorecard
     * @secure
     * @response `200` `(Scorecard)[]` Array of Scorecards
     */
    list: (query, params = {}) =>
      this.request({
        path: `/scorecard`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags scorecard
     * @name Update
     * @summary Update a scorecard
     * @request POST:/scorecard/{id}
     * @secure
     * @response `200` `(Scorecard)[]` Scorecard
     */
    update: (id, data, params = {}) =>
      this.request({
        path: `/scorecard/${id}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags scorecard
     * @name Delete
     * @summary Delete a scorecard
     * @request DELETE:/scorecard/{id}
     * @secure
     * @response `200` `(any)[]` Empty
     */
    delete: (id, params = {}) =>
      this.request({
        path: `/scorecard/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  scorecardRun = {
    /**
     * No description
     *
     * @tags scorecard-run
     * @name List
     * @summary List scorecard runs
     * @request GET:/scorecard-run
     * @secure
     * @response `200` `(ScorecardRun)[]` Array of Scorecard Runs
     */
    list: (query, params = {}) =>
      this.request({
        path: `/scorecard-run`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };
  scorecardRule = {
    /**
     * No description
     *
     * @tags scorecard-rule
     * @name Create
     * @summary Create a scorecard rule
     * @request POST:/scorecard-rule
     * @secure
     * @response `200` `(ScorecardRule)[]` Scorecard rule
     */
    create: (data, params = {}) =>
      this.request({
        path: `/scorecard-rule`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags scorecard-rule
     * @name List
     * @summary List scorecard rules
     * @request GET:/scorecard-rule
     * @secure
     * @response `200` `(ScorecardRule)[]` Array of Scorecard rules
     */
    list: (query, params = {}) =>
      this.request({
        path: `/scorecard-rule`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags scorecard-rule
     * @name Update
     * @summary Update a scorecard rule
     * @request POST:/scorecard-rule/{id}
     * @secure
     * @response `200` `(ScorecardRule)[]` Scorecard rule
     */
    update: (id, data, params = {}) =>
      this.request({
        path: `/scorecard-rule/${id}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags scorecard-rule
     * @name Delete
     * @summary Delete a scorecard rule
     * @request DELETE:/scorecard-rule/{id}
     * @secure
     * @response `200` `(any)[]` Empty
     */
    delete: (id, params = {}) =>
      this.request({
        path: `/scorecard-rule/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  scorecardAssignment = {
    /**
     * No description
     *
     * @tags scorecard-assignment
     * @name Create
     * @summary Create a scorecard assignment
     * @request POST:/scorecard-assignment
     * @secure
     * @response `200` `(ScorecardAssignment)[]` Scorecard Assignment
     */
    create: (data, params = {}) =>
      this.request({
        path: `/scorecard-assignment`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags scorecard-assignment
     * @name List
     * @summary List scorecard assignments
     * @request GET:/scorecard-assignment
     * @secure
     * @response `200` `(ScorecardAssignment)[]` Array of Scorecard Assignments
     */
    list: (query, params = {}) =>
      this.request({
        path: `/scorecard-assignment`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags scorecard-assignment
     * @name Delete
     * @summary Delete a scorecard assignment
     * @request DELETE:/scorecard-assignment/{id}
     * @secure
     * @response `200` `(any)[]` Empty
     */
    delete: (id, params = {}) =>
      this.request({
        path: `/scorecard-assignment/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  scorecardResult = {
    /**
     * No description
     *
     * @tags scorecard-assignment
     * @name List
     * @summary List scorecard results
     * @request GET:/scorecard-result
     * @secure
     * @response `200` `(ScorecardResult)[]` Array of Scorecard Results
     */
    list: (query, params = {}) =>
      this.request({
        path: `/scorecard-result`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };
  session = {
    /**
     * No description
     *
     * @tags session
     * @name List
     * @summary List of sessions for the authenticated user
     * @request GET:/session
     * @secure
     * @response `200` `(Session)[]` Array of sessions
     */
    list: (query, params = {}) =>
      this.request({
        path: `/session`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags session
     * @name Delete
     * @summary Delete a session
     * @request DELETE:/session/{id}
     * @secure
     * @response `200` `(any)[]` Empty
     */
    delete: (id, params = {}) =>
      this.request({
        path: `/session/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  team = {
    /**
     * No description
     *
     * @tags team
     * @name Create
     * @summary Create a team
     * @request POST:/team
     * @secure
     * @response `200` `(Team)[]` Team
     */
    create: (data, params = {}) =>
      this.request({
        path: `/team`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags scaffold
     * @name List
     * @summary List teams
     * @request GET:/team
     * @secure
     * @response `200` `(Team)[]` Array of teams
     */
    list: (query, params = {}) =>
      this.request({
        path: `/team`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags team
     * @name Update
     * @summary Update a team
     * @request POST:/team/{id}
     * @secure
     * @response `200` `(Team)[]` Team
     */
    update: (id, data, params = {}) =>
      this.request({
        path: `/team/${id}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags team
     * @name Delete
     * @summary Delete a team
     * @request DELETE:/team/{id}
     * @secure
     * @response `200` `(any)[]` Empty
     */
    delete: (id, params = {}) =>
      this.request({
        path: `/team/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  teamMember = {
    /**
     * No description
     *
     * @tags team-member
     * @name Create
     * @summary Create a team member
     * @request POST:/team-member
     * @secure
     * @response `200` `(TeamMember)[]` Team Member
     */
    create: (data, params = {}) =>
      this.request({
        path: `/team-member`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags team-member
     * @name List
     * @summary List team members
     * @request GET:/team-member
     * @secure
     * @response `200` `(TeamMember)[]` Array of team members
     */
    list: (query, params = {}) =>
      this.request({
        path: `/team-member`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags team-member
     * @name Delete
     * @summary Delete a team member
     * @request DELETE:/team-member/{id}
     * @secure
     * @response `200` `(any)[]` Empty
     */
    delete: (id, params = {}) =>
      this.request({
        path: `/team-member/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  user = {
    /**
     * No description
     *
     * @tags user
     * @name List
     * @summary List users
     * @request GET:/user
     * @secure
     * @response `200` `(User)[]` Returns a collection of users.
     */
    list: (query, params = {}) =>
      this.request({
        path: `/user`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags user
     * @name Update
     * @summary Update a user
     * @request POST:/user/{id}
     * @secure
     * @response `200` `(User)[]` User
     */
    update: (id, data, params = {}) =>
      this.request({
        path: `/user/${id}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags user
     * @name Delete
     * @summary Delete a user
     * @request DELETE:/user/{id}
     * @secure
     * @response `200` `(any)[]` Empty
     */
    delete: (id, params = {}) =>
      this.request({
        path: `/user/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  workspace = {
    /**
     * No description
     *
     * @tags workspace
     * @name List
     * @summary List workspaces
     * @request GET:/workspace
     * @secure
     * @response `200` `(Workspace)[]` Array of workspaces
     */
    list: (query, params = {}) =>
      this.request({
        path: `/workspace`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags workspace
     * @name Update
     * @summary Update a workspace
     * @request POST:/workspace/{id}
     * @secure
     * @response `200` `(Workspace)[]` Workspace
     */
    update: (id, data, params = {}) =>
      this.request({
        path: `/workspace/${id}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
    /**
     * No description
     *
     * @tags workspace
     * @name Delete
     * @summary Delete a workspace
     * @request DELETE:/workspace/{id}
     * @secure
     * @response `200` `(any)[]` Empty
     */
    delete: (id, params = {}) =>
      this.request({
        path: `/workspace/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),
  };
}
