import {User}                       from '@glimpse/glimpse';
import {Table, useModal, Box, Text} from '@glimpse/glass';
import {useState}                   from 'react';
import {glimpse}                    from 'providers/glimpse/glimpse-provider';
import {useTranslation}             from 'foundations/i18n/use-translation';
import {useQuery}                   from 'foundations/net/use-query';
import {UserSlideout}               from 'features/user/user-slideout';
import {RequirePermission}          from 'features/permission/require-permission';

export function UsersTable({
    query = {}
}: {
    query?: User.List.Params
}) {
    const {t}                     = useTranslation();
    const [selected, setSelected] = useState<User['id']>();
    const userModal               = useModal();
    const api                     = useQuery<User>({
        key: ['user', query],
        fn:  () => glimpse.user.list(query)
    });

    const viewUser = (id: User['id']) => {
        setSelected(id);
        userModal.toggle();
    };

    return (
        <RequirePermission permission={'user.read'}>
            {
                selected &&
                <UserSlideout open={userModal.open} onClose={userModal.toggle} userId={selected}/>
            }
            <Table>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>{t('Name')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('Email')}</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {
                        api.data?.length === 0 &&
                        <Box p={2}>
                            <Text color='text.subtle'>{t('No users')}</Text>
                        </Box>
                    }
                    {
                        api.data?.map(user =>
                            <Table.Row effects={['hoverable', 'clickable']} onClick={() => viewUser(user.id)}>
                                <Table.BodyCell>
                                    <Text fontWeight={'bold'}>{user.name}</Text>
                                    <Text color={'text.subtle'}>{user.title ?? '-'}</Text>
                                </Table.BodyCell>
                                <Table.BodyCell>{user.email}</Table.BodyCell>
                            </Table.Row>
                        )
                    }
                </Table.Body>
            </Table>
        </RequirePermission>
    );
}