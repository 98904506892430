import React, {useEffect, useMemo}        from 'react';
import styles                             from 'foundations/layouts/app-layout/app-layout.module.css';
import {AppGlowbar}                       from 'foundations/layouts/app-layout/app-glowbar';
import {AppLayoutBody}                    from 'foundations/layouts/app-layout/app-layout-body';
import {AppLayoutHeader}                  from 'foundations/layouts/app-layout/app-layout-header';
import {useTranslation}                   from 'foundations/i18n/use-translation';
import {Box, Button, Flex, Text}          from '@glimpse/glass';
import {Link}                             from 'react-router-dom';
import {useQuery}                         from 'foundations/net/use-query';
import {glimpse}                          from 'providers/glimpse/glimpse-provider';
import {BillingSubscription, Integration} from '@glimpse/glimpse';
import {IntegrationHelper}                from 'features/integration/integration-helper';

export function AppLayout({
    children,
    title
}: {
    children: React.ReactNode,
    title?: string
}) {
    const {t}          = useTranslation();
    const subscription = useQuery<BillingSubscription>({
        key: ['billing-subscription'],
        fn:  glimpse.billingSubscription.list
    });

    const integrations = useQuery<Integration>({
        key: ['integration'],
        fn:  glimpse.integration.list
    });

    useEffect(() => {
        document.title = title ?? 'Glimpse';
    }, [title]);

    const hasGit = useMemo(() => {
        if (integrations.data) {
            return integrations.data.find((int) => {
                const meta = IntegrationHelper.get(int.connector);
                return meta.features?.repositories;
            });
        } else {
            return true;
        }
    }, [JSON.stringify(integrations.data)]);

    return (
        <div className={styles.app}>
            <div className={styles.header}>
                <AppGlowbar/>
            </div>
            <main className={styles.content}>
                <Box pl={2} pr={2}>
                    {
                        subscription.data[0]?.status === 'canceled' &&
                        <Box mt={1} p={2} bg={'danger'} radius={1}>
                            <Flex gap={2} justify={'between'} align={'center'}>
                                <Text color={'n0'}>{t('Your account was cancelled, features have been limited. Renew to keep using Glimpse')}</Text>
                                <Link to={'/settings/billing'}>
                                    <Button variant={'default'}>{t('Renew Now')}</Button>
                                </Link>
                            </Flex>
                        </Box>
                    }
                    {
                        false &&
                        !integrations.loading &&
                        !hasGit &&
                        subscription.data[0]?.status !== 'canceled' &&
                        <Box mt={1} p={2} bg={'info'} radius={1}>
                            <div className={styles.alert}>
                                <Flex gap={2} justify={'between'} align={'center'}>
                                    <div>
                                        <Text fontWeight={'bolder'} fontSize={2} color={'n0'}>{t('Onboarding')}</Text>
                                        <Text color={'n0'}>{t('Connect your Github.com to get started')}</Text>
                                    </div>
                                    <Link to={'/settings/integrations'}>
                                        <Button variant={'default'}>{t('Integrate Github.com')}</Button>
                                    </Link>
                                </Flex>
                            </div>
                        </Box>
                    }
                </Box>
                {children}
            </main>
        </div>
    );
}

AppLayout.Header = AppLayoutHeader;
AppLayout.Body   = AppLayoutBody;