import {PipelineRun}    from '@glimpse/glimpse';
import {Badge, Text}    from '@glimpse/glass';
import {useTranslation} from 'foundations/i18n/use-translation';

export function PipelineStatusBadge({
    status
}: {
    status: PipelineRun['status'] | undefined | null
}) {
    const {t} = useTranslation();

    if (typeof status === 'undefined' || status === null) {
        return <Text color={'text.subtle'}>{t('No status')}</Text>;
    }

    switch (status) {
        case 'pending':
            return <Badge color={'neutral.subtle'} text={t('Pending')}/>;
        case 'in_progress':
            return <Badge color={'info.subtle'} text={t('In progress')}/>;
        case 'cancelled':
            return <Badge color={'neutral.subtle'} text={t('Cancelled')}/>;
        case 'success':
            return <Badge color={'success.subtle'} text={t('Success')}/>;
        case 'error':
            return <Badge color={'danger.subtle'} text={t('Error')}/>;
        default:
            return null;

    }
}