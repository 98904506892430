import {Scorecard}                 from '@glimpse/glimpse';
import {Modal, Button, Text, Flex} from '@glimpse/glass';
import {useNavigate}               from 'react-router-dom';
import {glimpse}        from 'providers/glimpse/glimpse-provider';
import {useTranslation} from 'foundations/i18n/use-translation';
import toast                 from 'react-hot-toast';
import {useMutate}           from 'foundations/net/use-mutate';
import {FormFallbackError}   from 'foundations/ui/form/form-fallback-error';

export function DeleteScorecardModal({
    open,
    onClose,
    scorecardId
}: {
    open: boolean,
    onClose: () => void,
    scorecardId: Scorecard['id'],
}) {
    const {t}                      = useTranslation();
    const navigate                 = useNavigate();
    const {submit, loading, error} = useMutate<Scorecard>({
        key:       ['scorecard', scorecardId],
        fn:        async () => await glimpse.scorecard.delete(scorecardId),
        onSuccess: () => {
            toast.success(t('Scorecard deleted'));
            navigate('/scorecards');
        }
    });

    return (
        <Modal onClose={onClose} open={open}>
            <Modal.Header onClose={onClose} title={t('Delete this scorecard?')}/>
            <Modal.Body>
                <FormFallbackError error={error}/>
                <Text>
                    {t('Are you sure you want to delete this Scorecard? This cannot be undone.')}
                </Text>
            </Modal.Body>
            <Modal.Footer>
                <Flex justify={'end'}>
                    <Button onClick={onClose}>{t('Cancel')}</Button>
                    <Button variant='destructive' loading={loading} onClick={submit}>{t('Delete Scorecard')}</Button>
                </Flex>
            </Modal.Footer>
        </Modal>
    );
}