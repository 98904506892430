import {useModal, Button, Icon, Box, Text, Grid} from '@glimpse/glass';
import {AppLayout}                               from 'foundations/layouts/app-layout/app-layout';
import React                                     from 'react';
import {RequirePermission}                       from 'features/permission/require-permission';
import {useTranslation}                          from 'foundations/i18n/use-translation';
import {CreatePropertyModal}                     from 'features/property/create-property-modal';
import {PropertyTable}                           from 'features/property/property-table';

export function SettingsPropertiesScreen() {
    const createField = useModal();
    const {t}         = useTranslation();

    return (
        <AppLayout title={t('Properties')}>
            <RequirePermission permission={'property.write'} fallback>
                <CreatePropertyModal open={createField.open} onClose={createField.toggle}/>
                <AppLayout.Header>
                    <Box pt={3} pb={3}>
                        <Grid justify='between' align='center'>
                            <Text fontSize={4} fontWeight='bolder'>{t('Properties')}</Text>
                            <Button variant='primary' icon={<Icon fa={['far', 'fa-plus']}/>} onClick={createField.toggle}>{t('Add Property')}</Button>
                        </Grid>
                    </Box>
                </AppLayout.Header>
                <AppLayout.Body>
                    <PropertyTable/>
                </AppLayout.Body>
            </RequirePermission>
        </AppLayout>
    );
}