import {CreateInviteModal}                                from 'features/invite/create-invite-modal';
import {useModal, Button, Icon, Grid, Text, Box, useTabs} from '@glimpse/glass';
import {RequirePermission}                                from 'features/permission/require-permission';
import React                                              from 'react';
import {AppLayout}                                        from 'foundations/layouts/app-layout/app-layout';
import {Searchbox}                                        from 'foundations/ui/searchbox/searchbox';
import {useForm}                                          from 'foundations/ui/form/use-form';
import {User}                                             from '@glimpse/glimpse';
import {useTranslation}                                   from 'foundations/i18n/use-translation';
import {Tabs}                                             from '@glimpse/glass';
import {InviteTable}                                      from 'features/invite/invite-table';
import {WorkspaceMembersTable}                            from 'features/workspace-member/workspace-members-table';

export function SettingsWorkspaceMembersScreen() {
    const {t}          = useTranslation();
    const createInvite = useModal();
    const form         = useForm<User.List.Params>();
    const tabs         = useTabs('members');

    return (
        <AppLayout title={t('Workspace Members')}>
            <RequirePermission permission={'user.read'}>
                <CreateInviteModal open={createInvite.open} onClose={createInvite.toggle}/>
                <AppLayout.Header>
                    <Box pt={3} pb={3}>
                        <Grid justify='between' align='center'>
                            <Text fontSize={4} fontWeight='bolder'>{t('Workspace Members')}</Text>
                            <div>
                                <RequirePermission permission={'user.write'}>
                                    <>
                                        <RequirePermission permission={'user.write'}>
                                            <Button variant='primary' icon={<Icon fa={['far', 'fa-envelope']}/>} onClick={createInvite.toggle}>{t('Invite')}</Button>
                                        </RequirePermission>
                                    </>
                                </RequirePermission>
                            </div>
                        </Grid>
                    </Box>
                    <Tabs onChange={tabs.change}>
                        <Tabs.Tab label={t('Members')} current={tabs.current} value='members'/>
                        <Tabs.Tab label={t('Invites')} current={tabs.current} value='invites'/>
                    </Tabs>
                </AppLayout.Header>
                <AppLayout.Body>
                    <Tabs.Panel active={tabs.current === 'members'}>
                        <Box mb={2}>
                            <Searchbox {...form.register('query')}/>
                        </Box>
                        <WorkspaceMembersTable query={form.data}/>
                    </Tabs.Panel>
                    <Tabs.Panel active={tabs.current === 'invites'}>
                        <InviteTable/>
                    </Tabs.Panel>
                </AppLayout.Body>
            </RequirePermission>
        </AppLayout>
    );
}