import {Image, Text, Icon, Box, Flex} from '@glimpse/glass';
import React                          from 'react';
import {Integration}                  from '@glimpse/glimpse';
import {glimpse}                      from 'providers/glimpse/glimpse-provider';
import {useTranslation}               from 'foundations/i18n/use-translation';
import {useQuery}                     from 'foundations/net/use-query';
import {RequirePermission}            from 'features/permission/require-permission';
import {useNavigate}                  from 'react-router-dom';
import {IntegrationHelper}            from 'features/integration/integration-helper';

export function IntegrationPanel({
    logo,
    label,
    connector
}: {
    logo: string,
    label: string,
    connector: Integration['connector'],
}) {
    const {t}             = useTranslation();
    const navigate        = useNavigate();
    const {data, loading} = useQuery<Integration>({
        key: ['integration', connector],
        fn:  () => glimpse.integration.list({connector})
    });

    const meta = IntegrationHelper.get(connector);

    return (
        <RequirePermission permission={'integration.write'}>
            <Box p={2} border={'default'} radius={1} effects={['hoverable', 'clickable']} onClick={() => navigate(`/settings/integrations/${connector}`)}>
                <Flex align='center' justify={'between'}>
                    <Flex gap={1} align={'center'}>
                        <Image width='32px' source={logo}/>
                        <Text fontWeight='bold' fontSize={2}>{label}</Text>
                    </Flex>
                    <Flex gap={1} align={'center'}>
                        {
                            !loading && data?.length > 0 &&
                            <Flex gap={0.5} align={'center'}>
                                <Icon fa={['fas', 'fa-circle']} color={'success'} fontSize={0.25}/>
                                <Text color={'text.subtle'}>{data.length + ' ' + 'Installed'}</Text>
                            </Flex>
                        }
                        {
                            !loading && data?.length === 0 &&
                            <Text color={'text.subtle'}>{t('Not Installed')}</Text>
                        }
                    </Flex>
                </Flex>
                <Box pt={2}>
                    {meta?.features?.components && <Feature text={t('Components')}/>}
                    {meta?.features?.scorecards && <Feature text={t('Scorecards')}/>}
                    {meta?.features?.repositories && <Feature text={t('Repositories')}/>}
                    {meta?.features?.pullRequests && <Feature text={t('Pull Requests')}/>}
                    {meta?.features?.pipelines && <Feature text={t('Pipelines')}/>}
                    {meta?.features?.teams && <Feature text={t('Teams')}/>}
                    {meta?.features?.insights && <Feature text={t('Insights')}/>}
                    {meta?.capabilities?.multiInstall && <Feature text={t('Multi-Install')}/>}
                </Box>
            </Box>
        </RequirePermission>
    );
}

const Feature = ({text}: { text: string }) => {
    return (
        <Box mt={0.5}>
            <Flex gap={1} align={'center'}>
                <Icon fa={['fas', 'fa-check']} color={'success'}/>
                <Text>{text}</Text>
            </Flex>
        </Box>
    );
};