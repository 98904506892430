import {Alert, Box, Button, Code, CodeBlock, Flex, Grid, Icon, Reveal, Separator, Spaced, Tabs, Text, Textbox, useTabs} from '@glimpse/glass';
import React, {useState}                                                                                                from 'react';
import {useForm}                                                                                                        from 'foundations/ui/form/use-form';
import {AppLayout}                                                                                                      from 'foundations/layouts/app-layout/app-layout';
import {useTranslation}                                                                                                 from 'foundations/i18n/use-translation';
import {Insight}                                                                                                        from '@glimpse/glimpse';
import {BillingProductUpsell}                                                                                           from 'features/billing-product/billing-product-upsell';
import {queries}                                                                                                        from '@glimpse/gql';
import {IntegrationHelper}                                                                                              from 'features/integration/integration-helper';
import {InsightStat}                                                                                                    from 'features/insight/insight-stat/insight-stat';
import {InsightTotals}                                                                                                  from 'features/insight/insight-totals/insight-totals';
import {InsightHistory}                                                                                                 from 'features/insight/insight-history/insight-history';
import {InsightIntervalFilter}                                                                                          from 'features/insight/insight-interval-filter';

export function InsightsScreen() {
    const {t}  = useTranslation();
    const tabs = useTabs('overview');
    const form = useForm<Insight.List.Params>();

    const upsell = <BillingProductUpsell
        icon={<Icon fa={['fas', 'fa-bolt']} color={'secondary'} fontSize={4}/>}
        preTitle={t('Insights across your stack')}
        title={t('Query data from across Glimpse and your Integrations')}
        desc={t('Write custom queries to meet advanced use cases, dig deep into data across your integration and compare data points')}/>;

    return (
        <AppLayout title={t('Insights')}>
            <AppLayout.Header>
                <Box pt={3} pb={3}>
                    <Text fontSize={4} fontWeight='bolder'>{t('Insights')}</Text>
                </Box>
                <Tabs onChange={tabs.change}>
                    <Tabs.Tab value={'overview'} current={tabs.current} label={t('Overview')}/>
                    <Tabs.Tab value={'explorer'} current={tabs.current} label={t('GQL Explorer')}/>
                </Tabs>
            </AppLayout.Header>
            <AppLayout.Body>
                {
                    tabs.current === 'overview' && <OverviewTab/>
                }
                {
                    tabs.current === 'explorer' && <ExploreTab/>
                }
            </AppLayout.Body>
        </AppLayout>
    );
}

const OverviewTab = () => {
    const {t}  = useTranslation();
    const form = useForm<Insight.List.Params>({
        interval: '30_days'
    });

    return (
        <Spaced direction={'vertical'} gap={3}>
            <Box>
                <Box mb={2}>
                    <Flex justify={'between'}>
                        <Text fontWeight={'bolder'} fontSize={3}>{t('Pull Requests')}</Text>
                        <InsightIntervalFilter {...form.register('interval')} label={t('Date range')}/>
                    </Flex>
                </Box>
                <Grid xs={3} gap={2} align={'stretch'}>
                    <InsightStat name={'pull_request.avg_time_open'} mode={'duration'} query={form.data}/>
                    <InsightStat name={'pull_request.max_time_open'} mode={'duration'} query={form.data}/>
                    <InsightStat name={'pull_request.cycle_time'} mode={'duration'} query={form.data}/>
                    <InsightTotals name={'pull_request.state_totals'} query={form.data}/>
                    <Grid.Item xs={6}>
                        <InsightHistory name={'pull_request.avg_time_open_history'} query={form.data}/>
                    </Grid.Item>
                </Grid>
            </Box>
            <Box>
                <Box mb={2}>
                    <Text fontWeight={'bolder'} fontSize={3}>{t('Pipelines')}</Text>
                </Box>
                <Grid xs={4} gap={2} align={'stretch'}>
                    <InsightStat name={'pipeline_run.success_rate'} mode={'percent'} query={form.data}/>
                    <InsightStat name={'pipeline_run.avg_duration'} mode={'duration'} query={form.data}/>
                    <InsightTotals name={'pipeline_run.outcome_totals'} query={form.data}/>
                    <Grid.Item xs={6}>
                        <InsightHistory name={'pipeline_run.outcome_history'} query={form.data}/>
                    </Grid.Item>
                    <Grid.Item xs={6}>
                        <InsightHistory name={'pipeline_run.avg_duration_history'} query={form.data}/>
                    </Grid.Item>
                </Grid>
            </Box>
            <Box>
                <Box mb={2}>
                    <Text fontWeight={'bolder'} fontSize={3}>{t('Repositories')}</Text>
                </Box>
                <Grid xs={6} gap={2} align={'stretch'}>
                    <InsightTotals name={'repository.languages'}/>
                    <InsightTotals name={'repository.topics'}/>
                </Grid>
            </Box>
        </Spaced>
    );
};

const ExploreTab = () => {
    const {t}                     = useTranslation();
    const integrations            = IntegrationHelper.listAll().filter((int) => int.capabilities?.glimpseGql);
    const [selected, setSelected] = useState<string>();

    return (
        <Grid gap={4}>
            <Grid.Item xs={3}>
                <Box mb={1} ml={0.5}>
                    <Text fontWeight='bold' fontSize={3}>{t('Data Sources')}</Text>
                </Box>
                <Spaced direction={'vertical'} gap={0.5}>
                    {
                        integrations
                            .sort((a, b) => a.label.localeCompare(b.label))
                            .map((integration) =>
                                <Reveal>
                                    <Box p={2} border={'default'} radius={1} effects={['hoverable', 'clickable']} onClick={() => setSelected(integration.connector)}>
                                        <Flex justify={'between'} align={'center'}>
                                            <Flex gap={1} align={'center'}>
                                                <img width={20} src={integration.icon}/>
                                                <Text fontWeight={'bold'}>{integration.label}</Text>
                                            </Flex>
                                            <Reveal.Hidden>
                                                <Icon fa={['far', 'chevron-right']} color={'n60'}/>
                                            </Reveal.Hidden>
                                        </Flex>
                                    </Box>
                                </Reveal>
                            )
                    }
                </Spaced>
            </Grid.Item>
            <Grid.Item xs={9}>
                <Box mb={1} ml={0.5}>
                    <Text fontWeight='bold' fontSize={3}>{t('Available Queries')}</Text>
                </Box>
                {
                    selected &&
                    queries
                        .filter((query) => query.connector === selected)
                        .map((query) => <Query query={query}/>)
                }
                {
                    !selected &&
                    <Box border={'dashed'} pt={16} pb={16} radius={1}>
                        <Flex justify={'center'}>
                            <Text color={'text.subtle'}>
                                {t('Select a data source to get started')}
                            </Text>
                        </Flex>
                    </Box>
                }
            </Grid.Item>
        </Grid>
    );
};

const Query = ({
    query
}: {
    query: typeof queries[0]
}) => {
    const [open, setOpen] = useState<boolean>(true);
    const {t}             = useTranslation();

    return (
        <Box mb={1} p={2} border={'default'} radius={1}>
            <Flex align={'center'} justify={'between'}>
                <Text fontWeight={'bold'}>
                    {query.title}
                </Text>
            </Flex>
            {
                open &&
                <Box mt={2}>
                    <Separator/>

                    <Box mt={2}>
                        <Code>
                            {`${query.connector}.${query.name}(${query.params?.map((a: any) => a.name + ':' + a.type)?.join(', ')})`}
                        </Code>
                    </Box>
                    {
                        query.desc &&
                        <Box mt={2}>
                            <Text>{query.desc}</Text>
                        </Box>
                    }
                    {
                        query?.example &&
                        <Box mt={2}>
                            <Box mb={1}>
                                <Text fontWeight={'bold'}>{t('Example')}</Text>
                            </Box>
                            <CodeBlock>{query.example}</CodeBlock>
                        </Box>
                    }
                </Box>
            }
        </Box>
    );
};