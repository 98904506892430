import {Modal, Button, Text, Flex} from '@glimpse/glass';
import {glimpse}                   from 'providers/glimpse/glimpse-provider';
import {useTranslation}            from 'foundations/i18n/use-translation';
import toast                       from 'react-hot-toast';
import {useMutate}                 from 'foundations/net/use-mutate';
import {FormFallbackError}         from 'foundations/ui/form/form-fallback-error';

export function RenewBillingSubscriptionModal({
    open,
    onClose
}: {
    open: boolean,
    onClose: () => void,
}) {
    const {t}                      = useTranslation();
    const {submit, loading, error} = useMutate({
        key:       ['billing-subscription'],
        fn:        () => glimpse.billingSubscription.renew(),
        onSuccess: () => {
            toast.success(t('Subscription renewed'));
            onClose();
        }
    });

    return (
        <Modal onClose={onClose} open={open}>
            <Modal.Header onClose={onClose} title={t('Renew your subscription')}/>
            <Modal.Body>
                <FormFallbackError error={error}/>
                <Text>
                    {t('Renew your subscription to regain full access to Glimpse.')}
                </Text>
            </Modal.Body>
            <Modal.Footer>
                <Flex justify={'end'}>
                    <Button variant='primary' loading={loading} onClick={submit}>{t('Renew Now')}</Button>
                </Flex>
            </Modal.Footer>
        </Modal>
    );
}