import {Box, Icon, Grid, Text, Button, useModal, Reveal, Menu} from '@glimpse/glass';
import React, {useState}                                       from 'react';
import {Integration}                                           from '@glimpse/glimpse';
import {Empty}                                                 from 'foundations/ui/empty/empty';
import {CenteredSpinner}                                       from 'foundations/ui/interstitial/centered-spinner';
import {glimpse}                                               from 'providers/glimpse/glimpse-provider';
import {DeleteIntegrationModal}                                from 'features/integration/delete-integration-modal';
import {useTranslation}                                        from 'foundations/i18n/use-translation';
import {useQuery}                                              from 'foundations/net/use-query';
import {UpdateIntegrationModal}                                from 'features/integration/update-integration-modal';
import {SyncIntegrationModal}                                  from 'features/integration/sync-integration-modal';
import {Link}                                                  from 'react-router-dom';


export function IntegrationInstallList({
    query = {}
}: {
    query?: Integration.List.Params
}) {
    const {t} = useTranslation();

    const {loading, data} = useQuery<Integration>({
        key: ['integration', query],
        fn:  () => glimpse.integration.list(query)
    });

    if (loading) {
        return <CenteredSpinner/>;
    }

    if (data?.length === 0) {
        return <Empty
            icon={<Icon fa={['fas', 'download']}/>}
            title={t('No installs yet')}
            desc={t('Install to import resources, connect data, populate reports and enrich your development experience.')}/>;
    }

    return (
        <Grid xs={12} gap={2}>
            {
                data?.map(integration => <Item integration={integration}/>)
            }
        </Grid>
    );
}

function Item({
    integration
}: {
    integration: Integration
}) {
    const {t}         = useTranslation();
    const updateModal = useModal();
    const deleteModal = useModal();
    const syncModal   = useModal();

    const menu = (
        <>
            <Menu.Group>
                <Menu.Item onClick={updateModal.toggle}>
                    {t('Edit details')}
                </Menu.Item>
                <Menu.Item onClick={updateModal.toggle}>
                    {t('Edit sync behaviour')}
                </Menu.Item>
            </Menu.Group>
            <Menu.Group>
                <Menu.Item onClick={syncModal.toggle}>
                    {t('Force sync')}
                </Menu.Item>
            </Menu.Group>
            <Menu.Group>
                <Menu.Item onClick={deleteModal.toggle}>
                    {t('Uninstall')}
                </Menu.Item>
            </Menu.Group>
        </>
    );

    return (
        <>
            {
                updateModal.open &&
                <UpdateIntegrationModal open={updateModal.open} onClose={updateModal.toggle} integrationId={integration.id}/>
            }
            {
                deleteModal.open &&
                <DeleteIntegrationModal open={deleteModal.open} onClose={deleteModal.toggle} integrationId={integration.id}/>
            }
            {
                syncModal.open &&
                <SyncIntegrationModal open={syncModal.open} onClose={syncModal.toggle} integrationId={integration.id}/>
            }
            <Box border={'default'} radius={1} p={2} effects={['hoverable']}>
                <Grid justify={'between'}>
                    <div>
                        <Box mb={0.5}>
                            <Text fontWeight={'bold'}>{integration.name}</Text>
                        </Box>
                        <Text color={'text.subtle'}>{t('Installed')} {integration.created_at}</Text>
                    </div>
                    <Menu menu={menu}>
                        <Button spacing={'compact'} variant={'subtle'} icon={<Icon fa={['fas', 'ellipsis']}/>}/>
                    </Menu>
                </Grid>
            </Box>
        </>
    );
}