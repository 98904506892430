import {Box, Text, useModal, Spaced} from '@glimpse/glass';
import {Component}                   from '@glimpse/glimpse';
import {RequirePermission}           from 'features/permission/require-permission';
import {useTranslation}              from 'foundations/i18n/use-translation';
import {CreateComponentOwnerModal}   from 'features/component-owner/create-component-owner-modal';
import React                         from 'react';
import {useQuery}                    from 'foundations/net/use-query';
import {glimpse}                     from 'providers/glimpse/glimpse-provider';
import {CenteredSpinner}             from 'foundations/ui/interstitial/centered-spinner';

export function ComponentMetaSection({
    componentId
}: {
    componentId: Component['id']
}) {
    const createModal = useModal();
    const {t}         = useTranslation();

    const {data, loading} = useQuery<Component>({
        key: ['component', {id: componentId}],
        fn:  () => glimpse.component.list({id: componentId})
    });

    const component = data?.[0];

    return (
        <RequirePermission permission={'component.read'}>
            {
                createModal.open &&
                <CreateComponentOwnerModal open={createModal.open} onClose={createModal.toggle} componentId={componentId}/>
            }
            <Box mb={1} ml={0.5}>
                <Text fontWeight='bold' fontSize={3}>{t('Meta')}</Text>
            </Box>
            <Box border={'default'} p={2} radius={1}>
                {
                    loading && <CenteredSpinner/>
                }
                {
                    component &&
                    !loading &&
                    <Spaced gap={1.5} direction={'vertical'}>
                        <Item label={t('Type')} value={component.type}/>
                        <Item label={t('Source')} value={component.source_connector ?? 'manual'}/>
                    </Spaced>
                }
            </Box>
        </RequirePermission>
    );
}

const Item = ({
    label,
    value
}: {
    label: string,
    value: string
}) => {
    return (
        <div>
            <Text display={'block'} fontWeight={'bold'}>{label}</Text>
            <Text color={'text.subtle'}>{value}</Text>
        </div>
    );
};