import {AppListing}                from '@glimpse/glimpse';
import {Modal, Button, Text, Flex} from '@glimpse/glass';
import {glimpse}                   from 'providers/glimpse/glimpse-provider';
import {useTranslation}      from 'foundations/i18n/use-translation';
import toast                 from 'react-hot-toast';
import {useMutate}           from 'foundations/net/use-mutate';
import {FormFallbackError}   from 'foundations/ui/form/form-fallback-error';

export function DeleteAppListingModal({
    open,
    onClose,
    appListingId
}: {
    open: boolean,
    onClose: () => void,
    appListingId: AppListing['id'],
}) {
    const {t}                      = useTranslation();
    const {loading, submit, error} = useMutate({
        key:       ['app-listing', appListingId],
        fn:        async () => await glimpse.appListing.delete(appListingId),
        onSuccess: () => {
            toast.success(t('App deleted'));
            onClose();
        }
    });

    return (
        <Modal onClose={onClose} open={open}>
            <Modal.Header onClose={onClose} title={t('Remove this app?')}/>
            <Modal.Body>
                <FormFallbackError error={error}/>
                <Text>
                    {t('Are you sure you want to remove this app? This cannot be undone.')}
                </Text>
            </Modal.Body>
            <Modal.Footer>
                <Flex justify={'end'}>
                    <Button onClick={onClose}>{t('Cancel')}</Button>
                    <Button variant='destructive' loading={loading} onClick={submit}>{t('Remove App')}</Button>
                </Flex>
            </Modal.Footer>
        </Modal>
    );
}