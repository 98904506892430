import {Box, Flex, Text} from '@glimpse/glass';
import {useQuery}        from 'foundations/net/use-query';
import {Insight}         from '@glimpse/glimpse';
import {glimpse}         from 'providers/glimpse/glimpse-provider';
import React, {useMemo}  from 'react';
import {LoadingPanel}    from 'foundations/ui/loading-panel/loading-panel';
import {useTranslation}  from 'foundations/i18n/use-translation';

export function InsightTotals({
    name,
    query
}: {
    name: string,
    query?: Insight.List.Params
}) {
    const {t}             = useTranslation();
    const {data, loading} = useQuery<Insight>({
        key: ['insight', query],
        fn:  () => glimpse.insight.list(query)
    });

    const insight = useMemo(() => {
        return data.find(data => data.name === name);
    }, [JSON.stringify(data)]);

    if (loading) {
        return <LoadingPanel/>;
    }

    return (
        <Box p={2} border={'default'} radius={1}>
            <Box mb={2}>
                <Text fontWeight={'bold'}>{insight?.label}</Text>
            </Box>
            {
                !loading &&
                (typeof insight?.value === 'undefined' || insight.value === null) &&
                <Box pt={1} pb={2}>
                    <Flex align={'center'} justify={'center'}>
                        <Text color={'text.subtle'}>{t('Not enough data')}</Text>
                    </Flex>
                </Box>
            }
            {
                Object.keys(insight?.value ?? {}).map((key) =>
                    <Box>
                        <Text display={'block'}>{`${key}: ${(insight?.value as Record<string, any>)[key]}`}</Text>
                    </Box>
                )
            }
        </Box>
    );
}