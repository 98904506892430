import {Table, Text, Box, Flex} from '@glimpse/glass';
import {ComponentOwner}         from '@glimpse/glimpse';
import {useNavigate}            from 'react-router-dom';
import React                    from 'react';
import {glimpse}                from 'providers/glimpse/glimpse-provider';
import {useTranslation}         from 'foundations/i18n/use-translation';
import {CenteredSpinner}        from 'foundations/ui/interstitial/centered-spinner';
import {useQuery}               from 'foundations/net/use-query';
import {RequirePermission}      from 'features/permission/require-permission';
import {ComponentIcon}          from 'features/component/component-icon';
import {EmptyStateSmall}        from 'foundations/ui/empty-state-small/empty-state-small';

export function TeamComponentTable({
    teamId
}: {
    teamId: ComponentOwner['team_id'],
}) {
    const {t}      = useTranslation();
    const navigate = useNavigate();
    const query    = {
        team_id: teamId,
        expand:  ['component']
    };

    const {loading, data} = useQuery<ComponentOwner>({
        key: ['component', query],
        fn:  async () => glimpse.componentOwner.list(query)
    });

    if (loading) {
        return (
            <Box border={'default'} xAlign={'center'} radius={1}>
                <CenteredSpinner/>
            </Box>
        );
    }

    if (data?.length === 0) {
        return <EmptyStateSmall text={t('No owned components')}/>
    }

    return (
        <RequirePermission permission={'component.read'} fallback>
            <Box border={'default'} radius={1}>
                <Table>
                    <Table.Body>
                        {
                            data?.map(ownership =>
                                <Table.Row effects={['clickable', 'hoverable']} onClick={() => navigate(`/components/${ownership.component?.id}`)} key={ownership.component?.id}>
                                    <Table.BodyCell>
                                        <Box>
                                            <Flex align={'center'}>
                                                <Box mr={1}>
                                                    <ComponentIcon componentType={ownership.component?.type!}/>
                                                </Box>
                                                <Text fontWeight={'bold'}>
                                                    {ownership.component?.name}
                                                </Text>
                                            </Flex>
                                        </Box>
                                    </Table.BodyCell>
                                    <Table.BodyCell>
                                        <Text color={'text.subtle'}>{ownership.component?.description}</Text>
                                    </Table.BodyCell>
                                </Table.Row>
                            )
                        }
                    </Table.Body>
                </Table>
            </Box>
        </RequirePermission>
    );
}