import {Select, SelectProps} from '@glimpse/glass';
import {useTranslation} from 'foundations/i18n/use-translation';
import {Pipeline}       from '@glimpse/glimpse';

export function SelectPipelineEnvironment(props: Omit<SelectProps, 'options'>) {
    const {t} = useTranslation();
    const options: {
        label: string,
        value: Exclude<Pipeline['environment'], null>
    }[]       = [
        {
            value: 'production',
            label: t('Production')
        },
        {
            value: 'staging',
            label: t('Staging')
        },
        {
            value: 'testing',
            label: t('Testing')
        },
        {
            value: 'development',
            label: t('Development')
        },
        {
            value: 'other',
            label: t('Other')
        }
    ];

    return <Select {...props} options={options}/>;
}