import {TeamMember}                from '@glimpse/glimpse';
import {Modal, Button, Form, Flex} from '@glimpse/glass';
import {SelectUser}                from 'features/user/select-user';
import {useForm}           from 'foundations/ui/form/use-form';
import {FormFallbackError} from 'foundations/ui/form/form-fallback-error';
import {glimpse}           from 'providers/glimpse/glimpse-provider';
import {useTranslation}    from 'foundations/i18n/use-translation';
import toast                 from 'react-hot-toast';
import {useQuery}  from 'foundations/net/use-query';
import {useMutate} from 'foundations/net/use-mutate';

export function CreateTeamMemberModal({
    open,
    onClose,
    teamId
}: {
    open: boolean,
    teamId: TeamMember['team_id'],
    onClose: () => void
}) {
    const {t}     = useTranslation();
    const form    = useForm<TeamMember.Create.Params>({team_id: teamId});
    const members = useQuery<TeamMember>({
        key: ['team-member', teamId],
        fn:  () => glimpse.teamMember.list({team_id: teamId})
    });
    const api     = useMutate<TeamMember>({
        key:       ['team-member'],
        fn:        () => glimpse.teamMember.create(form.data),
        onSuccess: () => {
            toast.success(t('Team member added'));
            onClose();
        }
    });

    return (
        <Modal onClose={onClose} open={open}>
            <Modal.Header onClose={onClose} title={t('Add a team member')}/>
            <Modal.Body>
                <Form onSubmit={api.submit}>
                    <FormFallbackError error={api.error}/>
                    <Form.Field label={t('Team Member')} errors={api.error?.params.user_id}>
                        <SelectUser {...form.register('user_id', {required: true})} exclude={members.data?.map(member => member.user_id)}/>
                    </Form.Field>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Flex justify={'end'}>
                    <Button onClick={onClose}>{t('Cancel')}</Button>
                    <Button variant='primary' loading={api.loading} onClick={api.submit} disabled={!form.valid}>{t('Add Member')}</Button>
                </Flex>
            </Modal.Footer>
        </Modal>
    );
}