import React, {useMemo}                 from 'react';
import {LoadingPanel}                   from 'foundations/ui/loading-panel/loading-panel';
import {useQuery}                       from 'foundations/net/use-query';
import {Insight}                        from '@glimpse/glimpse';
import {glimpse}                        from 'providers/glimpse/glimpse-provider';
import {ResponsiveLine}                 from '@nivo/line';
import {Box, Flex, Icon, Text, Tooltip} from '@glimpse/glass';
import {GlimpseChartTheme}              from 'foundations/ui/chart/glimpse-chart-theme';
import {GlimpseChartColors}             from 'foundations/ui/chart/glimpse-chart-colors';
import {ChartLegend}                    from 'foundations/ui/chart/chart-legend';
import {ageFromTimestamp}               from 'foundations/ui/date/age-from-timestamp';
import {useTranslation}                 from 'foundations/i18n/use-translation';

export function InsightHistory({
    name,
    query
}: {
    name: string,
    query: Insight.List.Params
}) {
    const {t} = useTranslation();
    const insightApi = useQuery<Insight>({
        key: ['insight', query],
        fn:  () => glimpse.insight.list(query)
    });

    const insight = useMemo(() => {
        if (insightApi.data) {
            return insightApi.data.find((insight) => insight.name === name);
        } else {
            return null;
        }

    }, [name, JSON.stringify(insightApi.data)]);

    const chartData = useMemo(() => {
        const byLine: Record<string, any> = {};

        if (!Array.isArray(insight?.value)) {
            console.log('Insight history can only be used with array');
            return [];
        }

        for (const value of insight?.value ?? []) {
            for (const key of Object.keys(value)) {
                if (key !== 'date') {
                    if (!Array.isArray(byLine[key])) {
                        byLine[key] = [];
                    }

                    byLine[key].push({
                        x: value.date,
                        y: value[key]
                    });
                }
            }
        }

        return Object.keys(byLine).map((key) => ({
            id:   key,
            data: byLine[key]
        }));

    }, [insight?.value]);

    if (insightApi.loading) {
        return <LoadingPanel/>;
    }

    return (
        <Box border={'default'} p={2} radius={1}>
            <Box mb={2}>
                <Flex align={'center'} justify={'between'}>
                    <Flex align={'center'} gap={1}>
                        <Text fontWeight={'bold'}>{insight?.label}</Text>
                        {
                            insight?.description &&
                            <Tooltip content={insight.description}>
                                <Icon fa={['far', 'circle-info']} color={'n60'}/>
                            </Tooltip>
                        }
                    </Flex>
                </Flex>
            </Box>
            <ChartLegend>
                {
                    Array.isArray(insight?.value) &&
                    Object.keys(insight?.value?.[0] ?? {})
                        .filter((key) => key !== 'date')
                        .map((key, i) =>
                            <ChartLegend.Item label={key} color={GlimpseChartColors[i]}/>
                        )
                }
            </ChartLegend>
            <div style={{height: 250}}>
                <ResponsiveLine
                    data={chartData}
                    theme={GlimpseChartTheme}
                    colors={GlimpseChartColors}
                    enableGridX={false}
                    enablePoints={true}
                    margin={{top: 40, right: 25, bottom: 25, left: 40}}
                    curve={'monotoneX'}
                    axisBottom={{
                        tickSize:       5,
                        tickPadding:    5,
                        tickRotation:   0,
                        truncateTickAt: 0
                    }}
                    axisLeft={{
                        tickSize:       5,
                        tickValues:     6,
                        tickPadding:    5,
                        tickRotation:   0,
                        truncateTickAt: 0,
                        format:         function (value) {
                            switch (insight?.value_format) {
                                case 'duration':
                                    const age = ageFromTimestamp(value);
                                    return `${age.value.toFixed(0)}${age.shortUnit}`;
                                case `percentage`:
                                    return `${(value * 100).toFixed(0)}%`;
                                default:
                                    return value.toString();
                            }
                        }
                    }}
                />
            </div>
        </Box>
    );
}