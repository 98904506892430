import {Box, Text, Button, useModal, Icon, Flex} from '@glimpse/glass';
import {Scorecard}                               from '@glimpse/glimpse';
import {RequirePermission}                       from 'features/permission/require-permission';
import {useTranslation}                          from 'foundations/i18n/use-translation';
import {faPlus}                                  from '@fortawesome/pro-light-svg-icons';
import React                      from 'react';
import {ScorecardRuleTable}       from 'features/scorecard-rule/scorecard-rule-table';
import {CreateScorecardRuleModal} from 'features/scorecard-rule/create-scorecard-rule-modal';

export function ScorecardRuleSection({
    scorecardId
}: {
    scorecardId: Scorecard['id']
}) {
    const createModal = useModal();
    const {t}         = useTranslation();

    return (
        <>
            <CreateScorecardRuleModal scorecardId={scorecardId} open={createModal.open} onClose={createModal.toggle}/>
            <Box mb={1}>
                <Flex justify='between' align={'center'}>
                    <Text fontWeight='bold' fontSize={3}>{t('Rules')}</Text>
                    <RequirePermission permission={'scorecard.write'}>
                        <Button spacing={'compact'} icon={<Icon fa={faPlus}/>} onClick={createModal.toggle}/>
                    </RequirePermission>
                </Flex>
            </Box>
            <ScorecardRuleTable query={{scorecard_id: scorecardId}}/>
        </>
    );
}