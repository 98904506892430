import {Modal, Button, Form, Textbox, Textarea, Alert, Box, Flex} from '@glimpse/glass';
import {ErrorModal}                                               from 'foundations/ui/error-modal/error-modal';
import {LoadingModal}                                             from 'foundations/ui/loading-modal/loading-modal';
import {useForm}                                                  from 'foundations/ui/form/use-form';
import React                                                      from 'react';
import {Component}                                                from '@glimpse/glimpse';
import {glimpse}                                                  from 'providers/glimpse/glimpse-provider';
import {useTranslation}                                           from 'foundations/i18n/use-translation';
import toast                                                      from 'react-hot-toast';
import {useMutate}                                                from 'foundations/net/use-mutate';
import {useQuery}                                                 from 'foundations/net/use-query';
import {SelectComponentType}                                      from 'features/component/select-component-type';
import {FormFallbackError}                                        from 'foundations/ui/form/form-fallback-error';

export function UpdateComponentModal({
    open,
    onClose,
    componentId
}: {
    open: boolean,
    onClose: () => void,
    componentId: Component['id']
}) {
    const {t}  = useTranslation();
    const form = useForm<Component.Update.Params>();

    const load = useQuery<Component>({
        key:       ['component', componentId],
        fn:        async () => await glimpse.component.list({id: componentId}),
        onSuccess: (res) => form.setData(res.data[0])
    });

    const mutate = useMutate<Component>({
        key:       ['component', componentId],
        fn:        async () => await glimpse.component.update(componentId, form.data),
        onSuccess: () => {
            toast.success(t('Component updated'));
            onClose();
        }
    });

    if (load.loading)
        return <LoadingModal onClose={onClose} open={open}/>;

    if (load.error)
        return <ErrorModal onClose={onClose} open={open}/>;

    return (
        <Modal onClose={onClose} open={open}>
            <Modal.Header onClose={onClose} title={t('Update this component')}/>
            <Modal.Body>
                {
                    load.data?.[0]?.source_connector &&
                    <Box mb={2}>
                        <Alert bg={'info.subtle'}>
                            {t('This component is managed by an integration. Some details, such as the name and type, may be overridden by the integration.')}
                        </Alert>
                    </Box>
                }
                <Form>
                    <FormFallbackError error={mutate.error}/>
                    <Form.Field label={t('Name')} errors={mutate.error?.params.name}>
                        <Textbox {...form.register('name')} placeholder={t('Authentication Gateway')}/>
                    </Form.Field>
                    <Form.Field label={t('Type')} errors={mutate.error?.params.type}>
                        <SelectComponentType {...form.register('type')}/>
                    </Form.Field>
                    <Form.Field label={t('Description')} errors={mutate.error?.params.description}>
                        <Textarea {...form.register('description')}/>
                    </Form.Field>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Flex justify={'end'}>
                    <Button onClick={onClose}>{t('Cancel')}</Button>
                    <Button variant='primary' loading={mutate.loading} onClick={mutate.submit}>{t('Save Changes')}</Button>
                </Flex>
            </Modal.Footer>
        </Modal>
    );
}