import AzureIcon     from 'features/integration/azure/azure-icon.png';
import GithubIcon    from 'features/integration/github/github-icon.png';
import PosthogIcon   from 'features/integration/posthog/posthog-icon.png';
import TerraformIcon from 'features/integration/terraform/terraform-icon.png';
import SentryIcon    from 'features/integration/sentry/sentry-icon.png';
import NotionIcon    from 'features/integration/notion/notion-icon.png';
import JiraIcon      from 'features/integration/jira/jira-icon.png';
import {Config}      from 'foundations/config/config';

const openInstallWindow = (url: string) => window.open(
    url,
    `Install Integration`,
    'toolbar=no,location=no,status=no,menubar=no,width=600,height=700,top=100,left=100'
);

const definition: Record<string, {
    label: string,
    icon: string,
    connector: string,
    capabilities?: {
        apps?: boolean,
        multiInstall?: boolean,
        glimpseGql?: boolean,
        realTimeSync?: boolean,
    }
    features?: {
        components?: boolean,
        repositories?: boolean,
        pullRequests?: boolean,
        teams?: boolean,
        insights?: boolean,
        scorecards?: boolean,
        pipelines?: boolean
    },
    install?: () => void
}> = {
    azure:     {
        connector:    'azure',
        label:        'Azure',
        icon:         AzureIcon,
        capabilities: {
            multiInstall: true,
            glimpseGql:   true,
            realTimeSync: true
        },
        features:     {
            components: true
        },
        install:      () => openInstallWindow('/settings/integrations/azure/create')
    },
    jira:      {
        connector:    'jira',
        label:        'Jira',
        icon:         JiraIcon,
        capabilities: {
            multiInstall: true,
            glimpseGql:   true,
            realTimeSync: true
        },
        features:     {
            insights:   true,
            scorecards: true
        },
        install:      () => openInstallWindow('/settings/integrations/jira/create')
    },
    github:    {
        connector:    'github',
        label:        'Github',
        icon:         GithubIcon,
        capabilities: {
            apps:         true,
            glimpseGql:   true,
            realTimeSync: true
        },
        features:     {
            components:   true,
            teams:        true,
            repositories: true,
            pullRequests: true,
            insights:     true,
            scorecards:   true,
            pipelines:    true
        },
        install:      () => openInstallWindow(Config.isProduction()
            ? 'https://github.com/apps/glimpse-github'
            : 'https://github.com/apps/glimpse-github-development-version'
        )
    },
    posthog:   {
        connector:    'posthog',
        label:        'PostHog',
        icon:         PosthogIcon,
        capabilities: {
            multiInstall: true,
            glimpseGql:   true,
            realTimeSync: true
        },
        features:     {
            insights:   true,
            scorecards: true
        },
        install:      () => openInstallWindow('/settings/integrations/posthog/create')
    },
    sentry:    {
        connector: 'sentry',
        label:     'Sentry',
        icon:      SentryIcon
    },
    terraform: {
        connector: 'sentry',
        label:     'Sentry',
        icon:      TerraformIcon
    },
    notion:    {
        connector: 'sentry',
        label:     'Sentry',
        icon:      NotionIcon
    }
};

export class IntegrationHelper {

    static get(connector: string) {
        return definition[connector];
    }

    static getUnsafe(connector: string | null | undefined) {
        if (connector === null || typeof connector === 'undefined') {
            return null;
        }
        return definition[connector];
    }

    static listAll() {
        return Object.values(definition);
    }

    static parseIntegrationFromUrl(url: string) {
        if (url.startsWith('https://github.com')) {
            return definition.github;
        }

        if (url.includes('sentry.io')) {
            return definition.sentry;
        }

        if (url.includes('notion.so')) {
            return definition.notion;
        }

        if (url.includes('azure.com')) {
            return definition.azure;
        }

        if (url.includes('posthog.com')) {
            return definition.posthog;
        }

        if (url.includes('terraform.io')) {
            return definition.terraform;
        }
    }
}