import {Team}               from '@glimpse/glimpse';
import {Text, Box, Flex}    from '@glimpse/glass';
import React                from 'react';
import {useTranslation}     from 'foundations/i18n/use-translation';
import {TeamComponentTable} from 'features/team-component/team-component-table';

export function TeamComponentSection({
    teamId
}: {
    teamId: Team['id']
}) {
    const {t} = useTranslation();
    return (
        <>
            <Box mb={1} ml={0.5}>
                <Flex justify='between' align='center'>
                    <Text fontWeight='bold' fontSize={3}>{t('Owned Components')}</Text>
                </Flex>
            </Box>
            <TeamComponentTable teamId={teamId}/>
        </>
    );
}