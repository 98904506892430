import {Modal, Button, Text, Flex} from '@glimpse/glass';
import {useNavigate}               from 'react-router-dom';
import {Team}           from '@glimpse/glimpse';
import {glimpse}        from 'providers/glimpse/glimpse-provider';
import {useTranslation} from 'foundations/i18n/use-translation';
import toast                 from 'react-hot-toast';
import {useMutate}           from 'foundations/net/use-mutate';
import {FormFallbackError}   from 'foundations/ui/form/form-fallback-error';

export function DeleteTeamModal({
    open,
    onClose,
    teamId
}: {
    open: boolean,
    onClose: () => void,
    teamId: Team['id'],
}) {
    const {t}      = useTranslation();
    const navigate = useNavigate();
    const {loading, submit, error}      = useMutate({
        key:       ['team', teamId],
        fn:        () => glimpse.team.delete(teamId),
        onSuccess: () => {
            toast.success(t('Team deleted'));
            navigate('/teams');
        }
    });

    return (
        <Modal onClose={onClose} open={open}>
            <Modal.Header onClose={onClose} title={t('Delete this team?')}/>
            <Modal.Body>
                <FormFallbackError error={error}/>
                <Text>
                    {t('Are you sure you want to remove this team? This cannot be undone.')}
                </Text>
            </Modal.Body>
            <Modal.Footer>
                <Flex justify={'end'}>
                    <Button onClick={onClose}>{t('Cancel')}</Button>
                    <Button variant='destructive' loading={loading} onClick={submit}>{t('Delete Team')}</Button>
                </Flex>
            </Modal.Footer>
        </Modal>
    );
}