import {Text, Form, Textbox, Box, Button, Separator, Grid, Alert} from '@glimpse/glass';
import {AuthLayout}                                               from 'foundations/layouts/auth-layout/auth-layout';
import {useNavigate}                                              from 'react-router-dom';
import {useForm}                                                  from 'foundations/ui/form/use-form';
import {Auth, Session}                                            from '@glimpse/glimpse';
import {glimpse}                                                  from 'providers/glimpse/glimpse-provider';
import {useTranslation}                                           from 'foundations/i18n/use-translation';
import {useMutate}                                                from 'foundations/net/use-mutate';
import {useAuth}                                                  from 'features/auth/use-auth';
import {useEffect, useState}                                      from 'react';
import toast                                                      from 'react-hot-toast';
import {useGoogleAuth}                                            from 'features/auth/google-auth/use-google-auth';
import {AuthButton}                                               from 'features/auth/auth-button/auth-button';

export function LoginScreen() {
    const {t}                 = useTranslation();
    const {signIn}            = useAuth();
    const navigate            = useNavigate();
    const [method, setMethod] = useState<string>('password');
    const signup              = () => navigate('/signup');
    const resetPassword       = () => navigate('/reset-password');

    const form = useForm<Auth.Signin.Params>({
        method: 'password'
    });

    const {error, submit, loading} = useMutate<Session>({
        key:       ['session'],
        fn:        glimpse.auth.signin,
        onSuccess: (res) => signIn(res.data[0].token)
    });

    const googleAuth = useGoogleAuth({
        onSuccess: (auth, profile) => {
            setMethod('google');
            submit({
                method:       'google',
                google_token: auth.access_token,
                email:        profile.email
            });
        }
    });

    const passwordAuth = () => submit({
        ...form.data,
        method: 'password'
    });

    useEffect(() => {
        if (error?.code) {
            const foundError = error?.params?.['email']?.[0]
                ?? error?.params?.['name']?.[0]
                ?? error?.message
                ?? t('Unable to sign up');

            if (foundError !== 'Required') {
                toast.error(foundError);
            }
        }
    }, [error, method]);

    return (
        <AuthLayout>
            <Box xAlign={'center'} mb={6}>
                <Text fontSize={5} fontWeight='bold'>{t('Login to your account')}</Text>
            </Box>
            <Form onSubmit={passwordAuth}>
                {
                    error?.code === 'api_authentication_error' &&
                    <Box pb={3} mt={-1}>
                        <Alert bg='danger.subtle'>
                            {error.message}
                        </Alert>
                    </Box>
                }
                <Form.Field label={t('Email')} errors={error?.params.email}>
                    <Textbox {...form.register('email')}/>
                </Form.Field>
                <Form.Field label={t('Password')} errors={error?.params.password}>
                    <Textbox {...form.register('password')} type='password'/>
                </Form.Field>
            </Form>
            <Box pt={4}>
                <Button fullwidth variant='primary' spacing='large' onClick={passwordAuth} loading={loading}>{t('Continue')}</Button>
            </Box>
            <Box pt={2} pb={2}>
                <Separator/>
            </Box>
            <Box mb={4}>
                <AuthButton method={'google'} text={t('Login with Google')} onClick={googleAuth.submit}/>
            </Box>
            <Grid justify='between'>
                <Button variant='link' onClick={signup}>{t('Create an account')}</Button>
                <Button variant='link' onClick={resetPassword}>{t('Forgot your password?')}</Button>
            </Grid>
        </AuthLayout>
    );
}