import {Modal, Button, Form, Textbox, Flex} from '@glimpse/glass';
import {ErrorModal}                         from 'foundations/ui/error-modal/error-modal';
import {LoadingModal}                       from 'foundations/ui/loading-modal/loading-modal';
import {useForm}                            from 'foundations/ui/form/use-form';
import React                                from 'react';
import {glimpse}                            from 'providers/glimpse/glimpse-provider';
import {useTranslation}                     from 'foundations/i18n/use-translation';
import toast                                from 'react-hot-toast';
import {useQuery}                           from 'foundations/net/use-query';
import {useMutate}                          from 'foundations/net/use-mutate';
import {ScorecardRule}                      from '@glimpse/glimpse';

export function UpdateScorecardRuleModal({
    open,
    onClose,
    scorecardRuleId
}: {
    open: boolean,
    onClose: () => void,
    scorecardRuleId: ScorecardRule['id']
}) {
    const {t}  = useTranslation();
    const form = useForm<ScorecardRule.Update.Params>();
    const load = useQuery<ScorecardRule>({
        key:       ['scorecard-rule', scorecardRuleId],
        fn:        () => glimpse.scorecardRule.list({id: scorecardRuleId}),
        onSuccess: (res) => form.setData(res?.data[0])
    });

    const mutate = useMutate<ScorecardRule>({
        key:       ['scorecard-rule', scorecardRuleId],
        fn:        () => glimpse.scorecardRule.update(scorecardRuleId, form.data),
        onSuccess: () => {
            toast.success(t('Rule deleted'));
            onClose();
        }
    });

    if (load.loading)
        return <LoadingModal onClose={onClose} open={open}/>;

    if (load.error)
        return <ErrorModal onClose={onClose} open={open}/>;

    return (
        <Modal onClose={onClose} open={open}>
            <Modal.Header onClose={onClose} title={t('Update this rule')}/>
            <Modal.Body>
                <Form>
                    <Form.Field label={t('Name')} errors={mutate.error?.params.name}>
                        <Textbox {...form.register('name')} placeholder={t('Has Readme')}/>
                    </Form.Field>
                    <Form.Field label={t('GQL Query')} errors={mutate.error?.params.gql} description={t('Queries are the foundation of your check. Write queries to evaluate data, make comparisons and access integration functions.')}>
                        <Textbox {...form.register('gql')} placeholder={'github.hasFile("readme.md")'}/>
                    </Form.Field>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Flex justify={'end'}>
                    <Button onClick={onClose}>{t('Cancel')}</Button>
                    <Button variant='primary' loading={mutate.loading} onClick={mutate.submit}>{t('Save Changes')}</Button>
                </Flex>
            </Modal.Footer>
        </Modal>
    );
}