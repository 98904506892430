import {Box, Text, Button, useModal, Flex, Icon} from '@glimpse/glass';
import {Integration}                             from '@glimpse/glimpse';
import {useTranslation}                          from 'foundations/i18n/use-translation';
import React                                     from 'react';
import {IntegrationInstallList}                  from 'features/integration-install/integration-install-list';

export function IntegrationInstallSection({
    connector
}: {
    connector: Integration['connector']
}) {
    const {t} = useTranslation();

    return (
        <>
            <Box mb={1} ml={0.5}>
                <Flex justify='between' align={'center'}>
                    <Text fontWeight='bold' fontSize={3}>{t('Current Installs')}</Text>
                </Flex>
            </Box>
            <IntegrationInstallList query={{
                connector
            }}/>
        </>
    );
}