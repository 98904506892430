import {Component, ComponentPipeline}                                     from '@glimpse/glimpse';
import {Table, Button, Box, useModal, Text, Reveal, Flex, Image, Tooltip} from '@glimpse/glass';
import {glimpse}                                                          from 'providers/glimpse/glimpse-provider';
import React, {useState}                                                  from 'react';
import {useQuery}                                                         from 'foundations/net/use-query';
import {RequirePermission}                                                from 'features/permission/require-permission';
import {CenteredSpinner}                                                  from 'foundations/ui/interstitial/centered-spinner';
import {useTranslation}                                                   from 'foundations/i18n/use-translation';
import {DeleteComponentPipelineModal}                                     from 'features/component-pipeline/delete-component-pipeline-modal';
import {PipelineStatusBadge}                                              from 'features/pipeline/pipeline-status-badge';
import {IntegrationHelper}                                                from 'features/integration/integration-helper';
import {EmptyStateSmall}                                                  from 'foundations/ui/empty-state-small/empty-state-small';

export function ComponentPipelineTable({
    componentId
}: {
    componentId: Component['id']
}) {
    const {t}                     = useTranslation();
    const modal                   = useModal();
    const [selected, setSelected] = useState<ComponentPipeline['id']>();
    const query                   = {
        component_id: componentId,
        expand:       ['pipeline']
    };
    const {loading, data}         = useQuery<ComponentPipeline>({
        key: ['component-pipeline', query],
        fn:  async () => await glimpse.componentPipeline.list(query)
    });

    const deletePipeline = (id: ComponentPipeline['id']) => {
        setSelected(id);
        modal.toggle();
    };

    if (loading) {
        return (
            <Box border={'default'} xAlign={'center'} radius={1}>
                <CenteredSpinner/>
            </Box>
        );
    }

    if (data?.length === 0) {
        return <EmptyStateSmall text={t('No connected pipelines')}/>;
    }

    return (
        <Box border={'default'} radius={1}>
            <Table>
                <DeleteComponentPipelineModal open={modal.open} onClose={modal.toggle} componentPipelineId={selected!}/>
                <RequirePermission permission={'component.read'} fallback>
                    <Table.Body>
                        {
                            data?.map(pipeline =>
                                <Reveal>
                                    <Table.Row key={pipeline.id} effects={['hoverable']}>
                                        <Table.BodyCell>
                                            <Box p={1}>
                                                <Flex align={'center'} gap={1}>
                                                    <Tooltip content={IntegrationHelper.get(pipeline.pipeline?.source_connector!).label}>
                                                        <Image width={'18px'} source={IntegrationHelper.get(pipeline.pipeline?.source_connector!).icon}/>
                                                    </Tooltip>
                                                    <Text fontWeight={'bold'}>
                                                        {pipeline.pipeline?.name}
                                                    </Text>
                                                </Flex>
                                            </Box>
                                        </Table.BodyCell>
                                        <Table.BodyCell shrink>
                                            <Flex align={'center'} gap={1}>
                                                {
                                                    /**
                                                     <PipelineStatusBadge status={pipeline.pipeline?.status}/>
                                                     */
                                                }
                                                <RequirePermission permission={'component.write'}>
                                                    <Reveal.Hidden>
                                                        <Button spacing='compact' icon='far fa-xmark' onClick={() => deletePipeline(pipeline.id)}/>
                                                    </Reveal.Hidden>
                                                </RequirePermission>
                                            </Flex>
                                        </Table.BodyCell>
                                    </Table.Row>
                                </Reveal>
                            )
                        }
                    </Table.Body>
                </RequirePermission>
            </Table>
        </Box>
    );
}