import {useModal, Button, Icon, Box, Text, Grid} from '@glimpse/glass';
import {AppLayout}                               from 'foundations/layouts/app-layout/app-layout';
import React                                     from 'react';
import {RequirePermission}                       from 'features/permission/require-permission';
import {useTranslation}                          from 'foundations/i18n/use-translation';
import {ApiKeyTable}                             from 'features/api-key/api-key-table';
import {CreateApiKeyModal}                       from 'features/api-key/create-api-key-modal';
import {RequireEntitlement}                      from 'features/entitlement/require-entitlement';
import {BillingProductUpsell}                    from 'features/billing-product/billing-product-upsell';

export function SettingsApiKeysScreen() {
    const createApiKey = useModal();
    const {t}          = useTranslation();

    const upsell = <BillingProductUpsell
        icon={<Icon fa={['fas', 'fa-key']} color={'secondary'} fontSize={4}/>}
        preTitle={t('API Keys')}
        title={t('Programmatically access Glimpse from your own applications')}
        desc={t('Manage components remotely, add/remove team members, create advanced automations, track deployments. Use your own code to manage all of Glimpse.')}/>;

    return (
        <AppLayout title={t('API Keys')}>
            <RequirePermission permission={'workspace.write'} fallback>
                {
                    createApiKey.open &&
                    <CreateApiKeyModal onClose={createApiKey.toggle}/>
                }
                <AppLayout.Header>
                    <Box pt={3} pb={3}>
                        <Grid justify='between' align='center'>
                            <Text fontSize={4} fontWeight='bolder'>{t('API Keys')}</Text>
                            <RequireEntitlement entitlement={'api-keys'}>
                                <Button variant='primary' icon={<Icon fa={['far', 'fa-plus']}/>} onClick={createApiKey.toggle}>{t('Add API Key')}</Button>
                            </RequireEntitlement>
                        </Grid>
                    </Box>
                </AppLayout.Header>
                <AppLayout.Body>
                    <RequireEntitlement entitlement={'api-keys'} fallback={upsell}>
                        <ApiKeyTable/>
                    </RequireEntitlement>
                </AppLayout.Body>
            </RequirePermission>
        </AppLayout>
    );
}