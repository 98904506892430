import {PullRequestTable}               from 'features/pull-request/pull-request-table';
import {useForm}                        from 'foundations/ui/form/use-form';
import {PullRequest}                    from '@glimpse/glimpse';
import {Box, BoxedSelector, Flex, Text} from '@glimpse/glass';
import {AppLayout}                      from 'foundations/layouts/app-layout/app-layout';
import React                            from 'react';
import {useTranslation}                 from 'foundations/i18n/use-translation';
import {PullRequestStateFilter}         from 'features/pull-request/pull-request-state-filter';
import {AutoFilter}                     from 'foundations/ui/filter/auto-filter';

export function PullRequestsScreen() {
    const {t}  = useTranslation();
    const form = useForm<PullRequest.List.Params>();

    const options = [
        {label: t('All'), value: undefined},
        {label: t('Mine'), value: 'mine'},
        {label: t('Assigned'), value: 'assigned'},
    ];

    return (
        <AppLayout title={t('Pull Requests')}>
            <AppLayout.Header>
                <Box pt={3} pb={3}>
                    <Text fontSize={4} fontWeight='bolder'>{t('Pull Requests')}</Text>
                </Box>
            </AppLayout.Header>
            <AppLayout.Body>
                <Box mb={2}>
                    <BoxedSelector {...form.register('state')} options={options}/>
                </Box>
                <Box mb={2}>
                    <Flex gap={1}>
                        <PullRequestStateFilter {...form.register('state')}/>
                        <AutoFilter {...form.register('source_target_namespace_key')} resource={'pull-request'} property={'source_target_namespace_key'} label={'Organization'}/>
                        <AutoFilter {...form.register('source_target_key')} resource={'pull-request'} property={'source_target_key'} label={'Repo'}/>
                    </Flex>
                </Box>
                <PullRequestTable query={form.data}/>
            </AppLayout.Body>
        </AppLayout>
    );
}