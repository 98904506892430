import {Modal, Button, Form, Textbox, Flex} from '@glimpse/glass';
import {ErrorModal}                         from 'foundations/ui/error-modal/error-modal';
import {LoadingModal}                 from 'foundations/ui/loading-modal/loading-modal';
import {useForm}                      from 'foundations/ui/form/use-form';
import {Link}                         from '@glimpse/glimpse';
import {glimpse}                      from 'providers/glimpse/glimpse-provider';
import {useTranslation}               from 'foundations/i18n/use-translation';
import toast                          from 'react-hot-toast';
import {useMutate}                    from 'foundations/net/use-mutate';
import {useQuery}                     from 'foundations/net/use-query';

export function UpdateLinkModal({
    open,
    onClose,
    linkId
}: {
    open: boolean,
    onClose: () => void,
    linkId: Link['id']
}) {
    const {t}    = useTranslation();
    const form   = useForm<Link.Update.Params>();
    const update = useMutate<Link>({
        key:       ['link'],
        fn:        () => glimpse.link.update(linkId, form.data),
        onSuccess: () => {
            toast.success(t('Link updated'));
            onClose();
        }
    });

    const load = useQuery<Link>({
        key:       ['link', linkId],
        fn:        () => glimpse.link.list({id: linkId}),
        onSuccess: (res) => {
            const data = {...(res?.data?.[0] ?? [])};
            // @ts-ignore
            delete data.parsed;
            form.setData(data as Link.Update.Params);
        }
    });

    if (load.loading) {
        return <LoadingModal onClose={onClose} open={open}/>;
    }

    if (load.error) {
        return <ErrorModal onClose={onClose} open={open}/>;
    }

    return (
        <Modal onClose={onClose} open={open}>
            <Modal.Header onClose={onClose} title={t('Update this link')}/>
            <Modal.Body>
                <Form>
                    <Form.Field label={t('Url')} errors={update.error?.params.url}>
                        <Textbox {...form.register('url')}/>
                    </Form.Field>
                    <Form.Field label={t('Custom Label')} errors={update.error?.params.label} description={t('Supply a custom label instead of using dynamically generated labels')}>
                        <Textbox {...form.register('label')} placeholder={load?.data?.[0]?.parsed.label ?? ''}/>
                    </Form.Field>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Flex justify={'end'}>
                    <Button onClick={onClose}>{t('Cancel')}</Button>
                    <Button variant='primary' loading={update.loading} onClick={update.submit}>{t('Save Changes')}</Button>
                </Flex>
            </Modal.Footer>
        </Modal>
    );
}