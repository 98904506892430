export const ageFromTimestamp = (timestamp: number): {
    value: number,
    unit: string,
    shortUnit: string,
} => {
    const minutes = timestamp / 60;
    const hours   = timestamp / (60 * 60);
    const days    = timestamp / (60 * 60 * 24);
    const weeks   = timestamp / (60 * 60 * 24 * 7);
    const months  = timestamp / (60 * 60 * 24 * 30);
    const years   = timestamp / (60 * 60 * 24 * 365);

    if (years > 1) {
        return {value: years, unit: 'years', shortUnit: 'y'};
    }

    if (months > 1) {
        return {value: months, unit: 'months', shortUnit: 'm'};
    }

    if (weeks > 1) {
        return {value: weeks, unit: 'weeks', shortUnit: 'w'};
    }

    if (days > 1) {
        return {value: days, unit: 'days', shortUnit: 'd'};
    }

    if (hours > 1) {
        return {value: hours, unit: 'hours', shortUnit: 'h'};
    }

    if (minutes > 1) {
        return {value: minutes, unit: 'minutes', shortUnit: 'm'};
    }

    return {value: timestamp, unit: 'seconds', shortUnit: 's'};
};