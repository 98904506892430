import {Modal, Button, Form, Textbox, Flex, Select} from '@glimpse/glass';
import {Component, Property, PropertyValue}         from '@glimpse/glimpse';
import {useForm}                                    from 'foundations/ui/form/use-form';
import {glimpse}                                    from 'providers/glimpse/glimpse-provider';
import {useTranslation}                             from 'foundations/i18n/use-translation';
import toast                                        from 'react-hot-toast';
import {useMutate}                                  from 'foundations/net/use-mutate';
import {useQuery}                                   from 'foundations/net/use-query';
import {useMemo}                                    from 'react';
import {SelectProperty}                             from 'features/property/select-property';

export function CreatePropertyValueModal({
    open,
    onClose,
    componentId
}: {
    open: boolean,
    onClose: () => void,
    componentId: Component['id']
}) {
    const {t}   = useTranslation();
    const form  = useForm<PropertyValue.Create.Params>({
        component_id: componentId
    });
    const query = {
        component_id: componentId,
        expand:       ['property']
    };

    const properties = useQuery<Property>({
        key: ['property'],
        fn:  glimpse.property.list
    });

    const values = useQuery<PropertyValue>({
        key: ['property-value', query],
        fn:  () => glimpse.propertyValue.list(query)
    });

    const {error, loading, submit} = useMutate({
        key:       ['property-value'],
        fn:        () => glimpse.propertyValue.create(form.data),
        onSuccess: (res) => {
            toast.success(t('Property added'));
            onClose();
        }
    });

    const selected = useMemo(() => {
        return properties.data.find((a) => a.id === form.data.property_id);
    }, [form.data.property_id, JSON.stringify(properties.data)]);


    const selectedOptions = selected?.options?.split(',').map((opt) => ({
        label: opt.trim(),
        value: opt.trim()
    }));

    return (
        <Modal onClose={onClose} open={open}>
            <Modal.Header title={t('Add a property')} onClose={onClose}/>
            <Modal.Body>
                <Form>
                    <Form.Field label={t('Property')} errors={error?.params.property_id}>
                        <SelectProperty {...form.register('property_id', {required: true})} exclude={values.data.map(value => value.property_id)}/>
                    </Form.Field>
                    {
                        selected?.format === 'select' &&
                        <Form.Field errors={error?.params.value} label={t('Value')}>
                            <Select {...form.register('value', {required: true})} options={selectedOptions ?? []}/>
                        </Form.Field>
                    }
                    {
                        selected?.format === 'text' &&
                        <Form.Field errors={error?.params.value} label={t('Value')}>
                            <Textbox {...form.register('value', {required: true})}/>
                        </Form.Field>
                    }
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Flex justify={'end'}>
                    <Button onClick={onClose}>{t('Cancel')}</Button>
                    <Button variant='primary' loading={loading} onClick={submit} disabled={!form.valid}>{t('Add Property')}</Button>
                </Flex>
            </Modal.Footer>
        </Modal>
    );
}