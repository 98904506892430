import {Integration}                                    from '@glimpse/glimpse';
import {Modal, Button, Text, Flex, Alert, Spinner, Box} from '@glimpse/glass';
import {glimpse}                                        from 'providers/glimpse/glimpse-provider';
import {useTranslation}                                 from 'foundations/i18n/use-translation';
import {useMutate}                                      from 'foundations/net/use-mutate';
import {FormFallbackError}                              from 'foundations/ui/form/form-fallback-error';

export function SyncIntegrationModal({
    open,
    onClose,
    integrationId
}: {
    open: boolean,
    onClose: () => void,
    integrationId: Integration['id'],
}) {
    const {t}                      = useTranslation();
    const {loading, submit, error} = useMutate({
        key:       ['integration'],
        fn:        () => glimpse.integration.sync(integrationId),
        onSuccess: onClose
    });

    return (
        <Modal onClose={onClose} open={open}>
            <Modal.Header onClose={onClose} title='Force sync integration'/>
            <Modal.Body>
                <FormFallbackError error={error}/>
                {
                    loading &&
                    <Box mb={2}>
                        <Alert>
                            <Flex gap={1} align={'center'}>
                                <Spinner size={'compact'}/>
                                {t('Synchronizing data. Please do not close this window.')}
                            </Flex>
                        </Alert>
                    </Box>
                }
                <Text>
                    {t('Are you sure you want to forcefully synchronize this integration? This should only be done in rare cases when you know an error has occurred or have made major changes to your integration data.')}
                </Text>
            </Modal.Body>
            <Modal.Footer>
                <Flex justify={'end'}>
                    <Button onClick={onClose}>{t('Cancel')}</Button>
                    <Button variant='primary' loading={loading} onClick={submit}>{t('Sync Now')}</Button>
                </Flex>
            </Modal.Footer>
        </Modal>
    );
}