import {useQuery} from 'foundations/net/use-query';
import {glimpse}  from 'providers/glimpse/glimpse-provider';

export function PrefetchCoreResources() {
    useQuery({
        key: ['billing-subscription'],
        fn:  glimpse.billingSubscription.list
    });

    useQuery({
        key: ['workspace'],
        fn:  glimpse.billingSubscription.list
    });

    return null;
}