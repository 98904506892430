import {Select}      from '@glimpse/glass';
import {SelectProps} from '@glimpse/glass';
import {glimpse}     from 'providers/glimpse/glimpse-provider';
import {Property}    from '@glimpse/glimpse';
import {useQuery}    from 'foundations/net/use-query';

export function SelectProperty(props: Omit<SelectProps, 'options'> & { exclude?: (Property['id'] | null)[] }) {
    const {data} = useQuery<Property>({
        key: ['property'],
        fn:  glimpse.property.list
    });

    const options = data
        ?.filter((property) => !props.exclude?.includes(property.id))
        .map(property => {
            return {
                label: property.label,
                value: property.id
            };
        }) ?? [];

    return <Select {...props} options={options}/>;
}