import {Modal, Button, Form, Textbox, Text, Flex} from '@glimpse/glass';
import {AppListing}                               from '@glimpse/glimpse';
import {useForm}                                  from 'foundations/ui/form/use-form';
import {glimpse}                                  from 'providers/glimpse/glimpse-provider';
import {useTranslation}                           from 'foundations/i18n/use-translation';
import toast                                      from 'react-hot-toast';
import {useMutate}                                from 'foundations/net/use-mutate';

export function CreateAppListingModal({
    open,
    onClose
}: {
    open: boolean,
    onClose: () => void
}) {
    const {t}                      = useTranslation();
    const form                     = useForm<AppListing.Create.Params>();
    const {error, loading, submit} = useMutate({
        key:       ['app-listing'],
        fn:        () => glimpse.appListing.create(form.data),
        onSuccess: () => {
            toast.success(t('App added'));
            onClose();
        }
    });

    return (
        <Modal onClose={onClose} open={open}>
            <Modal.Header onClose={onClose} title={t('Add an app')}>
                <Text color={'text.subtle'}>{t('Add an external app quick access and visibility across your team.')}</Text>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Field label={t('Label')} errors={error?.params.label}>
                        <Textbox {...form.register('label', {required: true})} placeholder={t('Sentry')}/>
                    </Form.Field>
                    <Form.Field label={t('URL')} errors={error?.params.url}>
                        <Textbox {...form.register('url')}/>
                    </Form.Field>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Flex justify={'end'}>
                    <Button onClick={onClose}>{t('Cancel')}</Button>
                    <Button variant='primary' loading={loading} onClick={submit} disabled={!form.valid}>{t('Add App')}</Button>
                </Flex>
            </Modal.Footer>
        </Modal>
    );
}