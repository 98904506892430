import {Action}                                                               from '@glimpse/glimpse';
import {Grid, Box, Text, useModal, Icon, Flex, Button, Reveal, ConfinedClick} from '@glimpse/glass';
import {useState}                                                             from 'react';
import React                                                                  from 'react';
import {glimpse}                                                              from 'providers/glimpse/glimpse-provider';
import {Empty}                                                                from 'foundations/ui/empty/empty';
import {useTranslation}                                                       from 'foundations/i18n/use-translation';
import {UpdateActionModal}                                                    from 'features/action/update-action-modal';
import {DeleteActionModal}                                                    from 'features/action/delete-action-modal';
import {ObjectUtil}                                                           from 'foundations/ui/object-util/object-util';
import {CenteredSpinner}                                                      from 'foundations/ui/interstitial/centered-spinner';
import {useQuery}                                                             from 'foundations/net/use-query';
import {RequirePermission}                                                    from 'features/permission/require-permission';
import {CreateActionRunModal}                                                 from 'features/action-run/create-action-run-modal';

export function ActionsGrid({
    query = {}
}: {
    query?: Action.List.Params,
}) {
    const {t}                     = useTranslation();
    const [selected, setSelected] = useState<Action['id']>();
    const actionRunModal          = useModal();
    const updateModal             = useModal();
    const deleteModal             = useModal();

    const {data, loading} = useQuery<Action>({
        key: ['action', query],
        fn:  () => glimpse.action.list(query)
    });

    if (data?.length === 0) {
        return ObjectUtil.isEmpty(query)
            ? <Empty
                title={t('Add your first self-serve action')}
                icon={<Icon fa={['far', 'fa-hand-pointer']}/>}
                desc={t('Use actions to automate repetitive tasks and create self-service capabilities. Create your first action and it will appear here.')}/>
            : <Empty
                title={t('No actions matching filters')}
                icon={<Icon fa={['far', 'fa-hand-pointer']}/>}
                desc={t('Change your filters or search query to find other actions.')}/>;
    }

    if (loading) {
        return <CenteredSpinner/>;
    }

    const runAction = (id: Action['id']) => {
        setSelected(id);
        actionRunModal.toggle();
    };

    const openUpdate = (id: Action['id']) => {
        setSelected(id);
        updateModal.toggle();
    };

    const openDelete = (id: Action['id']) => {
        setSelected(id);
        deleteModal.toggle();
    };

    return (
        <RequirePermission permission={'action.read'} fallback>
            {
                selected !== undefined &&
                <>
                    <CreateActionRunModal onClose={actionRunModal.toggle} open={actionRunModal.open} actionId={selected}/>
                    <UpdateActionModal open={updateModal.open} onClose={updateModal.toggle} actionId={selected}/>
                    <DeleteActionModal open={deleteModal.open} onClose={deleteModal.toggle} actionId={selected}/>
                </>
            }
            <Grid gap={2} xs={12} md={4} lg={3}>
                {
                    data?.map(action =>
                        <Reveal key={action.id}>
                            <div onClick={() => runAction(action.id)}>
                                <Box p={3} radius={1} border={'default'} effects={['clickable', 'hoverable']}>
                                    <Box pb={0.5}>
                                        <Flex justify={'between'}>
                                            <Text fontWeight='bold'>{action.name}</Text>
                                            <Reveal.Hidden>
                                                <ConfinedClick>
                                                    <Button onClick={() => openUpdate(action.id)} spacing={'compact'} icon='far fa-pen'/>
                                                    <Button onClick={() => openDelete(action.id)} spacing={'compact'} icon='far fa-trash'/>
                                                </ConfinedClick>
                                            </Reveal.Hidden>
                                        </Flex>
                                    </Box>
                                    <Text color='text.subtle'>{action.description ?? t('No Description')}</Text>
                                </Box>
                            </div>
                        </Reveal>
                    )
                }
            </Grid>
        </RequirePermission>
    );
}