import {Modal, Button, Form, Textbox, Textarea, Flex} from '@glimpse/glass';
import {Component}                                    from '@glimpse/glimpse';
import {useForm}                                      from 'foundations/ui/form/use-form';
import {glimpse}                                      from 'providers/glimpse/glimpse-provider';
import {useTranslation}                               from 'foundations/i18n/use-translation';
import toast                                          from 'react-hot-toast';
import {useNavigate}                                  from 'react-router-dom';
import {useMutate}                                    from 'foundations/net/use-mutate';
import {SelectComponentType}                          from 'features/component/select-component-type';

export function CreateComponentModal({
    open,
    onClose
}: {
    open: boolean,
    onClose: () => void
}) {
    const {t}                      = useTranslation();
    const navigate                 = useNavigate();
    const form                     = useForm<Component.Create.Params>({
        type: 'application'
    });
    const {error, loading, submit} = useMutate({
        key:       ['component'],
        fn:        () => glimpse.component.create(form.data),
        onSuccess: (res) => {
            toast.success(t('Component added'));
            navigate(`/components/${res.data[0].id}`);
            onClose();
        }
    });

    return (
        <Modal onClose={onClose} open={open}>
            <Modal.Header title={t('Add a component')} onClose={onClose}/>
            <Modal.Body>
                <Form>
                    <Form.Field label={t('Name')} errors={error?.params.name}>
                        <Textbox {...form.register('name', {required: true})} placeholder={t('Authentication Gateway')}/>
                    </Form.Field>
                    <Form.Field label={t('Type')} errors={error?.params.type}>
                        <SelectComponentType {...form.register('type', {required: true})}/>
                    </Form.Field>
                    <Form.Field label={t('Description')} errors={error?.params.description}>
                        <Textarea {...form.register('description')}/>
                    </Form.Field>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Flex justify={'end'}>
                    <Button onClick={onClose}>{t('Cancel')}</Button>
                    <Button variant='primary' loading={loading} onClick={submit} disabled={!form.valid}>{t('Add Component')}</Button>
                </Flex>
            </Modal.Footer>
        </Modal>
    );
}