import {Modal, Button, Form, Textbox, Box, Text, Flex} from '@glimpse/glass';
import {useForm}                                       from 'foundations/ui/form/use-form';
import {glimpse}        from 'providers/glimpse/glimpse-provider';
import {useTranslation} from 'foundations/i18n/use-translation';
import toast                                     from 'react-hot-toast';
import {useNavigate}                             from 'react-router-dom';
import {useMutate}                               from 'foundations/net/use-mutate';
import {Role}                                    from '@glimpse/glimpse';

export function CreateRoleModal({
    open,
    onClose
}: {
    open: boolean,
    onClose: () => void
}) {
    const {t}                      = useTranslation();
    const navigate                 = useNavigate();
    const form                     = useForm<Role.Create.Params>();
    const {error, loading, submit} = useMutate({
        key:       ['role'],
        fn:        () => glimpse.role.create(form.data),
        onSuccess: (res) => {
            toast.success(t('Role added'));
            navigate(`/roles/${res.data[0].id}`);
            onClose();
        }
    });

    return (
        <Modal onClose={onClose} open={open}>
            <Modal.Header onClose={onClose} title={t('Add a role')}/>
            <Modal.Body>
                <Box mt={-1} mb={3}>
                    <Text color={'text.subtle'}>{t('Give your custom role a unique name. You\'ll be able to set exact permissions later.')}</Text>
                </Box>
                <Form>
                    <Form.Field label={t('Name')} errors={error?.params.name}>
                        <Textbox {...form.register('name', {required: true})} placeholder={t('Guest')}/>
                    </Form.Field>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Flex justify={'end'}>
                    <Button onClick={onClose}>{t('Cancel')}</Button>
                    <Button variant='primary' loading={loading} onClick={submit} disabled={!form.valid}>{t('Add Role')}</Button>
                </Flex>
            </Modal.Footer>
        </Modal>
    );
}