import {Scaffold}                                                                           from '@glimpse/glimpse';
import {Modal, Button, Code, Text, List, Box, Flex, Badge, BoxedSelector, GlassChangeEvent} from '@glimpse/glass';
import {ErrorModal}                                                                         from 'foundations/ui/error-modal/error-modal';
import {LoadingModal}                                                                       from 'foundations/ui/loading-modal/loading-modal';
import {glimpse}                                                                            from 'providers/glimpse/glimpse-provider';
import {useTranslation}                                                                     from 'foundations/i18n/use-translation';
import {useQuery}                                                                           from 'foundations/net/use-query';
import {useState}                                                                           from 'react';
import {Link}                                                                               from 'react-router-dom';

export function ScaffoldModal({
    onClose,
    open,
    scaffoldId
}: {
    onClose: () => void,
    open: boolean,
    scaffoldId: Scaffold['id']
}) {
    const {t}                    = useTranslation();
    const {data, error, loading} = useQuery<Scaffold>({
        key: ['scaffold', scaffoldId],
        fn:  () => glimpse.scaffold.list({id: scaffoldId})
    });

    const scaffold = data?.[0];

    if (loading)
        return <LoadingModal onClose={onClose} open={open}/>;

    if (error)
        return <ErrorModal onClose={onClose} open={open}/>;

    return (
        <Modal onClose={onClose} open={open}>
            <Modal.Header onClose={onClose} title={scaffold?.name} right={
                <Badge text={t(`Uses ${scaffold?.mechanism}`)} color={'neutral.subtle'}/>
            }>
                {
                    scaffold?.description &&
                    <Text>{scaffold.description}</Text>
                }
            </Modal.Header>
            <Modal.Body>
                <Box mb={2}>
                    <Text fontWeight='bold' fontSize={2}>{t('How to use this Scaffold')}</Text>
                </Box>
                {
                    scaffold?.mechanism === 'git' &&
                    <GitScaffold scaffold={scaffold}/>
                }
                {
                    scaffold?.mechanism === 'cookiecutter' &&
                    <CookieCutterScaffold scaffold={scaffold}/>
                }
            </Modal.Body>
            <Modal.Footer>
                <Flex justify={'end'}>
                    <Button onClick={onClose}>{t('Close')}</Button>
                </Flex>
            </Modal.Footer>
        </Modal>
    );
}

const GitScaffold = ({
    scaffold
}: {
    scaffold: Scaffold
}) => {
    const {t}         = useTranslation();
    const [os, setOS] = useState('mac');
    const setOs       = (e: GlassChangeEvent) => {
        setOS(e.currentTarget.value);
    };

    const options = [
        {
            label: t('MacOS / Linux'),
            value: 'mac'
        },
        {
            label: t('Windows'),
            value: 'win'
        }
    ];

    return (
        <>
            <Box mb={2}>
                <BoxedSelector onChange={setOs} name={'type'} value={os} options={options}/>
            </Box>
            <Box mb={2}>
                <Text>{t('To use this scaffold you need clone the template repository locally and copy the template files:')}</Text>
            </Box>
            <Box>
                <div style={{lineHeight: '1.6rem'}}>
                    <List type='decimal'>
                        <List.Item>{t('Setup the repository')} <Code>{`git clone ${scaffold?.template_url}`}</Code></List.Item>
                        {
                            scaffold?.template_dir &&
                            <List.Item>{t('Navigate to the template subdirectory')} <Code>{`cd ${scaffold?.template_dir}`}</Code></List.Item>
                        }
                        {
                            os === 'mac' &&
                            <List.Item>{t('Copy the template files into your target directory')} <Code>cp -R . ./my/destination/dir</Code></List.Item>
                        }
                        {
                            os === 'win' &&
                            <List.Item>{t('Copy the template files into your target directory')} <Code>xcopy . ./my/destination/dir /s</Code></List.Item>
                        }
                    </List>
                </div>
            </Box></>
    );
};


const CookieCutterScaffold = ({
    scaffold
}: {
    scaffold: Scaffold
}) => {
    const {t} = useTranslation();
    return (
        <>
            <Box mb={2}>
                <Text>{t('To use this scaffold you need to setup Python and then run CookieCutter.')}</Text>
            </Box>
            <Box>
                <div style={{lineHeight: '1.6rem'}}>
                    <List type='decimal'>
                        <List.Item>
                            <Link to={'https://www.python.org/downloads/'}>
                                <Text display={'inline'} color={'secondary'}>
                                    {t('Install Python')}
                                </Text>
                            </Link>
                        </List.Item>
                        <List.Item>
                            <Link to={'https://github.com/cookiecutter/cookiecutter?tab=readme-ov-file#installation'}>
                                <Text display={'inline'} color={'secondary'}>
                                    {t('Install CookieCutter')}
                                </Text>
                            </Link>
                        </List.Item>
                        <List.Item>
                            {t('Navigate to the directory you want to generate the scaffold in')}
                        </List.Item>
                        <List.Item>
                            {t('Run cookiecutter')} <Code>{`pipx run cookiecutter ${scaffold.template_url}`}</Code>
                        </List.Item>
                    </List>
                </div>
            </Box></>
    );
};