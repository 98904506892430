import {Grid, Box, Text, Spaced, Icon} from '@glimpse/glass';
import {AppLayout}                     from 'foundations/layouts/app-layout/app-layout';
import React, {useContext}             from 'react';
import {Link}                          from 'react-router-dom';
import {useTranslation}                from 'foundations/i18n/use-translation';
import {AuthContext}                   from 'features/auth/auth-context';
import {AppListingFavouriteGrid}       from 'features/app-listing-favourite/app-listing-favourite-grid';

export function HomeScreen() {
    const {t}    = useTranslation();
    const {user} = useContext(AuthContext);
    return (
        <AppLayout>
            <AppLayout.Body>
                <Box mt={3} mb={6}>
                    <Text fontSize={5} fontWeight='bolder'>{`Welcome, ${user.name.split(' ')[0]}!`}</Text>
                    <Box pt={1}>
                        <Text color='text.subtle' fontSize={3}>
                            {t('Where do you want to start?')}
                        </Text>
                    </Box>
                </Box>
                <Box mb={2}>
                    <Text fontSize={3} fontWeight={'bolder'}>{t('Apps')}</Text>
                </Box>
                <Box mb={4}>
                    <AppListingFavouriteGrid query={{limit: 6, sort: ['label:asc']}}/>
                </Box>
                <Box mb={2}>
                    <Text fontSize={3} fontWeight={'bolder'}>{t('Quick Links')}</Text>
                </Box>
                <Grid gap={3} xs={3} align='stretch'>
                    <div>
                        <Spaced direction={'vertical'}>
                            <Item name={t('Components')} icon={<Icon fa={['far', 'fa-server']}/>} href={'/components'}/>
                            <Item name={t('Scorecards')} icon={<Icon fa={['far', 'fa-list-check']}/>} href={'/scorecards'}/>
                            <Item name={t('Scaffolds')} icon={<Icon fa={['far', 'fa-box-open']}/>} href={'/scaffolds'}/>
                        </Spaced>
                    </div>
                    <div>
                        <Spaced direction={'vertical'}>
                            <Item name={t('Repos')} icon={<Icon fa={['far', 'fa-code-branch']}/>} href={'/repos'}/>
                            <Item name={t('PRs')} icon={<Icon fa={['far', 'fa-code-pull-request']}/>} href={'/pull-requests'}/>
                            <Item name={t('Pipelines')} icon={<Icon fa={['far', 'fa-arrows-repeat']}/>} href={'/pipelines'}/>
                        </Spaced>
                    </div>
                    <div>
                        <Spaced direction={'vertical'}>
                            <Item name={t('Apps')} icon={<Icon fa={['far', 'fa-boxes-stacked']}/>} href={'/apps'}/>
                            <Item name={t('Teams')} icon={<Icon fa={['far', 'fa-sitemap']}/>} href={'/teams'}/>
                            <Item name={t('People')} icon={<Icon fa={['far', 'fa-users']}/>} href={'/people'}/>
                        </Spaced>
                    </div>
                    <div>
                        <Spaced direction={'vertical'}>
                            <Item name={t('Insights')} icon={<Icon fa={['far', 'fa-bolt']}/>} href={'/insights'}/>
                        </Spaced>
                    </div>
                </Grid>
            </AppLayout.Body>
        </AppLayout>
    );
}

const Item = function ({
    name,
    icon,
    href
}: {
    name: string,
    icon: React.ReactNode,
    href: string,
}) {
    return (
        <Link to={href}>
            <Box radius={1} p={2} border='default' effects={['hoverable', 'clickable']}>
                <Spaced gap={2} direction={'horizontal'}>
                    {icon}
                    <Text fontSize={1}>{name}</Text>
                </Spaced>
            </Box>
        </Link>
    );
};
