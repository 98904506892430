import React  from 'react';
import styles from 'foundations/layouts/app-layout/app-layout-body.module.css';

export function AppLayoutBody({
    children
}: {
    children: React.ReactNode
}) {
    return (
        <div className={styles.body}>
            <div className={styles.inner}>
                {children}
            </div>
        </div>
    );
}