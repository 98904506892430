import {Table, Text, Box, Icon, Spaced, Button, useModal, Menu, Flex} from '@glimpse/glass';
import React                                                          from 'react';
import {PullRequest}                                                  from '@glimpse/glimpse';
import {Empty}                                                        from 'foundations/ui/empty/empty';
import {CenteredSpinner}                                              from 'foundations/ui/interstitial/centered-spinner';
import {glimpse}                                                      from 'providers/glimpse/glimpse-provider';
import {useTranslation}                                               from 'foundations/i18n/use-translation';
import {useQuery}                                                     from 'foundations/net/use-query';
import {RequirePermission}                                            from 'features/permission/require-permission';
import {PullRequestTimeOpenBadge}                                     from 'features/pull-request/pull-request-time-open-badge';
import {Link}                                                         from 'react-router-dom';
import {IntegrationHelper}                                            from 'features/integration/integration-helper';
import {DeletePullRequestModal}                                       from 'features/pull-request/delete-pull-request-modal';

export function PullRequestTable({
    query = {}
}: {
    query?: PullRequest.List.Params
}) {
    const {t} = useTranslation();
    const api = useQuery<PullRequest>({
        key: ['pull-request', query],
        fn:  () => glimpse.pullRequest.list(query)
    });

    if (api.loading) {
        return <CenteredSpinner/>;
    }

    if (api.data?.length === 0) {
        return <Empty
            icon={<Icon fa={['far', 'fa-code-pull-request']}/>}
            title={t('Connect your pull requests')}
            desc={t('Pull Requests or Merge Requests will show up here after connecting your Git provider')}/>;
    }
    return (
        <RequirePermission permission={'pull-request.read'} fallback>
            <Table>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell> </Table.HeaderCell>
                        <Table.HeaderCell>{t('Name')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('Description')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('Repo')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('Age')}</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                {
                    api.data?.length === 0 &&
                    <Box p={2}>
                        <Text color='text.subtle'>{t('There are no repositories to display')}</Text>
                    </Box>
                }
                <Table.Body>
                    {
                        api.data?.map(pr => <Row pr={pr}/>)
                    }
                </Table.Body>
            </Table>
        </RequirePermission>
    );
}

const Row = ({
    pr
}: {
    pr: PullRequest
}) => {
    const {t}         = useTranslation();
    const deleteModal = useModal();
    const int         = IntegrationHelper.get(pr.source_connector!);

    const menu = (
        <>
            <Menu.Group>
                <Link to={pr.web_url ?? '#'} target={'_blank'}>
                    <Menu.Item>
                        {t(`Open in ${int.label}`)}
                    </Menu.Item>
                </Link>
            </Menu.Group>
            <Menu.Group>
                <Menu.Item onClick={deleteModal.toggle}>
                    {t('Remove pull request')}
                </Menu.Item>
            </Menu.Group>
        </>
    );

    return (
        <>
            {
                deleteModal.open &&
                <DeletePullRequestModal open={deleteModal.open} onClose={deleteModal.toggle} pullRequestId={pr.id}/>
            }
            <Table.Row effects={['hoverable']} key={pr.id}>
                <Table.BodyCell shrink>
                    {pr.state === 'open' && <Icon fontSize={3} color={'success'} fa={['fas', 'code-pull-request']}/>}
                    {pr.state === 'merged' && <Icon fontSize={3} color={'brand'} fa={['fas', 'code-pull-request']}/>}
                    {pr.state === 'closed' && <Icon fontSize={3} color={'danger'} fa={['fas', 'code-pull-request-closed']}/>}
                    {pr.state === 'draft' && <Icon fontSize={3} color={'success'} fa={['fas', 'code-pull-request-draft']}/>}
                </Table.BodyCell>
                <Table.BodyCell>
                    <Spaced direction={'vertical'} gap={0}>
                        <Text fontWeight={'bold'}>{pr.title}</Text>
                        <Text color={'text.subtle'}>#{pr.number}</Text>
                    </Spaced>
                </Table.BodyCell>
                <Table.BodyCell>
                    <Text color={'text.subtle'}>
                        {(pr.description ?? '').length > 0 ? pr.description : '-'}
                    </Text>
                </Table.BodyCell>
                <Table.BodyCell>
                    <Link to={'/repos'}>
                        <Button variant={'subtle'} icon={<Icon fa={['fab', 'github']}/>}>
                            {`${pr.source_target_namespace_key} / ${pr.source_target_key}`}
                        </Button>
                    </Link>
                </Table.BodyCell>
                <Table.BodyCell>
                    <PullRequestTimeOpenBadge timeOpen={pr.time_open}/>
                </Table.BodyCell>
                <Table.BodyCell shrink>
                    <Flex align={'center'}>
                        <RequirePermission permission={'pull-request.write'}>
                            <Menu menu={menu}>
                                <Button spacing={'compact'} variant={'subtle'} icon={<Icon fa={['fas', 'ellipsis']}/>}/>
                            </Menu>
                        </RequirePermission>
                    </Flex>
                </Table.BodyCell>
            </Table.Row>
        </>
    );
};