import {Button, useModal, Code, Reveal, Box, Text, Flex, Spaced, Menu} from '@glimpse/glass';
import {glimpse}                                                       from 'providers/glimpse/glimpse-provider';
import React                                                           from 'react';
import {useTranslation}                                                from 'foundations/i18n/use-translation';
import {useQuery}                                                      from 'foundations/net/use-query';
import {RequirePermission}                                             from 'features/permission/require-permission';
import {CenteredSpinner}                                               from 'foundations/ui/interstitial/centered-spinner';
import {EmptyStateSmall}                                               from 'foundations/ui/empty-state-small/empty-state-small';
import {UpdateScorecardRuleModal}                                      from 'features/scorecard-rule/update-scorecard-rule-modal';
import {DeleteScorecardRuleModal}                                      from 'features/scorecard-rule/delete-scorecard-rule-modal';
import {ScorecardRule}                                                 from '@glimpse/glimpse';

export function ScorecardRuleTable({
    query = {}
}: {
    query?: ScorecardRule.List.Params
}) {
    const {t}             = useTranslation();
    const {loading, data} = useQuery<ScorecardRule>({
        key: ['scorecard-rule', query],
        fn:  () => glimpse.scorecardRule.list(query)
    });

    if (loading) {
        return (
            <Box border={'default'} xAlign={'center'} radius={1}>
                <CenteredSpinner/>
            </Box>
        );
    }

    if (data?.length === 0) {
        return <EmptyStateSmall text={t('No checks created yet')}/>;
    }

    return (
        <RequirePermission permission={'scorecard.read'} fallback>
            <Spaced gap={1} direction={'vertical'}>
                {
                    data?.map(rule => <Item rule={rule}/>)
                }
            </Spaced>
        </RequirePermission>
    );
}

const Item = ({
    rule
}: {
    rule: ScorecardRule
}) => {
    const {t}         = useTranslation();
    const updateModal = useModal();
    const deleteModal = useModal();

    const menu = (
        <>
            <Menu.Group>
                <Menu.Item onClick={updateModal.toggle}>
                    {t('Edit rule')}
                </Menu.Item>
                <Menu.Item onClick={deleteModal.toggle}>
                    {t('Delete rule')}
                </Menu.Item>
            </Menu.Group>
        </>
    );

    return (
        <>
            {
                updateModal.open &&
                <UpdateScorecardRuleModal open={updateModal.open} onClose={updateModal.toggle} scorecardRuleId={rule.id}/>
            }
            {
                deleteModal.open &&
                <DeleteScorecardRuleModal open={deleteModal.open} onClose={deleteModal.toggle} scorecardRuleId={rule.id}/>
            }
            <Reveal key={rule.id}>
                <Box effects={['hoverable']} p={2} border={'default'} radius={1}>
                    <Flex justify={'between'}>
                        <Text fontWeight={'bold'}>{rule.name}</Text>
                        <RequirePermission permission={'scorecard.write'} fallback>
                            <Menu menu={menu}>
                                <Reveal.Hidden>
                                    <Button variant={'subtle'} spacing={'compact'} icon={'fas fa-ellipsis'}/>
                                </Reveal.Hidden>
                            </Menu>
                        </RequirePermission>
                    </Flex>
                </Box>
            </Reveal>
        </>
    );
};