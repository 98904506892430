import {Modal, Button, Form, Flex} from '@glimpse/glass';
import {IntegrationApp}            from '@glimpse/glimpse';
import {useForm}                   from 'foundations/ui/form/use-form';
import {glimpse}                   from 'providers/glimpse/glimpse-provider';
import {useTranslation}            from 'foundations/i18n/use-translation';
import toast                       from 'react-hot-toast';
import {useMutate}                 from 'foundations/net/use-mutate';
import React                       from 'react';
import {SelectGithubRepo}          from 'features/integration/github/select-github-repo';

export function CreateIntegrationAppModal({
    open,
    onClose,
    source_connector,
    component_id
}: {
    open: boolean,
    onClose: () => void,
    component_id?: IntegrationApp['component_id'],
    source_connector?: IntegrationApp['source_connector']
}) {
    const {t}  = useTranslation();
    const form = useForm<IntegrationApp.Create.Params>({
        source_connector,
        component_id
    });

    const {loading, submit} = useMutate<IntegrationApp>({
        key:       ['integration-app'],
        fn:        () => glimpse.integrationApp.create(form.data),
        onSuccess: () => {
            toast.success(t('App added'));
            onClose();
        }
    });

    return (
        <Modal onClose={onClose} open={open}>
            <Modal.Header onClose={onClose} title={t('Add an app')}/>
            <Modal.Body>
                <Form>
                    {
                        source_connector === 'github' &&
                        <Form.Field label={t('Github Repo')}>
                            {/*@ts-ignore */}
                            <SelectGithubRepo {...form.register('settings.repo', {required: true})}/>
                        </Form.Field>
                    }
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Flex justify={'end'}>
                    <Button onClick={onClose}>{t('Cancel')}</Button>
                    <Button variant='primary' loading={loading} onClick={submit} disabled={!form.valid}>{t('Add App')}</Button>
                </Flex>
            </Modal.Footer>
        </Modal>
    );
}