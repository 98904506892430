import {Table, Text, Box, Icon, Flex, Badge, Button, useModal, Menu} from '@glimpse/glass';
import {Repository}                                                  from '@glimpse/glimpse';
import React                                                         from 'react';
import {glimpse}                                                     from 'providers/glimpse/glimpse-provider';
import {useTranslation}                                              from 'foundations/i18n/use-translation';
import {CenteredSpinner}                                             from 'foundations/ui/interstitial/centered-spinner';
import {useQuery}                                                    from 'foundations/net/use-query';
import {RequirePermission}                                           from 'features/permission/require-permission';
import {Empty}                                                       from 'foundations/ui/empty/empty';
import {isQueryEmpty}                                                from 'foundations/net/is-query-empty';
import {DeleteRepositoryModal}                                       from 'features/repository/delete-repository-modal';
import {Link}                                                        from 'react-router-dom';
import {IntegrationHelper}                                           from 'features/integration/integration-helper';

export function RepositoryTable({
    query = {}
}: {
    query?: Repository.List.Params,
}) {
    const {t}             = useTranslation();
    const {loading, data} = useQuery<Repository>({
        key: ['repository', query],
        fn:  () => glimpse.repository.list(query)
    });

    if (loading) {
        return <CenteredSpinner/>;
    }

    if (data?.length === 0) {
        return isQueryEmpty(query)
            ? <Empty
                prompt={
                    <RequirePermission permission={'integration.write'}>
                        <Link to={'/settings/integrations'}>
                            <Button variant={'primary'}>{t('Connect Integration')}</Button>
                        </Link>
                    </RequirePermission>
                }
                icon={<Icon fa={['far', 'fa-code-fork']}/>}
                title={t('Connect your repositories')}
                desc={t('Connect your repos from Github.com to get started. You can connect multiple accounts, integrations and organizations into one view.')}/>
            : <Empty
                title={t('No repos matching filters')}
                icon={<Icon fa={['far', 'fa-code-fork']}/>}
                desc={t('Change your filters or search query to find other repos.')}/>;
    }

    return (
        <RequirePermission permission={'repository.read'} fallback>
            <Table>
                {
                    data?.length === 0 &&
                    <Box p={2}>
                        <Text color='text.subtle'>{t('There are no repositories to display')}</Text>
                    </Box>
                }
                <Table.Header>
                    <Table.HeaderCell>
                        {t('Details')}
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                        {t('Visibility')}
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                        {t('Language')}
                    </Table.HeaderCell>
                </Table.Header>
                <Table.Body>
                    {
                        data?.map(repo => <Row repo={repo}/>)
                    }
                </Table.Body>
            </Table>
        </RequirePermission>
    );
}

const Row = ({
    repo
}: {
    repo: Repository
}) => {
    const {t}         = useTranslation();
    const deleteModal = useModal();
    const int         = IntegrationHelper.get(repo.source_connector!);

    const menu = (
        <>
            <Menu.Group>
                <Link to={repo.web_url ?? '#'} target={'_blank'}>
                    <Menu.Item>
                        {t(`Open in ${int.label}`)}
                    </Menu.Item>
                </Link>
            </Menu.Group>
            <Menu.Group>
                <Menu.Item onClick={deleteModal.toggle}>
                    {t('Remove repository')}
                </Menu.Item>
            </Menu.Group>
        </>
    );

    return (
        <>
            {
                deleteModal.open &&
                <DeleteRepositoryModal open={deleteModal.open} onClose={deleteModal.toggle} repositoryId={repo.id}/>
            }
            <Table.Row effects={['hoverable']} key={repo.id}>
                <Table.BodyCell>
                    <Box pt={1} pb={1}>
                        <Flex align={'center'}>
                            <Box mr={1.5}>
                                <Icon fontSize={4} fa={['fab', 'fa-github']}/>
                            </Box>
                            <Box>
                                <Text fontWeight={'bold'}>
                                    {`${repo.namespace_name} / ${repo.name}`}
                                </Text>
                                <Text color={'text.subtle'}>{repo.description ?? '-'}</Text>
                            </Box>
                        </Flex>
                    </Box>
                </Table.BodyCell>
                <Table.BodyCell>
                    <Badge color={'neutral.subtle'} text={repo.visibility}/>
                </Table.BodyCell>
                <Table.BodyCell>
                    <Text color={'text.subtle'}>{repo.languages.join(', ')}</Text>
                </Table.BodyCell>
                <Table.BodyCell shrink>
                    <RequirePermission permission={'repository.write'}>
                        <Menu menu={menu}>
                            <Button spacing={'compact'} variant={'subtle'} icon={<Icon fa={['fas', 'ellipsis']}/>}/>
                        </Menu>
                    </RequirePermission>
                </Table.BodyCell>
            </Table.Row>
        </>
    );
};