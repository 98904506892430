import {Button, useModal, Text, Box, Flex, Icon} from '@glimpse/glass';
import {CreateLinkModal}                         from 'features/link/create-link-modal';
import {Link}                                    from '@glimpse/glimpse';
import {RequirePermission}                       from 'features/permission/require-permission';
import {useTranslation}                          from 'foundations/i18n/use-translation';
import {faPlus}                                  from '@fortawesome/pro-light-svg-icons';
import React                                     from 'react';
import {LinkList}                                from 'features/link/link-list';

export function LinkSection({
    componentId
}: {
    componentId: Link['component_id']
}) {
    const {t}         = useTranslation();
    const createModal = useModal();

    return (
        <RequirePermission permission={'link.read'}>
            {
                createModal.open &&
                <CreateLinkModal open={createModal.open} onClose={createModal.toggle} componentId={componentId}/>
            }
            <Box mb={1} ml={0.5}>
                <Flex justify='between' align='center'>
                    <Text fontWeight='bold' fontSize={3}>{t('Links')}</Text>
                    <RequirePermission permission={'component.write'}>
                        <Button spacing={'compact'} icon={<Icon fa={faPlus}/>} onClick={createModal.toggle}/>
                    </RequirePermission>
                </Flex>
            </Box>
            <LinkList query={{component_id: componentId}}/>
        </RequirePermission>
    );
}