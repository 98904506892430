import {Textbox, TextboxProps} from '@glimpse/glass';
import {useMemo}               from 'react';
import {queries}               from '@glimpse/gql';

export function GqlTextbox(props: TextboxProps) {
    const suggestions = useMemo(() => {
        return queries
            .map((query) => `${query.connector}.${query.name}()`)
            .filter((query) => query !== props.value)
            .filter((query) => query.startsWith(props.value as unknown as string));
    }, [props.value]);

    return (
        <Textbox {...props} suggestions={suggestions}/>
    );
}