import {Repository}                from '@glimpse/glimpse';
import {Modal, Button, Text, Flex} from '@glimpse/glass';
import {glimpse}                   from 'providers/glimpse/glimpse-provider';
import {useTranslation}            from 'foundations/i18n/use-translation';
import toast                       from 'react-hot-toast';
import {useMutate}                 from 'foundations/net/use-mutate';
import {FormFallbackError}         from 'foundations/ui/form/form-fallback-error';

export function DeleteRepositoryModal({
    open,
    onClose,
    repositoryId
}: {
    open: boolean,
    onClose: () => void,
    repositoryId: Repository['id'],
}) {
    const {t}                      = useTranslation();
    const {loading, submit, error} = useMutate({
        key:       ['repository', repositoryId],
        fn:        async () => await glimpse.repository.delete(repositoryId),
        onSuccess: () => {
            toast.success(t('Repository removed'));
            onClose();
        }
    });

    return (
        <Modal onClose={onClose} open={open}>
            <Modal.Header onClose={onClose} title={t('Remove repository')}/>
            <Modal.Body>
                <FormFallbackError error={error}/>
                <Text>
                    {t('Are you sure you want to remove this repository from Glimpse?')}
                </Text>
            </Modal.Body>
            <Modal.Footer>
                <Flex justify={'end'}>
                    <Button onClick={onClose}>{t('Cancel')}</Button>
                    <Button variant='destructive' loading={loading} onClick={submit}>{t('Remove Repository')}</Button>
                </Flex>
            </Modal.Footer>
        </Modal>
    );
}