import {Team}                                         from '@glimpse/glimpse';
import {Modal, Button, Form, Textbox, Textarea, Flex} from '@glimpse/glass';
import {ErrorModal}                                   from 'foundations/ui/error-modal/error-modal';
import {LoadingModal}                           from 'foundations/ui/loading-modal/loading-modal';
import {useForm}        from 'foundations/ui/form/use-form';
import {glimpse}        from 'providers/glimpse/glimpse-provider';
import {useTranslation} from 'foundations/i18n/use-translation';
import toast                                    from 'react-hot-toast';
import {useMutate}                              from 'foundations/net/use-mutate';
import {useQuery}                               from 'foundations/net/use-query';

export function UpdateTeamModal({
    open,
    onClose,
    teamId
}: {
    open: boolean,
    onClose: () => void,
    teamId: Team['id']
}) {
    const {t}    = useTranslation();
    const form   = useForm<Team.Update.Params>();
    const update = useMutate<Team>({
        key:       ['team', teamId],
        fn:        () => glimpse.team.update(teamId, form.data),
        onSuccess: () => {
            toast.success(t('Team updated'));
            onClose();
        }
    });

    const load = useQuery<Team>({
        key:       ['team', teamId],
        fn:        () => glimpse.team.list({id: teamId}),
        onSuccess: (res) => form.setData(res.data[0] as Team.Update.Params)
    });

    if (load.loading)
        return <LoadingModal onClose={onClose} open={open}/>;

    if (load.error)
        return <ErrorModal onClose={onClose} open={open}/>;

    return (
        <Modal onClose={onClose} open={open}>
            <Modal.Header onClose={onClose} title='Update Team'/>
            <Modal.Body>
                <Form>
                    <Form.Field label={t('Name')} errors={update.error?.params.name}>
                        <Textbox {...form.register('name')} placeholder='Red UI Team'/>
                    </Form.Field>
                    <Form.Field label={t('Description')} errors={update.error?.params.description}>
                        <Textarea {...form.register('description')}/>
                    </Form.Field>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Flex justify={'end'}>
                    <Button onClick={onClose}>{t('Cancel')}</Button>
                    <Button variant='primary' loading={update.loading} onClick={update.submit}>{t('Save Changes')}</Button>
                </Flex>
            </Modal.Footer>
        </Modal>
    );
}