import {Text, Grid, Icon, Box} from '@glimpse/glass';
import React                   from 'react';
import {Scorecard}             from '@glimpse/glimpse';
import {glimpse}               from 'providers/glimpse/glimpse-provider';
import {Empty}                 from 'foundations/ui/empty/empty';
import {Link}                  from 'react-router-dom';
import {useTranslation}        from 'foundations/i18n/use-translation';
import {CenteredSpinner}       from 'foundations/ui/interstitial/centered-spinner';
import {useQuery}              from 'foundations/net/use-query';
import {RequirePermission}     from 'features/permission/require-permission';

export function ScorecardGrid({
    query
}: {
    query?: Scorecard.List.Params
}) {
    const {t}             = useTranslation();
    const {data, loading} = useQuery<Scorecard>({
        key: ['scorecard', query],
        fn:  async () => await glimpse.scorecard.list(query)
    });

    if (loading) {
        return <CenteredSpinner/>;
    }

    if (data?.length === 0) {
        return <Empty
            icon={<Icon fa={['far', 'fa-list-check']}/>}
            title={t('Add your first scorecard')}
            desc={t('Use Scorecards to track and manage the maturity of your components.')}/>;
    }

    return (
        <RequirePermission permission={'scorecard.read'} fallback>
            <Grid gap={1} xs={12} md={12}>
                {
                    data?.map(scorecard =>
                        <Link to={`/scorecards/${scorecard.id}`} key={scorecard.id}>
                            <Box p={3} radius={1} border={'default'} effects={['clickable', 'hoverable']}>
                                <Box pb={1}>
                                    <Text fontWeight='bold'>{scorecard.name}</Text>
                                </Box>
                                <Text color='text.subtle'>{scorecard.description ?? 'No Description'}</Text>
                            </Box>
                        </Link>
                    )
                }
            </Grid>
        </RequirePermission>
    );
}