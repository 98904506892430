import Cookies                                       from 'js-cookie';
import {glimpse, GlimpseProvider}                    from 'providers/glimpse/glimpse-provider';
import {Session, User, Workspace, Role, Entitlement} from '@glimpse/glimpse';

export type AuthPackage = {
    session: Session,
    workspace: Workspace,
    user: User,
    role: Role,
    entitlements: Entitlement[]
}

export class Auth {
    public static async init(): Promise<AuthPackage> {
        const token = Auth.getSessionToken();

        if (typeof token === 'undefined') {
            GlimpseProvider.init();

            // Pretend that we returned full auth, because no un-authed component will use this result
            return {} as AuthPackage;
        } else {
            glimpse.key         = token;
            GlimpseProvider.key = token;
            GlimpseProvider.init();

            // Populate initial store data
            const session = await glimpse.session.list({
                token:  token,
                expand: ['user', 'user.role', 'workspace']
            });

            const entitlements = await glimpse.entitlement.list();

            return {
                session:      session.data[0],
                workspace:    session.data[0].workspace!,
                user:         session.data[0].user!,
                role:         session.data[0].user!.role!,
                entitlements: entitlements.data
            };
        }
    }

    public static getSessionToken() {
        return Cookies.get('_glimpse_sid');
    }
}