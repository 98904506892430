import {GlassChangeEvent} from '@glimpse/glass';
import {StaticFilter}     from 'foundations/ui/filter/static-filter';
import {useTranslation}   from 'foundations/i18n/use-translation';
import {Insight}          from '@glimpse/glimpse';

export function InsightIntervalFilter({
    value,
    onChange,
    name,
    label
}: {
    value: any,
    onChange: (event: GlassChangeEvent) => void,
    name: string,
    label: string,
}) {
    const {t} = useTranslation();
    const options: {
        label: string,
        value: Insight.List.Params['interval']
    }[]       = [
        {
            value: '7_days',
            label: t('Last 7 days')
        },
        {
            value: '30_days',
            label: t('Last 30 days')
        },
        {
            value: '90_days',
            label: t('Last 90 days')
        }
    ];

    return <StaticFilter options={options} value={value} onChange={onChange} name={name} label={label}/>;
}