import {Text, Icon, Image, Flex, Tooltip, useModal, Button, Menu, Table, Box} from '@glimpse/glass';
import React, {useMemo}                                                       from 'react';
import {Pipeline}                                                             from '@glimpse/glimpse';
import {glimpse}                                                              from 'providers/glimpse/glimpse-provider';
import {Empty}                                                                from 'foundations/ui/empty/empty';
import {useTranslation}                                                       from 'foundations/i18n/use-translation';
import {CenteredSpinner}                                                      from 'foundations/ui/interstitial/centered-spinner';
import {useQuery}                                                             from 'foundations/net/use-query';
import {RequirePermission}                                                    from 'features/permission/require-permission';
import {IntegrationHelper}                                                    from 'features/integration/integration-helper';
import {UpdatePipelineModal}                                                  from 'features/pipeline/update-pipeline-modal';
import {DeletePipelineModal}                                                  from 'features/pipeline/delete-pipeline-modal';
import {Link}                                                                 from 'react-router-dom';
import {PipelineStatusBadge}                                                  from 'features/pipeline/pipeline-status-badge';

export function PipelineTable({
    query
}: {
    query?: Pipeline.List.Params
}) {
    const {t}             = useTranslation();
    const q               = {
        ...query,
        expand: ['runs']
    };
    const {data, loading} = useQuery<Pipeline>({
        key: ['pipelines', q],
        fn:  async () => await glimpse.pipeline.list(q)
    });

    if (loading) {
        return <CenteredSpinner/>;
    }

    if (data?.length === 0) {
        return <Empty
            icon={<Icon fa={['far', 'fa-code-pull-request']}/>}
            title={t('Connect your pipelines')}
            desc={t('Your CI/CD pipelines and builds will show up here after connecting your CI platform.')}/>;
    }

    return (
        <RequirePermission permission={'pipeline.read'} fallback>
            <Table>
                <Table.Header>
                    <Table.HeaderCell>{t('Name')}</Table.HeaderCell>
                    <Table.HeaderCell>{t('Status')}</Table.HeaderCell>
                    <Table.HeaderCell>{t('Latest Run')}</Table.HeaderCell>
                    <Table.HeaderCell>{t('Environment')}</Table.HeaderCell>
                    <Table.HeaderCell>{t('Repo')}</Table.HeaderCell>
                    <Table.HeaderCell> </Table.HeaderCell>
                </Table.Header>
                <Table.Body>
                    {
                        data?.map((pipeline => <Row pipeline={pipeline}/>))
                    }
                </Table.Body>
            </Table>
        </RequirePermission>
    );
}

const Row = ({
    pipeline
}: {
    pipeline: Pipeline
}) => {
    const {t}         = useTranslation();
    const updateModal = useModal();
    const deleteModal = useModal();

    const menu = (
        <>
            {
                pipeline.source_connector &&
                <Menu.Group>
                    <Link to={pipeline.web_url ?? '#'} target={'_blank'}>
                        <Menu.Item>
                            {t(`Open in ${IntegrationHelper.get(pipeline.source_connector).label}`)}
                        </Menu.Item>
                    </Link>
                </Menu.Group>
            }
            <Menu.Group>
                <Menu.Item onClick={updateModal.toggle}>
                    {t('Edit details')}
                </Menu.Item>
                <Menu.Item onClick={deleteModal.toggle}>
                    {t('Remove pipeline')}
                </Menu.Item>
            </Menu.Group>
        </>
    );

    const latestRun = useMemo(() => {
        return pipeline.runs?.sort((a,b) => new Date(b.started_at).getTime() - new Date(a.started_at).getTime())[0];
    }, [JSON.stringify(pipeline.runs)])

    return (
        <>
            {
                updateModal.open &&
                <UpdatePipelineModal open={updateModal.open} onClose={updateModal.toggle} pipelineId={pipeline.id}/>
            }
            {
                deleteModal.open &&
                <DeletePipelineModal open={deleteModal.open} onClose={deleteModal.toggle} pipelineId={pipeline.id}/>
            }
            <Table.Row effects={['hoverable']}>
                <Table.BodyCell>
                    <Box pt={1} pb={1}>
                        <Flex gap={1} align={'center'}>
                            {
                                pipeline.source_connector &&
                                <Tooltip content={IntegrationHelper.get(pipeline.source_connector).label}>
                                    <Image width={'20px'} source={IntegrationHelper.get(pipeline.source_connector).icon}/>
                                </Tooltip>
                            }
                            <Text fontWeight='bold'>{pipeline.name}</Text>
                        </Flex>
                    </Box>
                </Table.BodyCell>
                <Table.BodyCell>
                    <PipelineStatusBadge status={pipeline?.runs?.[0].status}/>
                </Table.BodyCell>
                <Table.BodyCell>
                    {
                        pipeline.runs
                            ? <Button variant={'subtle'} href={latestRun?.web_url ?? '#'} hrefTarget={'_blank'}>
                                {latestRun?.number ? `#${latestRun?.number}: ` : ''}
                                {latestRun?.name}
                            </Button>
                            : <Text color={'text.subtle'}>-</Text>
                    }
                </Table.BodyCell>
                <Table.BodyCell>
                    <Text color={'text.subtle'}>{pipeline.environment ?? '-'}</Text>
                </Table.BodyCell>
                <Table.BodyCell shrink>
                    <Link to={'/repos'}>
                        <Button variant={'subtle'} icon={<Icon fa={['fab', 'github']}/>}>
                            {`${pipeline.repository_key} / ${pipeline.repository_namespace_key}`}
                        </Button>
                    </Link>
                </Table.BodyCell>
                <Table.BodyCell shrink>
                    <Flex align={'center'} gap={1}>
                        <Menu menu={menu}>
                            <Button spacing={'compact'} variant={'subtle'} icon={<Icon fa={['fas', 'ellipsis']}/>}/>
                        </Menu>
                    </Flex>
                </Table.BodyCell>
            </Table.Row>
        </>
    );
};