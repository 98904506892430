import {Component}                               from '@glimpse/glimpse';
import {Box, Button, Flex, Icon, Text, useModal} from '@glimpse/glass';
import React                                     from 'react';
import {useTranslation}                          from 'foundations/i18n/use-translation';
import {RequirePermission}                       from 'features/permission/require-permission';
import {faPlus}                                  from '@fortawesome/pro-light-svg-icons';
import {CreatePropertyValueModal}                from 'features/property-value/create-property-value-modal';
import {PropertyValueGrid}                       from 'features/property-value/property-value-grid';

export function PropertyValueSection({
    componentId
}: {
    componentId: Component['id']
}) {
    const createModal = useModal();
    const {t}         = useTranslation();

    return (
        <RequirePermission permission={'property.read'}>
            {
                createModal.open &&
                <CreatePropertyValueModal open={createModal.open} onClose={createModal.toggle} componentId={componentId}/>
            }
            <Box mb={1} ml={0.5}>
                <Flex justify='between' align={'center'}>
                    <Text fontWeight='bold' fontSize={3}>{t('Properties')}</Text>
                    <RequirePermission permission={'component.write'}>
                        <Button spacing={'compact'} icon={<Icon fa={faPlus}/>} onClick={createModal.toggle}/>
                    </RequirePermission>
                </Flex>
            </Box>
            <PropertyValueGrid componentId={componentId}/>
        </RequirePermission>
    );
}