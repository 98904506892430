import {Box, Text, Flex, Icon, Spaced} from '@glimpse/glass';
import {Integration}                   from '@glimpse/glimpse';
import {useTranslation}                from 'foundations/i18n/use-translation';
import React                           from 'react';
import {IntegrationHelper}             from 'features/integration/integration-helper';

export function IntegrationConnectorCapabilitiesSection({
    connectorId
}: {
    connectorId: Integration['connector']
}) {
    const {t}       = useTranslation();
    const connector = IntegrationHelper.get(connectorId);

    return (
        <>
            <Box mb={1} ml={0.5}>
                <Flex justify='between' align={'center'}>
                    <Text fontWeight='bold' fontSize={3}>{t('Capabilities')}</Text>
                </Flex>
            </Box>
            <Box p={2} border={'default'} radius={1}>
                <Spaced gap={2} direction={'vertical'}>
                    <Capability label={t('GlimpseQL')} supported={connector.capabilities?.glimpseGql}/>
                    <Capability label={t('Real-time sync')} supported={connector.capabilities?.realTimeSync}/>
                    <Capability label={t('Multi-install')} supported={connector.capabilities?.multiInstall}/>
                </Spaced>
            </Box>
        </>
    );
}

const Capability = ({
    label,
    supported
}: {
    label: string,
    supported: boolean | undefined
}) => {
    const {t} = useTranslation();

    return (
        <Flex justify={'between'} align={'center'}>
            <Text fontWeight={'bold'}>{label}</Text>
            {
                supported
                    ?
                    <Flex gap={0.5} align={'center'}>
                        <Icon fa={['far', 'circle-check']} color={'success'}/>
                        <Text color={'success'}>{t('Supported')}</Text>
                    </Flex>
                    :
                    <Flex gap={0.5} align={'center'}>
                        <Icon fa={['far', 'circle-xmark']} color={'text.subtle'}/>
                        <Text color={'text.subtle'}>{t('Unavailable')}</Text>
                    </Flex>
            }
        </Flex>
    );
};