import {Text, Grid, Box}      from '@glimpse/glass';
import React                  from 'react';
import {AppListing, Pipeline} from '@glimpse/glimpse';
import {glimpse}              from 'providers/glimpse/glimpse-provider';
import {Link}                 from 'react-router-dom';
import {useTranslation}       from 'foundations/i18n/use-translation';
import {CenteredSpinner}      from 'foundations/ui/interstitial/centered-spinner';
import {useQuery}             from 'foundations/net/use-query';
import {RequirePermission}    from 'features/permission/require-permission';
import {IntegrationHelper}    from 'features/integration/integration-helper';

export function AppListingFavouriteGrid({
    query
}: {
    query?: Pipeline.List.Params
}) {
    const {t}             = useTranslation();
    const {data, loading} = useQuery<AppListing>({
        key: ['app-listings', query],
        fn:  async () => await glimpse.appListing.list(query)
    });

    if (loading) {
        return <CenteredSpinner/>;
    }

    if (data?.length === 0) {
        return <Box><Text color={'text.subtle'}>{t('No apps yet, add them in the Apps directory.')}</Text></Box>;
    }

    return (
        <RequirePermission permission={'app-listing.read'} fallback>
            <Grid gap={1} sm={2} align={'stretch'}>
                {
                    data?.map(app => {
                        const logo = IntegrationHelper.parseIntegrationFromUrl(app.url)?.icon ?? `https://s2.googleusercontent.com/s2/favicons?domain_url=${app.url}`;
                        return (
                            <Link to={app.url} target={'_blank'} key={app.id}>
                                <Box p={2} radius={1} border={'default'} effects={['clickable', 'hoverable']}>
                                    <Box mb={1}>
                                        <img src={logo} style={{height: 32}}/>
                                    </Box>
                                    <Text fontWeight='bold'>{app.label}</Text>
                                </Box>
                            </Link>
                        );
                    })
                }
            </Grid>
        </RequirePermission>
    );
}