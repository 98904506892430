import {AppLayout}                 from 'foundations/layouts/app-layout/app-layout';
import {Box, Grid, Text, useModal} from '@glimpse/glass';
import React, {useContext}         from 'react';
import {Link}                      from 'react-router-dom';
import {DeleteWorkspaceModal}      from 'features/workspace/delete-workspace-modal';
import {UpdateWorkspaceModal}      from 'features/workspace/update-workspace-modal';
import {AuthContext}               from 'features/auth/auth-context';
import {useTranslation}            from 'foundations/i18n/use-translation';
import {RequirePermission}         from 'features/permission/require-permission';

export function SettingsScreen() {
    const {t}             = useTranslation();
    const updateWorkspace = useModal();
    const deleteWorkspace = useModal();
    const {workspace}     = useContext(AuthContext);


    return (
        <AppLayout title={t('Settings')}>
            <DeleteWorkspaceModal open={deleteWorkspace.open} onClose={deleteWorkspace.toggle} workspaceId={workspace.id}/>
            <UpdateWorkspaceModal open={updateWorkspace.open} onClose={updateWorkspace.toggle} workspaceId={workspace.id}/>
            <AppLayout.Header>
                <Box pt={3} pb={3}>
                    <Text fontSize={4} fontWeight='bolder'>{t('Settings')}</Text>
                </Box>
            </AppLayout.Header>
            <AppLayout.Body>
                <Box mb={2}>
                    <Text fontSize={2} fontWeight={'bolder'}>{t('Workspace')}</Text>
                </Box>
                <Grid xs={3} gap={2}>
                    <RequirePermission permission={'workspace.write'}>
                        <SettingsLink label={t('Details')} desc={t('Change your workspace name')} onClick={updateWorkspace.toggle}/>
                    </RequirePermission>
                    <RequirePermission permission={'billing.read'}>
                        <SettingsLink label={t('Billing')} desc={t('Update your plan or payment details')} href={'/settings/billing'}/>
                    </RequirePermission>
                    <RequirePermission permission={'workspace.write'}>
                        <SettingsLink label={t('Members')} desc={t('Manage workspace access')} href={'/settings/workspace/members'}/>
                    </RequirePermission>
                    <RequirePermission permission={'api-key.read'}>
                        <SettingsLink label={t('API Keys')} desc={t('Manage API keys and access')} href={'/settings/api-keys'}/>
                    </RequirePermission>
                </Grid>
                <Box mb={2} mt={4}>
                    <Text fontSize={2} fontWeight={'bolder'}>{t('Product')}</Text>
                </Box>
                <Grid xs={3} gap={2}>
                    <RequirePermission permission={'integration.read'}>
                        <SettingsLink label={t('Integrations')} desc={t('Integrate your favourite apps')} href='/settings/integrations'/>
                    </RequirePermission>
                    <RequirePermission permission={'property.read'}>
                        <SettingsLink label={t('Properties')} desc={t('Customize component properties')} href='/settings/properties'/>
                    </RequirePermission>
                    <RequirePermission permission={'role.read'}>
                        <SettingsLink label={t('Roles')} desc={t('Customize role access')} href={'/settings/roles'}/>
                    </RequirePermission>
                </Grid>
            </AppLayout.Body>
        </AppLayout>
    );
}

const SettingsLink = ({
    label,
    desc,
    href,
    onClick
}: {
    label: string,
    desc: string,
    onClick?: () => void,
    href?: string,
}) => {
    const children = (
        <Box p={2} radius={1} effects={['hoverable', 'clickable']} border={'default'}>
            <Text color={'text'} fontWeight={'bold'}>{label}</Text>
            <Text color={'text.subtle'}>{desc}</Text>
        </Box>
    );

    return href
        ? <Link to={href}>{children}</Link>
        : <div onClick={onClick}>{children}</div>;
};